import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useMemo } from 'react';

export interface ITableColumn<T> {
  label: string;
  render: (item: T) => React.ReactNode;
}

interface ITableProps<T> {
  data?: T[] | null;
  columns: ITableColumn<T>[];
}

export const TableMUI = <T, B>({ data, columns }: ITableProps<T>) => {
  const theme = useTheme();

  const placeholder = useMemo(() => {
    return (
      <Box
        sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: 400 }}
      >
        <Typography fontWeight={theme.typography.fontWeightMedium}>No confirmed sends yet</Typography>
        <Typography sx={{ fontSize: 14 }}>Waiting for information</Typography>
      </Box>
    );
  }, [theme.typography.fontWeightMedium]);

  // const [page, setPage] = useState(0);
  // const [rowsPerPage, setRowsPerPage] = useState(5);

  // const handleChangePage = (_event: unknown, newPage: number) => {
  //   setPage(newPage);
  // };
  //
  // const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(0);
  // };

  // const paginatedData = rowsPerPage > 0 ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : data;

  return !data || data.length === 0 ? (
    placeholder
  ) : (
    <TableContainer
      component={Paper}
      sx={{
        maxHeight: 440,
        overflow: 'auto',
      }}
    >
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            {columns.map((column, index) => (
              <TableCell key={index}>{column.label}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((item, rowIndex) => (
            <TableRow key={rowIndex}>
              {columns.map((column, colIndex) => (
                <TableCell key={colIndex}>{column.render(item)}</TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
        {/* <TableFooter>
        <TableRow>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
            count={data?.length || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableRow>
      </TableFooter> */}
      </Table>
    </TableContainer>
  );
};
