import { Box } from '@mui/material';
import * as React from 'react';

import { IOneLinkChunkFormProps } from '../../../contexts/OneLinkSummaryProvider';

const SecurityForm = ({ onSubmit, instance }: IOneLinkChunkFormProps) => {
  console.log('SecurityForm instance', instance);

  return (
    <Box>
      SecurityForm goes here...
      <button
        // @ts-ignore
        onClick={onSubmit}
      >
        submit
      </button>
    </Box>
  );
};

export default SecurityForm;
