import * as yup from 'yup';

import { ReactComponent as DeliveryIcon } from '../assets/images/icon-delivery-man.svg';
import { ReactComponent as GroundDeliveryIcon } from '../assets/images/icon-fast-delivery.svg';
import { ReactComponent as OvernightIcon } from '../assets/images/icon-fast-time.svg';
import { IOneLinkFormFields } from '../types/bucket';
import { IRadioButtonOption } from '../types/forms';
import { ICommonAddress, IReceiverInfoDetailsFormValues, ShippingOptionsEnum } from '../types/shipping';
import { USA } from './countries';
import { TITLE } from './oneLink';
import { SQUARE_BRACKETS_EXTRACTOR_REGEX, ZIP_VALIDATION_REGEX } from './shell';

const EMAIL_DOMAIN_REGEX = /^[a-z0-9-]+(\.[a-z0-9-]+)+\.?$/gi;

export const LEAD = 'Lead';
export const CONTACT = 'Contact';

export const DELAYED_SHIPPING_URL = 'delayed_shipping_url';
export const TRACKING_URL = 'tracking_url';
export const SHIPPED_DATE = 'shipped_date';

export const RECEIVER_FIRST_NAME = 'receiver_first_name';
export const RECEIVER_LAST_NAME = 'receiver_last_name';
export const RECEIVER_COMPANY_NAME = 'receiver_company_name';
export const RECEIVER_CRM_RECORD_ID = 'receiver_crm_record_id';
export const RECEIVER_CRM_RECORD_TYPE = 'receiver_crm_record_type';
export const RECEIVER_CRM_OPPORTUNITY_ID = 'receiver_crm_opportunity_id';
export const RECEIVER_PHONE = 'receiver_phone';
export const RECEIVER_ADDRESS1 = 'receiver_address1';
export const RECEIVER_ADDRESS2 = 'receiver_address2';
export const RECEIVER_STATE = 'receiver_state';
export const RECEIVER_ZIP = 'receiver_zip';
export const RECEIVER_COUNTRY = 'receiver_country';
export const RECEIVER_CITY = 'receiver_city';
export const RECEIVER_EMAIL = 'receiver_email';
export const SHIP_ORDER_STATUS = 'ship_order_status';
export const SHIPPING_OPTION = 'shipping_option';
export const CRM_TYPE = 'crm_type';
export const IS_RECEIVER_ADDRESS_FIXED = 'is_receiver_address_fixed';
export const RECEIVER_FIXED_ADDRESS = 'receiver_fixed_address';
export const RECEIVER_ADDRESS = 'receiver_address';

export const AWAITING_ADDRESS = 'awaiting_address';

export const SENDER_ADDRESS1 = 'sender_address1';
export const SENDER_FIRST_NAME = 'sender_first_name';
export const SENDER_CITY = 'sender_city';
export const SENDER_COUNTRY = 'sender_country';
export const SENDER_PHONE = 'sender_phone';
export const SENDER_LAST_NAME = 'sender_last_name';
export const SENDER_ZIP = 'sender_zip';
export const SENDER_ADDRESS2 = 'sender_address2';
export const SENDER_STATE = 'sender_state';
export const SENDER_COMPANY_NAME = 'sender_company_name';
export const SENDER_EMAIL = 'sender_email';
export const DEPARTMENT_ID = 'department_id';
export const EXPIRATION_DATE = 'expiration_date';
export const DISABLE_SENDER_EMAIL_NOTIFICATIONS = 'disable_sender_email_notifications';
export const DISABLE_EMAIL_NOTIFICATIONS = 'disable_email_notifications';
export const PASSWORD = 'password';
export const ALLOWED_DOMAINS = 'allowed_domains';
export const ENABLED_2FA = 'enabled_2fa';
export const RECIPIENTS = 'recipients';
export const REDEMPTION_LIMIT = 'max_submit_count';
export const RECIPIENTS_COUNT = 'recipients_count';

export const PRINT_YOUR_CAUSE_FC_ID = 'PrintYourCause';
export const PRINT_YOUR_CAUSE_UK_FC_ID = 'PrintYourCauseUK';

export const ADDRESS1 = 'address1';
export const ADDRESS2 = 'address2';
export const CITY = 'city';
export const STATE = 'state';
export const ZIP = 'zip';
export const COUNTRY = 'country';

export const fieldNamesForErrorOutput = {
  [RECEIVER_FIRST_NAME]: 'First name',
  [RECEIVER_LAST_NAME]: 'Last name',
  [RECEIVER_COMPANY_NAME]: 'Company name',
  [RECEIVER_PHONE]: 'Phone',
  [RECEIVER_ADDRESS1]: 'Address 1',
  [RECEIVER_ADDRESS2]: 'Address 2',
  [RECEIVER_STATE]: 'State',
  [RECEIVER_ZIP]: 'ZIP Code',
  [RECEIVER_COUNTRY]: 'Country',
  [RECEIVER_CITY]: 'City',
  [RECEIVER_EMAIL]: 'Email',
  [ADDRESS1]: 'Address 1',
  [CITY]: 'City',
  [ZIP]: 'ZIP Code',
  [COUNTRY]: 'Country',
  [STATE]: 'State',
};

export const SHIPPING_OPTIONS: IRadioButtonOption[] = [
  { label: 'Ground', value: ShippingOptionsEnum.Ground, icon: GroundDeliveryIcon },
  { label: '2 day', value: ShippingOptionsEnum.TwoDays, icon: DeliveryIcon },
  { label: '3 day', value: ShippingOptionsEnum.ThreeDays, icon: DeliveryIcon },
  { label: 'Overnight', value: ShippingOptionsEnum.OverNight, icon: OvernightIcon },
];

export const RECEIVER_FIXED_ADDRESS_HINT =
  'Turn this on for a fixed delivery address. The recipient can choose preferred gift options if available.';

export const SHIPPING_NOTIFICATIONS_OPTIONS = {
  SENDER: {
    TEXT: 'Disable Sender Notifications',
    TEXT_ABOUT:
      `If the box is checked, Imprint Engine will not generate email notifications with updates on every send.` +
      ` ` +
      `You will still be able to check the status of each one within the One Link Manager.`,
  },
};

export const INITIAL_DETAILS_FORM_STATE: IReceiverInfoDetailsFormValues = {
  [RECEIVER_FIRST_NAME]: '',
  [RECEIVER_LAST_NAME]: '',
  [RECEIVER_COMPANY_NAME]: '',
  [RECEIVER_PHONE]: '',
  note_message: '',
};

/*
 * Validation Schema for edit engagement sidebar Contact Info + note
 */
export const SEND_DETAILS_FORM_VALIDATION_SCHEMA = yup.object().shape({
  [RECEIVER_FIRST_NAME]: yup.string().label(fieldNamesForErrorOutput[RECEIVER_FIRST_NAME]).required().trim(),
  [RECEIVER_LAST_NAME]: yup.string().label(fieldNamesForErrorOutput[RECEIVER_LAST_NAME]).required().trim(),
  [RECEIVER_COMPANY_NAME]: yup.string().trim(),
  [RECEIVER_PHONE]: yup.string().label(fieldNamesForErrorOutput[RECEIVER_PHONE]).required().trim(),
  note_message: yup.string().max(250).trim(),
});

export const INITIAL_ONE_LINK_PHYSICAL_SHIPPING_FORM_STATE: IOneLinkFormFields = {
  [EXPIRATION_DATE]: null,
  [IS_RECEIVER_ADDRESS_FIXED]: false,
  [DISABLE_SENDER_EMAIL_NOTIFICATIONS]: true,
  [PASSWORD]: '',
  [ALLOWED_DOMAINS]: [],
  [ENABLED_2FA]: false,
  [REDEMPTION_LIMIT]: null,
  [TITLE]: '',
};

export const INITIAL_ONE_LINK_DIGITAL_SHIPPING_FORM_STATE: IOneLinkFormFields = {
  [EXPIRATION_DATE]: null,
  [DISABLE_SENDER_EMAIL_NOTIFICATIONS]: true,
  [PASSWORD]: '',
  [ALLOWED_DOMAINS]: [],
  [ENABLED_2FA]: false,
  [REDEMPTION_LIMIT]: null,
  [TITLE]: '',
};

/*
 * Validation Schema for one-link physical send
 * Non-delayed shipping
 */
export const ONE_LINK_PHYSICAL_SHIPPING_VALIDATION_SCHEMA = yup.object<IOneLinkFormFields>().shape({
  [EXPIRATION_DATE]: yup.string().nullable(),
  [PASSWORD]: yup.string().trim(),
  [REDEMPTION_LIMIT]: yup.number().nullable(),
  [ALLOWED_DOMAINS]: yup
    .array()
    .of(
      yup
        .string()
        .required()
        .test({
          name: 'is-email-pattern',
          message: ({ path = '', value }) => {
            const index = (() => {
              const match = new RegExp(SQUARE_BRACKETS_EXTRACTOR_REGEX).exec(path);

              return match ? parseInt(match[1], 10) : null;
            })();

            return {
              index,
              message: `"${value}" is not a valid domain.`,
            };
          },
          test: (value) => {
            return new RegExp(EMAIL_DOMAIN_REGEX).test(value);
          },
        }),
    )
    .nullable(),
  [TITLE]: yup.string(),
});

/*
 * Validation Schema for one-link digital send
 * Non-delayed shipping
 */
export const ONE_LINK_DIGITAL_SHIPPING_VALIDATION_SCHEMA = yup.object<IOneLinkFormFields>().shape({
  [EXPIRATION_DATE]: yup.string().nullable(),
  [PASSWORD]: yup.string().trim(),
  [REDEMPTION_LIMIT]: yup.number().nullable(),
  [ALLOWED_DOMAINS]: yup
    .array()
    .of(
      yup
        .string()
        .required()
        .test({
          name: 'is-email-pattern',
          message: ({ path = '', value }) => {
            const index = (() => {
              const match = new RegExp(SQUARE_BRACKETS_EXTRACTOR_REGEX).exec(path);

              return match ? parseInt(match[1], 10) : null;
            })();

            return {
              index,
              message: `"${value}" is not a valid domain.`,
            };
          },
          test: (value) => {
            return new RegExp(EMAIL_DOMAIN_REGEX).test(value);
          },
        }),
    )
    .nullable(),
  [TITLE]: yup.string(),
});

export const INITIAL_SHIPPING_ADDRESS_FORM_STATE: ICommonAddress = {
  [ADDRESS1]: '',
  [ADDRESS2]: '',
  [CITY]: '',
  [STATE]: '',
  [ZIP]: '',
  [COUNTRY]: '',
};

/*********************************************/

const CRM_VALIDATION_SCHEMA = yup.object().shape({
  receiver_crm_record_id: yup.string().required(),
  receiver_crm_record_type: yup.string(),
  receiver_crm_opportunity_id: yup.string(),
});

const isDS = (v: string) => v === AWAITING_ADDRESS;

const RECEIVER_CONTACT_VALIDATION_SCHEMA = yup.object().shape({
  receiver_first_name: yup.string().label(fieldNamesForErrorOutput[RECEIVER_FIRST_NAME]).required(),
  receiver_last_name: yup.string().label(fieldNamesForErrorOutput[RECEIVER_LAST_NAME]).required(),
  receiver_email: yup
    .string()
    .label(fieldNamesForErrorOutput[RECEIVER_EMAIL])
    .email()
    .when('ship_order_status', {
      is: (v?: string) => {
        if (typeof v === 'undefined') {
          return true;
        }
        return !isDS(v);
      },
      then: (sc) => sc.required(),
      otherwise: (sc) => sc.notRequired(),
    }),
  receiver_phone: yup
    .string()
    .label(fieldNamesForErrorOutput[RECEIVER_PHONE])
    .when('ship_order_status', {
      is: (shipOrderStatus?: string) => {
        if (typeof shipOrderStatus === 'undefined') {
          return true;
        }
        return !isDS(shipOrderStatus);
      },
      then: (sc) => sc.required(),
      otherwise: (sc) => sc.notRequired(),
    }),
  receiver_company_name: yup.string().label(fieldNamesForErrorOutput[RECEIVER_COMPANY_NAME]),
});

export const RECEIVER_ADDRESS_VALIDATION_SCHEMA = ({ strict }: { strict: boolean }) => ({
  address1: yup.string().label(fieldNamesForErrorOutput[ADDRESS1])[strict ? 'required' : 'notRequired'](),
  address2: yup.string().notRequired(),
  city: yup.string().label(fieldNamesForErrorOutput[CITY])[strict ? 'required' : 'notRequired'](),
  state: yup
    .string()
    .label(fieldNamesForErrorOutput[STATE])
    .when('country', {
      is: USA.two_digit_code,
      then: (sc) => sc[strict ? 'required' : 'notRequired'](),
      otherwise: (sc) => sc.notRequired(),
    }),
  zip: yup
    .string()
    .label(fieldNamesForErrorOutput[ZIP])
    [strict ? 'required' : 'notRequired']()
    .test('is-valid-zip', 'Invalid zip format', (value) => {
      const notEmpty = value && value.trim() !== '';
      return notEmpty ? ZIP_VALIDATION_REGEX.test(value) : true;
    }),
  country: yup.string().label(fieldNamesForErrorOutput[COUNTRY])[strict ? 'required' : 'notRequired'](),
});

const SHIPPING_DETAILS_VALIDATION_SCHEMA = yup.object().shape({
  ship_order_status: yup.string().default('').oneOf([AWAITING_ADDRESS, '']).defined(),
  is_receiver_address_fixed: yup.boolean().required(),
  receiver_address: yup
    .object<ICommonAddress>(RECEIVER_ADDRESS_VALIDATION_SCHEMA({ strict: false }))
    .when(
      ['ship_order_status', 'is_receiver_address_fixed', 'org_address_ids'],
      ([shipOrderStatus, isReceiverAddressFixed, orgAddressIDs], sc) => {
        if (!isDS(shipOrderStatus) || (isReceiverAddressFixed && typeof orgAddressIDs === 'undefined')) {
          return sc.shape(RECEIVER_ADDRESS_VALIDATION_SCHEMA({ strict: true }));
        }
        return sc.shape(RECEIVER_ADDRESS_VALIDATION_SCHEMA({ strict: false }));
      },
    ),
  org_address_ids: yup
    .array()
    .of(yup.string())
    .when(['ship_order_status', 'is_receiver_address_fixed'], {
      is: (shipOrderStatus: string, isReceiverAddressFixed: boolean) => isDS(shipOrderStatus) && isReceiverAddressFixed,
      then: (schema) =>
        schema.test({
          name: 'org_address_ids',
          message: 'Either org_address_ids must have at least 2 items or receiver_address must be defined',
          test(orgAddressIDs) {
            // tslint:disable-next-line:no-invalid-this
            const { receiver_address } = this.parent;
            return (
              (orgAddressIDs && orgAddressIDs.length >= 2) ||
              (typeof orgAddressIDs === 'undefined' &&
                receiver_address &&
                yup
                  .object()
                  .shape(RECEIVER_ADDRESS_VALIDATION_SCHEMA({ strict: false }))
                  .isValidSync(receiver_address))
            );
          },
        }),
      otherwise: (schema) => schema.notRequired(),
    }),
});

// Types are defined here to avoid circular dependencies, also they are inferred from yup schemas
export type TContactForm = yup.InferType<typeof RECEIVER_CONTACT_VALIDATION_SCHEMA>;
export type TShippingForm = yup.InferType<typeof SHIPPING_DETAILS_VALIDATION_SCHEMA> & TContactForm;
export type TCRMForm = yup.InferType<typeof CRM_VALIDATION_SCHEMA>;
export type TFormNoCRM<D> = D extends true ? TContactForm : TShippingForm;
export type TShippingFormValues<D, C> = C extends true ? TFormNoCRM<D> & TCRMForm : TFormNoCRM<D>;

interface IShippingDetailsHelperArgs<D extends boolean, C extends boolean> {
  isDigital: D;
  hasCRM: C;
  isDirectSend?: boolean;
  isForcedDS?: boolean;
}

export const getAddressValidationSchema = ({ strict }: { strict: boolean }) =>
  yup.object().shape(RECEIVER_ADDRESS_VALIDATION_SCHEMA({ strict }));

export const getShippingValidationSchema = <D extends boolean, C extends boolean>({
  isDigital,
  hasCRM,
  isDirectSend,
}: IShippingDetailsHelperArgs<D, C>) => {
  const baseSchema = hasCRM
    ? RECEIVER_CONTACT_VALIDATION_SCHEMA.concat(CRM_VALIDATION_SCHEMA)
    : RECEIVER_CONTACT_VALIDATION_SCHEMA;

  if (isDigital) {
    return baseSchema.concat(
      yup.object().shape({
        receiver_phone: yup.string().label(fieldNamesForErrorOutput[RECEIVER_PHONE]).notRequired(),
      }),
    );
  }

  if (isDirectSend) {
    return baseSchema.concat(
      yup.object().shape({
        receiver_address: yup.object().shape(RECEIVER_ADDRESS_VALIDATION_SCHEMA({ strict: true })),
      }),
    );
  }

  return baseSchema.concat(SHIPPING_DETAILS_VALIDATION_SCHEMA);
};

export const getShippingInitialValues = <D extends boolean, C extends boolean>({
  isDigital,
  hasCRM,
  isDirectSend,
  isForcedDS,
}: IShippingDetailsHelperArgs<D, C>): TShippingFormValues<D, C> => {
  const base = {
    receiver_first_name: '',
    receiver_last_name: '',
    receiver_email: '',
    receiver_phone: '',
    receiver_company_name: '',

    ...(hasCRM
      ? {
          receiver_crm_record_id: '',
          receiver_crm_record_type: '',
          receiver_crm_opportunity_id: '',
        }
      : {}),
  };

  if (isDigital) {
    return base as TShippingFormValues<D, C>;
  }

  if (isDirectSend) {
    return {
      ...(base as TShippingFormValues<D, C>),
      is_receiver_address_fixed: true,
      receiver_address: INITIAL_SHIPPING_ADDRESS_FORM_STATE,
    };
  }

  return {
    ...base,
    ship_order_status: isForcedDS ? AWAITING_ADDRESS : '',
    is_receiver_address_fixed: !isForcedDS,
    receiver_address: INITIAL_SHIPPING_ADDRESS_FORM_STATE,
    org_address_ids: undefined,
  } as TShippingFormValues<D, C>;
};
