import { call, put, select, takeLatest } from 'redux-saga/effects';
import { apiUrl } from '../../config/app';

import { SEND_ON_HOLD } from '../../constants/bucket';
import { endpoints, routes } from '../../constants/routing';
import { CRM_TYPE, SHIPPING_OPTION } from '../../constants/shipping';
import {
  IBulkEngagementSubmitProps,
  ICreateEngagementResponse,
  ICreateOneLinkSendResponse,
  IEditEngagementRequestPayload,
  IPostEngagementRequestPayload,
} from '../../types/bucket';
import { IResponse } from '../../types/http';
import { IInventoryItem } from '../../types/inventories';
import { IReduxAction } from '../../types/redux';
import { IApiError } from '../../types/shell';
import { ShippingOptionsEnum } from '../../types/shipping';
import { mapEngagementCandidateToRequest } from '../../utils/bucket';
import { isObjectEmpty } from '../../utils/helpers';
import { hasMskuItem, isItemMsku } from '../../utils/inventories';
import { mapSendItemsForEditSendRequest } from '../../utils/reports';
import * as BucketActions from '../actions/bucket';
import * as EngagementActions from '../actions/engagement';
import * as ModalActions from '../actions/modals';
import { routerProxyPush } from '../actions/shell';
import { selectCurrentOrganizationCRMType } from '../selectors/organizations';
import { AuthRequest } from './helpers';

export function* engagementWorkerSaga(action: IReduxAction<IPostEngagementRequestPayload>): Generator<any, any, any> {
  try {
    const { engagement, metadata } = action.payload!;

    // 1. Prepare request variables
    const crmType = yield select(selectCurrentOrganizationCRMType);
    const { isOneLink } = metadata;
    const endpoint = `${apiUrl}${isOneLink ? endpoints.createOneLink : endpoints.engagement}`;

    // 2. Prepare the request payload
    const body = JSON.stringify(
      mapEngagementCandidateToRequest(engagement, {
        ...metadata,
        crmType,
      }),
    );

    // 3. Make the request
    const response: IResponse<ICreateEngagementResponse | ICreateOneLinkSendResponse | IApiError> = yield call(
      AuthRequest,
      {
        method: 'POST',
        endpoint,
        body,
      },
    );

    // 4. Process the response
    if (!response.ok) {
      yield put(EngagementActions.postEngagementFailure(response.body));
      // yield put(
      //   AnalyticsActions.trackApiException({
      //     endpoint,
      //     reason: FAILURE_REASONS.REQUEST_FAILED,
      //   }),
      // );
    } else {
      yield put(routerProxyPush(routes.dashboard));
      yield put(BucketActions.flushBucket());
      yield put(
        EngagementActions.postEngagementSuccess(
          response.body as ICreateEngagementResponse | ICreateOneLinkSendResponse,
        ),
      );
      yield put(ModalActions.toggleRocketModal(response.body, metadata));
    }
  } catch (error) {
    // yield put(AnalyticsActions.trackException(error));
    yield put(EngagementActions.postEngagementFailure(error));
  }
}

function* bulkEngagementWorker(action: any): Generator<any, any, any> {
  const { data, resolve, reject } = (action.payload as IBulkEngagementSubmitProps) || {};
  try {
    // 1. Prepare request variables
    const endpoint = `${apiUrl}${endpoints.bulkWithCSV}`;
    const crmType = yield select(selectCurrentOrganizationCRMType);

    // tslint:disable-next-line:variable-name
    const { on_hold_until, receiver_address: receiver_fixed_address, org_address_ids } = data;
    const { receiver_address, ...rest } = data;
    let { items } = rest;

    if (hasMskuItem(items)) {
      items = items?.map(({ sku_options, ...item }: IInventoryItem) => {
        if (isItemMsku(item) && sku_options?.length) {
          return { ...item, sku_option_ids: sku_options.map(({ item_id }) => item_id) };
        }
        return item;
      });
    }

    // 2. Populate static fields to request payload
    const body = JSON.stringify({
      ...rest,
      items,
      [SEND_ON_HOLD]: on_hold_until || undefined,
      ...(!isObjectEmpty(receiver_fixed_address) ? { receiver_fixed_address } : {}),
      ...(org_address_ids?.length ? { org_address_ids } : {}),
      [CRM_TYPE]: crmType,
      [SHIPPING_OPTION]: ShippingOptionsEnum.Ground,
    });

    // 3. Make the request
    const response = yield call<any>(AuthRequest, {
      endpoint,
      method: 'POST',
      body,
    });

    // 4. Process the response
    if (!response.ok) {
      if (response.status === 400) {
        yield put(BucketActions.bulkEngagementFailure(response.body));
      } else {
        yield put(BucketActions.bulkEngagementApiFailure(response.body));
      }
      if (typeof reject === 'function') {
        reject(response.body);
      }
    } else {
      yield put(BucketActions.bulkEngagementSuccess(response.body));
      if (typeof resolve === 'function') {
        resolve(response.body);
      }
    }
  } catch (error) {
    yield put(BucketActions.bulkEngagementApiFailure(error));
    if (typeof reject === 'function') {
      reject(error);
    }
  }
}

function* editEngagementWorkerSaga(action: IReduxAction<IEditEngagementRequestPayload>): Generator<any, any, any> {
  const { resolve, reject, engagement } = action.payload || {};
  try {
    if (engagement) {
      const endpoint = `${apiUrl}${endpoints.editEngagement}`;

      const { items_info, ...rest } = engagement;

      const body = JSON.stringify({ ...mapSendItemsForEditSendRequest(items_info), ...rest });

      const response = yield call<any>(AuthRequest, {
        method: 'PATCH',
        endpoint,
        body,
      });

      if (!response.ok) {
        yield put(EngagementActions.editEngagementFailure(response.body));
        if (reject) {
          yield call(reject, response.body);
        }
      } else {
        yield put(EngagementActions.editEngagementSuccess({ engagement }));
        if (resolve) {
          const { error } = response.body || {};
          yield call(resolve, error);
        }
      }
    } else if (reject) {
      yield call(reject, { message: 'An error has occurred! No engagement to edit' });
    }
  } catch (error) {
    if (reject) {
      yield call(reject, error);
    }
  }
}

const sagas = {
  *watchEngagementRequest() {
    yield takeLatest(EngagementActions.POST_ENGAGEMENT_REQUEST, engagementWorkerSaga);
  },
  *watchBulkEngagementRequest() {
    yield takeLatest(BucketActions.BULK_ENGAGEMENT_REQUEST, bulkEngagementWorker);
  },
  *watchEditEngagementRequest() {
    yield takeLatest(EngagementActions.EDIT_ENGAGEMENT_REQUEST, editEngagementWorkerSaga);
  },
};

export default sagas;
