import cn from 'classnames';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ReactComponent as iconCSVInfo } from '../../assets/images/icon-csv-info.svg';
import { ReactComponent as FileIcon } from '../../assets/images/icon-file.svg';
import { ReactComponent as UploadIcon } from '../../assets/images/icon-upload.svg';
import { DisableEmailNotifications, FileDetails, InfoLink, UploadResult } from '../../components';
import { ActionButton, ExpirationDateInput, HoldUntilDateInput, Selector, UploadInput } from '../../components/forms';
import { CSV_BULK_SEND_EXAMPLE_URL } from '../../config/app';
import {
  DEFAULT_DELAYED_SHIPPING_EXPIRATION_DATE,
  DELAYED_SHIPPING_EXPIRATION,
  IS_TOGGLE_ON_HOLD_ENABLED,
  SEND_ON_HOLD,
} from '../../constants/bucket';
import { DELAYED_SHIPPING_EXPIRATION_DATE_HINT } from '../../constants/engagement';
import { BUSINESS_REASON_ID } from '../../constants/reasons';
import { DISABLE_SENDER_EMAIL_NOTIFICATIONS } from '../../constants/shipping';
import useUploadFile from '../../hooks/useUploadFile';
import { fetchAddressBookRequest } from '../../store/actions/addressBook';
import {
  addBucketValue,
  addBulkData,
  bulkEngagementRequest,
  clearBulkData,
  flushBucket,
  updateBucketShippingDetails,
  uploadBulkCsv,
} from '../../store/actions/bucket';
import {
  selectBucketItems,
  selectBulkData,
  selectBulkResult,
  selectBusinessReasonId,
  selectDelayedShippingExpirationDate,
  selectIsBulkShippingStepValid,
  selectIsBulkSubmitted,
  selectIsBusinessReasonRequired,
  selectIsDisabledSenderNotifications,
  selectIsPYGEnabled,
  selectPYGCampaignsList,
  selectSendOnHold,
  selectShippingDetails,
  selectUploadedCSV,
} from '../../store/selectors/bucket';
import { selectBusinessReasons } from '../../store/selectors/reasons';
import { IBulkEngagementRequest, IBulkEngagementResponse, IFlowStepProps } from '../../types/bucket';
import { IDropzoneResult } from '../../types/forms';
import { ISelectorValue } from '../../types/shell';
import { IShippingDetails } from '../../types/shipping';
import { UploadFileTypeEnum } from '../../types/upload';
import { formatFileSize, getFileInfo } from '../../utils/bucket';
import { isDigitalBox } from '../../utils/campaigns';
import { hasMskuItem } from '../../utils/inventories';
import notification from '../../utils/notification';
import { BulkShippingDetailsContainer } from '../ShippingDetailsContainer';

import styles from './UploadCSVContainer.module.scss';

const UploadCSVContainer: React.FC<IFlowStepProps> = () => {
  const dispatch = useDispatch();
  const uploadedFile = useSelector(selectUploadedCSV);
  const isDisabledSenderEmailNotifications = useSelector(selectIsDisabledSenderNotifications);
  const sendOnHoldDate = useSelector(selectSendOnHold);
  const sendExpirationDate = useSelector(selectDelayedShippingExpirationDate);
  const bulkRequestData = useSelector(selectBulkData);
  const result = useSelector(selectBulkResult);
  const bucketItems = useSelector(selectBucketItems);
  const pygCampaigns = useSelector(selectPYGCampaignsList);
  const isPYGFlow = useSelector(selectIsPYGEnabled);
  const shippingDetails = useSelector(selectShippingDetails);
  const hasMsku = hasMskuItem(bucketItems);

  const { uploadFile } = useUploadFile();

  const isSubmitted = useSelector(selectIsBulkSubmitted);

  const isBusinessReasonRequired = useSelector(selectIsBusinessReasonRequired);
  const businessReasonSelectedValue = useSelector(selectBusinessReasonId);
  const reasons = useSelector(selectBusinessReasons);
  const isShippingStepValid = useSelector(selectIsBulkShippingStepValid);

  const reasonsOptions = React.useMemo(() => {
    if (!reasons) {
      return [];
    }
    return reasons.map(({ name, uid }) => {
      return {
        label: name,
        value: uid,
      };
    });
  }, [reasons]);

  const isDisabled = React.useMemo(
    () => !uploadedFile || (isBusinessReasonRequired && !businessReasonSelectedValue) || !isShippingStepValid,
    [businessReasonSelectedValue, isBusinessReasonRequired, uploadedFile, isShippingStepValid],
  );

  const isLoaded = React.useMemo(() => !!uploadedFile, [uploadedFile]);

  const isNotDigitalSend = React.useMemo(() => {
    if (isPYGFlow) {
      const items = pygCampaigns?.map((campaign) => campaign.items).flat();
      return !isDigitalBox({ items });
    }
    return !isDigitalBox({ items: bucketItems });
  }, [bucketItems, isPYGFlow, pygCampaigns]);

  const clearUploadedFile = React.useCallback(() => {
    dispatch(addBulkData({ csv: null }));
  }, [dispatch]);

  const clearScreen = React.useCallback(() => {
    dispatch(clearBulkData());
  }, [dispatch]);

  const handleSenderNotificationsChange = React.useCallback(
    (checked: boolean) => {
      dispatch(addBucketValue({ [DISABLE_SENDER_EMAIL_NOTIFICATIONS]: checked }));
    },
    [dispatch],
  );

  const handleSendOnHold = React.useCallback(
    (value: string) => {
      dispatch(addBucketValue({ [SEND_ON_HOLD]: value ? new Date(value).toISOString() : value }));
    },
    [dispatch],
  );

  const handleSendExpirationDate = React.useCallback(
    (value: string) => {
      dispatch(addBucketValue({ [DELAYED_SHIPPING_EXPIRATION]: value ? new Date(value).toISOString() : value }));
    },
    [dispatch],
  );

  const handleToggleChange = React.useCallback(
    (value: boolean) => {
      dispatch(addBucketValue({ [IS_TOGGLE_ON_HOLD_ENABLED]: value }));
    },
    [dispatch],
  );

  const handleShippingDetailsChange = React.useCallback(
    (updates: IShippingDetails) => {
      dispatch(updateBucketShippingDetails(updates));
    },
    [dispatch],
  );

  const handleSelectBusinessReason = React.useCallback(
    (reason: ISelectorValue) => {
      dispatch(addBucketValue({ [BUSINESS_REASON_ID]: reason?.value }));
    },
    [dispatch],
  );

  const showMessage = React.useCallback((response: IBulkEngagementResponse | { message?: string }) => {
    if ('message' in response) {
      return notification.error(response.message, { content: response.message });
    }
    if ('error_count' in response && 'success_count' in response) {
      const { error_count, success_count } = response;
      const message = `There ${success_count === 1 ? 'was' : 'were'} ${success_count} send${
        success_count === 1 ? '' : 's'
      } created and ${error_count} error${error_count === 1 ? '' : 's'}`;

      return notification.show(message, 'default', { content: message });
    }
  }, []);

  const handleSubmit = React.useCallback(async () => {
    if (bulkRequestData) {
      return new Promise<IBulkEngagementResponse>((resolve, reject) => {
        dispatch(bulkEngagementRequest({ data: bulkRequestData as IBulkEngagementRequest, resolve, reject }));
      })
        .then(showMessage)
        .catch(showMessage);
    }
  }, [dispatch, bulkRequestData, showMessage]);

  const onUploadStart = React.useCallback(
    ({ files, resolve, reject }: IDropzoneResult) => {
      dispatch(uploadBulkCsv());
      uploadFile(files[0], UploadFileTypeEnum.BulkEngagement).then(resolve).catch(reject);
    },
    [dispatch, uploadFile],
  );

  const onUploadSuccess = React.useCallback(
    (files: File[]) => dispatch(addBulkData({ csv: getFileInfo(files[0]) })),
    [dispatch],
  );

  const onUploadFailure = React.useCallback(({ message = 'An error was occurred during file uploading.' }) => {
    notification.error(message, { content: message });
  }, []);

  React.useEffect(() => {
    clearScreen();
  }, [clearScreen]);

  React.useEffect(() => {
    if (isSubmitted) {
      return () => {
        dispatch(flushBucket());
      };
    }
  }, [dispatch, isSubmitted]);

  React.useEffect(() => {
    if (isNotDigitalSend) {
      dispatch(fetchAddressBookRequest());
    }
  }, [dispatch, isNotDigitalSend]);

  return (
    <div className={cn(styles.container)}>
      <div className={styles.upload}>
        <div className={styles.title}>File uploader</div>
        <UploadInput
          accept=".csv"
          onUpload={onUploadStart}
          onSuccess={onUploadSuccess}
          onFailure={onUploadFailure}
          className={styles.uploadField}
          multiple={false}
        >
          {({ isDragActive }) => {
            return (
              <div className={styles.placeholder}>
                {isLoaded ? <FileIcon /> : <UploadIcon />}
                {isDragActive ? (
                  <span className={styles.uploadMessage}>Drop the files here ...</span>
                ) : (
                  <span className={styles.uploadMessage}>
                    {isLoaded ? 'The file was uploaded' : 'Click or drag to upload CSV file'}
                  </span>
                )}
              </div>
            );
          }}
        </UploadInput>
        {uploadedFile ? (
          <FileDetails
            action={<button className={styles.clearIcon} onClick={clearUploadedFile} />}
            name={uploadedFile.name}
            icon={iconCSVInfo}
            description={`${formatFileSize(uploadedFile.size)}`}
          />
        ) : (
          <InfoLink
            url={CSV_BULK_SEND_EXAMPLE_URL}
            className={styles.exampleLink}
            toggleClassName={styles.toggle}
            label="Download Example"
            hint="Click on the link to download CSV file example"
          />
        )}
        <div className={styles.actions}>
          <DisableEmailNotifications
            className={styles.checkbox}
            value={isDisabledSenderEmailNotifications}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleSenderNotificationsChange(e.target.checked)}
          />
          <div className={styles.wrapper}>
            {isNotDigitalSend && (
              <>
                <HoldUntilDateInput
                  value={sendOnHoldDate}
                  onChange={handleSendOnHold}
                  onToggleChange={handleToggleChange}
                />
                <ExpirationDateInput
                  value={sendExpirationDate}
                  onChange={handleSendExpirationDate}
                  hint={DELAYED_SHIPPING_EXPIRATION_DATE_HINT}
                  defaultValue={DEFAULT_DELAYED_SHIPPING_EXPIRATION_DATE.toISOString()}
                />
              </>
            )}
          </div>
          <div className={styles.selectorContainer}>
            <Selector
              name={BUSINESS_REASON_ID}
              isSearchable
              className={styles.input}
              closeMenuOnSelect
              value={reasonsOptions.find((reason) => reason.value === businessReasonSelectedValue) || null}
              onChange={(reason) => handleSelectBusinessReason(reason as ISelectorValue)}
              options={reasonsOptions}
              placeholder="Select business reason"
              helperText="Business Reason"
              onClear={() => handleSelectBusinessReason({ value: '' } as ISelectorValue)}
              required={isBusinessReasonRequired}
            />
          </div>
          {isNotDigitalSend && (
            <BulkShippingDetailsContainer
              value={shippingDetails}
              onChange={handleShippingDetailsChange}
              isForcedDS={hasMsku || isPYGFlow}
            />
          )}
          <ActionButton className={styles.btn} title="Create Sends" onClick={handleSubmit} disabled={isDisabled} />
        </div>
      </div>
      <UploadResult className={styles.upload} result={result} />
    </div>
  );
};

export default UploadCSVContainer;
