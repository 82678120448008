import isEmpty from 'lodash/isEmpty';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import {
  DEFAULT_DELAYED_SHIPPING_EXPIRATION_DATE,
  DELAYED_SHIPPING_EXPIRATION,
  SEND_ON_BEHALF_THE_EMAIL,
} from '../../constants/bucket';
import { IS_HIDDEN_FOR_RECIPIENT } from '../../constants/inventories';
import { RECEIVER_FIXED_ADDRESS, SHIP_ORDER_STATUS, TCRMForm, TShippingForm } from '../../constants/shipping';
import {
  EMAIL_MESSAGE,
  EMAIL_SUBJECT,
  EMAIL_TEMPLATE_ID,
  INITIAL_ADD_EMAIL_FORM_STATE,
  INITIAL_ADD_NOTE_FORM_STATE,
  MESSAGE,
  NOTE_TEMPLATE_ID,
  RECEIVER_EMAIL,
} from '../../constants/templates';
import { IFlowStepProps } from '../../types/bucket';
import { ICampaign, ICampaignItem, ICustomSend } from '../../types/campaigns';
import { IReduxAction } from '../../types/redux';
import { ICommonAddress, IOneLinkShippingDetails, IShippingDetails } from '../../types/shipping';
import { EmailTemplateTypesEnum, IEmailTemplate, INoteTemplate } from '../../types/templates';
import { getShippingDetailsFromCampaign } from '../../utils/bucket';
import { getDoesCampaignContainOnlyHiddenItems } from '../../utils/campaigns';
import { isItemDigital } from '../../utils/inventories';
import { replaceLineBreaks, setTemplateTokens } from '../../utils/template';
import * as BucketActions from '../actions/bucket';
import { selectAddressBook } from '../selectors/addressBook';
import {
  selectBucketItems,
  selectDelayedShippingExpirationDate,
  selectEmailInformation,
  selectIsDigitalBucket,
  selectIsDisabledNotecard,
  selectIsPYGCampaignsDigital,
  selectIsPYGEnabled,
  selectIsSOBEnabled,
  selectNoteInformation,
  selectReceiverAddress,
  selectTemplateValues,
} from '../selectors/bucket';
import { selectEmailTemplates, selectNoteTemplates } from '../selectors/templates';

function* shippingInfoChangedWorkerSaga(
  action: IReduxAction<Partial<TShippingForm & TCRMForm> | ICampaign | ICustomSend | ICampaign[], IFlowStepProps>,
): Generator<any, any, any> {
  const { isBulk, isOneLink } = action.metadata || {};
  const { note_template_id } = yield select(selectNoteInformation);
  const { email_template_id } = yield select(selectEmailInformation);
  const templateValues = yield select(selectTemplateValues);
  const templateData = { ...action.payload, ...templateValues };
  const emailTemplates = yield select(selectEmailTemplates);
  const noteTemplates = yield select(selectNoteTemplates);
  const isSOBEnabled = yield select(selectIsSOBEnabled);
  const isDisabledNotecard = yield select(selectIsDisabledNotecard);
  const dsExpirationDate = yield select(selectDelayedShippingExpirationDate);
  const receiverAddress: ICommonAddress | undefined = yield select(selectReceiverAddress);

  const isNotDelayedShipping = (action?.payload as Partial<TShippingForm>)?.[SHIP_ORDER_STATUS] === '';
  const isDigital = yield select(selectIsDigitalBucket);
  const isPYGFlow = yield select(selectIsPYGEnabled);
  const isPYGCampaignsDigital = yield select(selectIsPYGCampaignsDigital);

  const existingEmailTemplate: IEmailTemplate | undefined =
    email_template_id && emailTemplates
      ? emailTemplates.find((i: IEmailTemplate) => i.item_id === email_template_id)
      : undefined;

  const existingNote: INoteTemplate | undefined =
    note_template_id && noteTemplates
      ? noteTemplates.find((i: INoteTemplate) => i.item_id === note_template_id)
      : undefined;

  // If there's a previously selected note template and now we are on digital flow
  const hasAllDigitalItems = isPYGFlow ? isPYGCampaignsDigital : isDigital;
  const shouldResetNote = (hasAllDigitalItems && existingNote) || isDisabledNotecard;

  const shouldResetEmail =
    // If there's a previously selected template and it's not delayed shipping
    (existingEmailTemplate && isNotDelayedShipping) ||
    // If previously selected template was for digital and now we are on non-digital flow
    (existingEmailTemplate && existingEmailTemplate.type === EmailTemplateTypesEnum.DIGITAL_GIFT && !isDigital) ||
    // If previously selected template was for delayed shipping and now we are on digital flow
    (existingEmailTemplate && existingEmailTemplate.type === EmailTemplateTypesEnum.DELAYED_SHIPPING && isDigital);

  let shippingDetails: IShippingDetails | IOneLinkShippingDetails | undefined;

  if ([BucketActions.PRE_SELECTED_BOX_CONFIRMED, BucketActions.PICK_YOUR_GIFT_BOXES_CONFIRMED].includes(action.type)) {
    const addressBook = yield select(selectAddressBook);
    shippingDetails = yield call(getShippingDetailsFromCampaign, action.payload as ICampaign | ICampaign[], {
      ...action.metadata,
      addressBook,
    });
  }

  const bucketFieldUpdates = {
    ...(!isSOBEnabled ? { [SEND_ON_BEHALF_THE_EMAIL]: null } : {}),
    ...(isBulk && !isDigital && !dsExpirationDate
      ? { [DELAYED_SHIPPING_EXPIRATION]: DEFAULT_DELAYED_SHIPPING_EXPIRATION_DATE.toISOString() }
      : {}),
    ...(isDigital && !!receiverAddress ? { [RECEIVER_FIXED_ADDRESS]: undefined } : {}),
  };

  if (shippingDetails) {
    yield put(BucketActions.updateBucketShippingDetails(shippingDetails));
  }

  if (!isEmpty(bucketFieldUpdates)) {
    yield put(BucketActions.addBucketValue(bucketFieldUpdates));
  }

  const shouldSkipParsingTemplateBodies = isBulk || isOneLink;

  if (shouldResetNote) {
    yield put(BucketActions.addNote(INITIAL_ADD_NOTE_FORM_STATE));
  } else if (existingNote) {
    const { body, item_id } = existingNote;
    yield put(
      BucketActions.addNote({
        [NOTE_TEMPLATE_ID]: item_id,
        [MESSAGE]: shouldSkipParsingTemplateBodies ? body : setTemplateTokens(replaceLineBreaks(body), templateData),
      }),
    );
  }

  if (shouldResetEmail) {
    yield put(BucketActions.addEmail({ ...INITIAL_ADD_EMAIL_FORM_STATE, [EMAIL_TEMPLATE_ID]: '' }));
  } else if (existingEmailTemplate) {
    const { item_id, body, subject } = existingEmailTemplate;

    yield put(
      BucketActions.addEmail({
        [EMAIL_TEMPLATE_ID]: item_id,
        [EMAIL_MESSAGE]: shouldSkipParsingTemplateBodies ? body : setTemplateTokens(body, templateData),
        [RECEIVER_EMAIL]: templateData[RECEIVER_EMAIL],
        [EMAIL_SUBJECT]: subject || '',
      }),
    );
  }
}

function* removeHiddenFlagForLastItemInBucketWorkerSaga(action: IReduxAction<string>): Generator<any, any, any> {
  const bucketItems = yield select(selectBucketItems);
  const removedItemId = action.payload;
  const filteredBucketItems = bucketItems.filter(
    (item: ICampaignItem) => item.item_id !== removedItemId && !isItemDigital(item),
  );
  const isOnlyHiddenItemsInCampaign = getDoesCampaignContainOnlyHiddenItems(filteredBucketItems);

  if (isOnlyHiddenItemsInCampaign) {
    const bucketItemIdToUpdate = filteredBucketItems.length ? filteredBucketItems.shift().item_id : undefined;
    yield put(
      BucketActions.changeBucketItemValue({ id: bucketItemIdToUpdate, value: false, name: IS_HIDDEN_FOR_RECIPIENT }),
    );
  }
}

const sagas = {
  *watchShippingInfoChanged() {
    yield takeLatest(
      [
        BucketActions.ADD_SHIPPING_INFORMATION,
        BucketActions.PRE_SELECTED_BOX_CONFIRMED,
        BucketActions.PICK_YOUR_GIFT_BOXES_CONFIRMED,
      ],
      shippingInfoChangedWorkerSaga,
    );
  },
  *watchBucketItemRemoved() {
    yield takeLatest(BucketActions.REMOVE_FROM_BUCKET, removeHiddenFlagForLastItemInBucketWorkerSaga);
  },
};

export default sagas;
