import cn from 'classnames';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { ReactComponent as DeleteIcon } from '../../assets/images/icon-delete-button.svg';
import { Filter, Loader, NoResultsPlaceholder, OneLinkCard, PaginatedList, TabProvider } from '../../components';
import { ActionButton, AsyncButton, ReportsCopyableButton } from '../../components/forms';
import { IPaginatedListChangeEvent, IPaginatedListRef } from '../../components/PaginatedList/PaginatedList';
import { MAX_ONE_LINKS_PER_PAGE, ONE_LINK_FILTERS, ONE_LNK_TABS } from '../../constants/oneLink';
import { routes } from '../../constants/routing';
import { DEPARTMENT_ID, ORG_ADMIN, SUPER_ADMIN } from '../../constants/users';
import { useHeaderDispatch } from '../../contexts/HeaderInfo';
import useDeptFilter from '../../hooks/useDeptFilter';
import useSearchFilter from '../../hooks/useSearchFilter/useSearchFilter';
import useWindowSize from '../../hooks/useWindowSize';
import { disableOneLinkRequest, fetchOneLinkSendsRequest } from '../../store/actions/oneLink';
import { fetchBusinessReasonsRequest } from '../../store/actions/reasons';
import { selectAdminType, selectIsAdminTypeUser } from '../../store/selectors/auth';
import {
  selectFilteredOneLinkSends,
  selectIsFinalOneLink,
  selectIsOneLinksFetching,
  selectOneLinkTotalCount,
} from '../../store/selectors/oneLink';
import { selectBusinessReasons } from '../../store/selectors/reasons';
import { selectIsShowContentLoader } from '../../store/selectors/shell';
import { IFetchOneLinkSendsRequestPayload, IOneLink, OneLinkStatusEnum } from '../../types/oneLink';
import { FilterTypesEnum, IUseFilterSelectorContainerProp, NotificationListEnum, UISizeEnum } from '../../types/shell';
import notification from '../../utils/notification';
import { handleApiError } from '../../utils/ui';
import { hasPermission } from '../../utils/users';

import styles from './OneLinkManagerContainer.module.scss';

const OneLinkManagerContainer = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { mobile } = useWindowSize();
  const { setHeader } = useHeaderDispatch();

  const [disabledLoaderIds, setDisabledLoaderIds] = React.useState<string[]>([]);
  const paginatedListRef = React.useRef<IPaginatedListRef<IOneLink>>(null);

  const adminType = useSelector(selectAdminType);
  const isAdminTypeUser = useSelector(selectIsAdminTypeUser);
  const isOneLinksFetching = useSelector(selectIsOneLinksFetching);
  const isGlobalLoaderShown = useSelector(selectIsShowContentLoader);
  const isFinalOneLink = useSelector(selectIsFinalOneLink);
  const reasons = useSelector(selectBusinessReasons);
  const oneLinkTotalCount = useSelector(selectOneLinkTotalCount);

  const [tab, setTab] = React.useState<OneLinkStatusEnum>(OneLinkStatusEnum.Active);
  const [filter, setFilter] = React.useState(
    isAdminTypeUser
      ? ONE_LINK_FILTERS.find(({ role = [] }) => role.includes(adminType))?.filterId
      : FilterTypesEnum.Individual,
  );

  const { SearchInput, query } = useSearchFilter([], [], {
    placeholder: 'Lookup One Link',
    hint: 'Look up specific sends by their title.',
  });

  const oneLinkSends = useSelector(selectFilteredOneLinkSends({ query, filter, status: tab }));

  const {
    DeptFilter,
    filteredItems: filteredOneLinks,
    departments: selectedDepartments,
    setDepartments: setSelectedDepartments,
  } = useDeptFilter(oneLinkSends || [], DEPARTMENT_ID, {
    className: styles.dropdownFilter,
    contentClassName: styles.dropdownContent,
    labelClassName: styles.dropdownLabel,
    canOpen: !isOneLinksFetching && filter === FilterTypesEnum.Organization,
  });

  const handleDisableOneLink = React.useCallback(
    ({ uid }: IOneLink) => {
      return new Promise<string>((resolve, reject) => {
        setDisabledLoaderIds((prev) => (prev.includes(uid) ? prev : [...prev, uid]));
        dispatch(disableOneLinkRequest({ uid, resolve, reject }));
      })
        .then((id: string) => {
          setDisabledLoaderIds((prev) => (prev.includes(uid) ? prev.filter((i) => i !== id) : prev));
        })
        .catch(handleApiError('Cannot disable One Link Send'));
    },
    [dispatch],
  );

  React.useEffect(() => {
    if (typeof setHeader === 'function') {
      setHeader({ title: 'One Link Manager', action: () => history.push(routes.dashboard) });
    }
  }, [setHeader, history]);

  const fetchReasons = React.useCallback(() => {
    dispatch(fetchBusinessReasonsRequest());
  }, [dispatch]);

  const fetchOneLinkSends = React.useCallback(
    (payload: Omit<IFetchOneLinkSendsRequestPayload, 'reject' | 'resolve'>) =>
      new Promise((resolve, reject) => {
        const preparedQuery = query?.toLowerCase().trim();
        dispatch(
          fetchOneLinkSendsRequest({
            ...(selectedDepartments && selectedDepartments.length
              ? {
                  department_ids: Array.isArray(selectedDepartments) ? selectedDepartments : [selectedDepartments],
                }
              : null),
            ...(preparedQuery && { search_query: preparedQuery }),
            ...payload,
            isOrgSearch: filter === FilterTypesEnum.Organization,
            isDeptSearch: filter === FilterTypesEnum.Department,
            status: tab,
            resolve,
            reject,
          }),
        );
      }),
    [query, dispatch, filter, tab, selectedDepartments],
  );

  const handlePaginationChange = React.useCallback(
    ({ page, setPage }: IPaginatedListChangeEvent) => {
      fetchOneLinkSends({
        page,
        page_size: MAX_ONE_LINKS_PER_PAGE,
      }).then(() => {
        setPage(page);
      });
    },
    [fetchOneLinkSends],
  );

  const filterConfig = React.useMemo(() => {
    return ONE_LINK_FILTERS.filter(({ role = [] }) => !role.length || role.includes(adminType)).map(
      ({ filterId, label: optionLabel }) => {
        return {
          label: optionLabel,
          key: optionLabel,
          isActive: filterId === filter,
          onClick: () => {
            setFilter(filterId);
            setSelectedDepartments(null);
          },
        };
      },
    );
  }, [filter, setSelectedDepartments, adminType]);

  React.useEffect(() => {
    fetchOneLinkSends({
      page: 1,
      page_size: MAX_ONE_LINKS_PER_PAGE,
    });

    paginatedListRef.current?.resetPageCount();
  }, [query, filter, tab, selectedDepartments, fetchOneLinkSends]);

  React.useEffect(() => {
    if (!reasons || !reasons.length) {
      fetchReasons();
    }
  }, [reasons, fetchReasons]);

  const tabIndex = React.useMemo(() => Object.values(OneLinkStatusEnum).indexOf(tab as OneLinkStatusEnum), [tab]);

  const getRenderTab = React.useCallback(
    (itemsList: IOneLink[]) => {
      return !itemsList || !itemsList.length ? (
        <NoResultsPlaceholder />
      ) : (
        <div className={styles.list}>
          {itemsList.map((oneLink: IOneLink) => {
            const isActive = oneLink.status === OneLinkStatusEnum.Active;
            const disableInProgress = disabledLoaderIds?.includes(oneLink.uid);

            return (
              <OneLinkCard
                key={oneLink.uid}
                engagement={oneLink}
                wide
                className={styles.card}
                controls={[
                  <ReportsCopyableButton
                    key="copy-link"
                    hint="Copy link"
                    className={cn(styles.control, { [styles.mobile]: mobile })}
                    isIcon={mobile}
                    value={oneLink.url}
                  />,
                  oneLink?.password && (
                    <ReportsCopyableButton
                      key="copy-password"
                      hint="Copy password"
                      className={cn(styles.control, { [styles.mobile]: mobile })}
                      isIcon={mobile}
                      value={oneLink.password}
                      name="Copy password"
                    />
                  ),
                  isActive && (
                    <AsyncButton
                      isLoading={disableInProgress}
                      className={cn(styles.control, styles.disable, {
                        [styles.mobile]: mobile,
                        [styles.isLoading]: disableInProgress,
                      })}
                      key="stop-button"
                      onClick={() => handleDisableOneLink(oneLink)}
                      loaderClassName={styles.disableLoader}
                    >
                      {mobile ? <DeleteIcon /> : 'Stop campaign'}
                    </AsyncButton>
                  ),
                  <ActionButton
                    size={UISizeEnum.Small}
                    outlined
                    className={cn(styles.control, styles.viewButton, { [styles.mobile]: mobile })}
                    key="view-button"
                    title="View summary"
                    onClick={() =>
                      history.push(routes.oneLinkManager.getOneLinkManagerUrl({ itemId: oneLink.uid, status: tab }))
                    }
                  />,
                ]}
              />
            );
          })}
        </div>
      );
    },
    [mobile, handleDisableOneLink, disabledLoaderIds, tab, history],
  );

  const setTabIndex = React.useCallback((index: number) => setTab(Object.values(OneLinkStatusEnum)[index]), []);

  const DeptFilterCustomContainer: React.FC<IUseFilterSelectorContainerProp> = React.useCallback(
    ({ children, reset, select }) => (
      <div className={styles.deptFilterContainer}>
        {children}
        <div className={styles.controls}>
          <ActionButton onClick={reset} title="Reset" outlined size={UISizeEnum.Small} />
          <ActionButton onClick={select} title="Select" size={UISizeEnum.Small} />
        </div>
      </div>
    ),
    [],
  );

  return (
    <div className={cn(styles.container)}>
      <div className={styles.filters}>
        {isAdminTypeUser && (
          <div className={styles.adminFilters}>
            {SearchInput}
            <Filter config={filterConfig} />
            {hasPermission([ORG_ADMIN, SUPER_ADMIN], adminType) &&
              DeptFilter({
                selectContainer: DeptFilterCustomContainer,
                isMulti: true,
                defaultValue: null,
                limit: 10,
                onLimitReach: () =>
                  notification.warning(NotificationListEnum.RestrictionOnSelectionFromSelect, {
                    content: 'You can select only 10 departments at a time.',
                  }),
              })}
          </div>
        )}
      </div>
      <PaginatedList<IOneLink>
        totalCount={oneLinkTotalCount}
        ref={paginatedListRef}
        items={filteredOneLinks}
        pageSize={MAX_ONE_LINKS_PER_PAGE}
        onChange={handlePaginationChange}
        isFinalItems={isFinalOneLink}
      >
        {({ items, pagination: Pagination }) => {
          return (
            <TabProvider
              className={styles.tabs}
              tabs={ONE_LNK_TABS.map((tabItem) => ({
                ...tabItem,
                renderer: tab === tabItem.tabId ? getRenderTab(items) : null,
              }))}
              activeTab={tabIndex}
              onSelect={setTabIndex}
              disabled={isOneLinksFetching}
            >
              <Pagination className={styles.pagination} />
              {!isGlobalLoaderShown && <Loader className={styles.loader} isLoading={isOneLinksFetching} />}
            </TabProvider>
          );
        }}
      </PaginatedList>
    </div>
  );
};

OneLinkManagerContainer.whyDidYouRender = false;

export default OneLinkManagerContainer;
