import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box, IconButton, Menu, MenuItem, Paper, Typography, useTheme } from '@mui/material';
import { useCallback, useMemo } from 'react';
import * as React from 'react';

type TProps = {
  title: string | React.ReactNode;
  children: React.ReactNode;
  minHeight?: number;
} & (
  | {
      actions?: never;
      onEdit?: () => void;
    }
  | {
      actions?: { title: string; onClick?: () => void; disabled?: boolean }[];
      onEdit?: never;
    }
);

export const Section: React.FC<TProps> = ({ title, actions, onEdit, children, minHeight }) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const menuItems = useMemo(() => {
    return actions?.map(({ title: menuItemTitle, onClick, disabled }) => {
      return (
        <MenuItem
          key={menuItemTitle}
          disabled={disabled}
          onClick={(e) => {
            e.stopPropagation();
            if (onClick) {
              onClick();
            }
            handleClose();
          }}
        >
          {menuItemTitle}
        </MenuItem>
      );
    });
  }, [handleClose, actions]);

  return (
    <Paper elevation={0} square={false} sx={{ p: 3, width: 1, minHeight }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography fontWeight={theme.typography.fontWeightMedium}>{title}</Typography>
        {onEdit && (
          <IconButton size="small" onClick={onEdit}>
            <EditIcon fontSize="small" />
          </IconButton>
        )}
        {actions && (
          <>
            <IconButton size="small" onClick={handleClick}>
              <MoreVertIcon fontSize="small" />
            </IconButton>
            <Menu anchorEl={anchorEl} open={!!anchorEl} onClose={handleClose}>
              {menuItems}
            </Menu>
          </>
        )}
      </Box>
      {children}
    </Paper>
  );
};
