import { useTheme } from '@mui/material';
import cn from 'classnames';
import React from 'react';

import { Drawer } from '../../components';
import useWindowSize from '../../hooks/useWindowSize';
import { DrawerAnimationDirectionEnum } from '../../types/shell';

import styles from './AutomationSidebar.module.scss';

interface IProps {
  onClose: () => void;
  trigger: boolean;
  children?: React.ReactNode;
  withPortal?: boolean;
}

const AutomationSidebar: React.FC<IProps> = ({ trigger, onClose, children, withPortal }) => {
  const { sass: variables } = useTheme();
  const { mobile, HD } = useWindowSize();

  const from = React.useMemo(
    () => (mobile ? variables.automationSidebarMinHeight : variables.automationSidebarMinWidth),
    [mobile],
  );

  const to = React.useMemo(() => {
    if (mobile) {
      return variables.automationSidebarMaxHeight;
    }

    if (HD) {
      return variables.automationSidebarMaxWidthHD;
    }

    return variables.automationSidebarMaxWidthFullHD;
  }, [mobile, HD]);

  return (
    <Drawer
      className={cn(styles.container)}
      direction={mobile ? DrawerAnimationDirectionEnum.Vertically : DrawerAnimationDirectionEnum.Horizontally}
      from={from}
      to={to}
      trigger={trigger}
      onClose={onClose}
      withOverlay
      withPortal={withPortal}
      overlayClassName={styles.overlay}
    >
      <div className={styles.closeBtnContainer}>
        <button className={styles.closeBtn} onClick={onClose} />
      </div>
      {children}
    </Drawer>
  );
};

export default AutomationSidebar;
