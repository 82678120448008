import cn from 'classnames';
import React from 'react';

import { ICommonAddress } from '../../types/shipping';

import styles from './CompactOrgAddressView.module.scss';

interface IProps {
  row: ICommonAddress & { label?: string };
  className?: string;
  addressClassName?: string;
  onReset?: () => void;
}

function CompactOrgAddressView({ row, className, addressClassName, onReset }: IProps) {
  return (
    <div className={cn(styles.container, className)}>
      {row.label && <div className={styles.truncated}>{row.label}</div>}
      <div className={styles.content}>
        <div className={cn(styles.address, styles.truncated, addressClassName)}>
          {row.address1 && <span>{`${row.address1}\u00A0`}</span>}
          {row.address2 && <span>{`${row.address2},\u00A0`}</span>}
          {row.city && <span>{`${row.city},\u00A0`}</span>}
          {row.state && <span>{`${row.state},\u00A0`}</span>}
          {row.zip && <span>{`${row.zip},\u00A0`}</span>}
          {row.country && <span>{row.country}</span>}
        </div>
        {onReset && (
          <button type="button" className={styles.reset} onClick={onReset}>
            Reset
          </button>
        )}
      </div>
    </div>
  );
}

export default CompactOrgAddressView;
