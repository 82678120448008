import cn from 'classnames';
import * as React from 'react';

import { ReactComponent as DragControlIcon } from '../../assets/images/icon-drag-control.svg';
import { useCurrency } from '../../contexts/CurrencyProvider';
import { GenderEnum, IInventoryItem, InventoryTypesEnum } from '../../types/inventories';
import { isItemDigital } from '../../utils/inventories';
import { FCDetails, InventoryItemOptions, ItemMetadata, ItemQuantity, Price, RedCrossButton } from '../index';

import styles from './InventoryViewItem.module.scss';

interface IViewItem {
  item_id: string;
  fulfillment_center_id: string;
  name?: string;
  price?: number;
  image_url: string;
  type: InventoryTypesEnum;
  product_sku?: string;
  sku_options?: IInventoryItem[];
  size?: string;
  color?: string;
  gender?: GenderEnum;
  flavor?: string;
  count?: number;
  quantity?: number;
  other_option?: string;
  item_customization_allowed?: boolean;
  is_hidden_for_recipient?: boolean;
  preorder_allowed?: boolean;
  low_stock_threshold?: number;
}

interface IProps {
  item: IViewItem;
  className?: string;
  iconClassName?: string;
  infoClassName?: string;
  actions?: React.ReactNode;
  showCount?: boolean;
  showQuantity?: boolean;
  isDraggable?: boolean;
  isHighlighted?: boolean;
  isOutlined?: boolean;
  onRemove?: () => void;
  defaultView?: boolean;
}
const InventoryViewItem = React.forwardRef(
  (
    {
      item,
      className,
      iconClassName,
      infoClassName,
      actions,
      showCount,
      showQuantity,
      isDraggable,
      isHighlighted = false,
      isOutlined = false,
      onRemove,
      defaultView,
    }: IProps,
    ref: React.Ref<HTMLDivElement>,
  ) => {
    const {
      item_id: itemId,
      fulfillment_center_id: fulfillmentCenterId,
      name,
      count,
      image_url: imageUrl,
      type,
      product_sku: sku,
      sku_options: skuOptions,
      size,
      color,
      gender,
      flavor,
      quantity,
      other_option: otherOption,
      item_customization_allowed: isCustomizable,
      is_hidden_for_recipient: isHiddenForRecipient,
      preorder_allowed: isPreorder,
      low_stock_threshold: threshold,
    } = React.useMemo(() => item, [item]);

    const { getItemPrice } = useCurrency();

    const isDigitalItem = React.useMemo(() => isItemDigital({ type }), [type]);
    const itemDisplayedPrice = React.useMemo(() => getItemPrice(item), [item, getItemPrice]);

    const itemIconLayout = React.useMemo(() => {
      return (
        <div className={cn(styles.iconInner, iconClassName)}>
          <div className={styles.icon} style={{ backgroundImage: `url(${imageUrl})` }} />
          <ItemMetadata
            type={type}
            isCustomizable={isCustomizable}
            itemId={itemId}
            title={name}
            count={count}
            skuOptions={skuOptions}
            isHidden={isHiddenForRecipient}
            showCount={showCount}
            fulfillmentCenterId={fulfillmentCenterId}
            isPreorder={isPreorder}
            threshold={threshold}
          />
          {showQuantity && quantity && <ItemQuantity quantity={quantity} />}
        </div>
      );
    }, [
      iconClassName,
      imageUrl,
      type,
      isCustomizable,
      itemId,
      name,
      count,
      skuOptions,
      isHiddenForRecipient,
      showCount,
      fulfillmentCenterId,
      showQuantity,
      quantity,
      isPreorder,
      threshold,
    ]);

    const itemDataLayout = React.useMemo(() => {
      return (
        <div className={styles.itemData}>
          <div className={styles.inventoryInfo}>
            <div className={styles.inventoryName}>{name}</div>
            <InventoryItemOptions
              size={size}
              color={color}
              flavor={flavor}
              gender={gender}
              otherOption={otherOption}
              className={styles.optionsContainer}
            />
          </div>
        </div>
      );
    }, [name, size, color, flavor, gender, otherOption]);

    const itemLayout = React.useMemo(
      () => (
        <React.Fragment>
          {itemIconLayout}
          <div className={styles.itemContentContainer}>
            <div className={cn(styles.itemDataContainer, infoClassName)}>
              <Price value={itemDisplayedPrice} className={styles.price} />
              {!isDigitalItem && (
                <FCDetails fulfillmentCenterId={fulfillmentCenterId} productSku={sku} isHighlighted={isHighlighted} />
              )}
            </div>
            {itemDataLayout}
            {actions && <div className={styles.actions}>{actions}</div>}
          </div>
        </React.Fragment>
      ),
      [
        itemDisplayedPrice,
        itemIconLayout,
        infoClassName,
        itemDataLayout,
        isDigitalItem,
        fulfillmentCenterId,
        sku,
        isHighlighted,
        actions,
      ],
    );

    return (
      <div
        ref={ref}
        className={cn(
          styles.inventoryItem,
          { [styles.defaultView]: defaultView },
          { [styles.outlined]: isOutlined },
          { [styles.highlighted]: isHighlighted },
          { [styles.isDraggable]: isDraggable },
          className,
        )}
      >
        {isDraggable ? (
          <div className={styles.dragContainer}>
            <DragControlIcon tabIndex={-10} className={styles.dragControl} />
            {itemLayout}
          </div>
        ) : (
          itemLayout
        )}
        {onRemove && <RedCrossButton onClick={onRemove} size="xs" className={styles.deleteButton} />}
      </div>
    );
  },
);

export default InventoryViewItem;
