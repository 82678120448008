import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import * as React from 'react';

import { variables } from '../../../../contexts/ThemeProvider';

interface IProps {
  children: React.ReactNode;
}

const StyledLabel = styled(Box)(({ theme }) => ({
  fontSize: 14,
  color: variables.graySuit,
  marginBottom: 5,
  [theme.breakpoints.up('xl')]: {
    fontSize: 16,
  },
}));

export const Label: React.FC<IProps> = ({ children }) => {
  return <StyledLabel>{children}</StyledLabel>;
};
