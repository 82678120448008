import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import * as React from 'react';

interface IProps {
  open: boolean;
  title: string;
  message: string;
  onClose: () => void;
  onConfirm: () => void;
  confirmButtonText?: string;
}

export const ConfirmationDialog: React.FC<IProps> = ({
  open,
  title,
  confirmButtonText = 'Confirm',
  message,
  onClose,
  onConfirm,
}) => {
  return (
    <Dialog open={open} onClose={onClose} sx={{ '& .MuiDialog-paper': { p: 5 } }}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ fontSize: 18 }}>{message}</DialogContentText>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center', paddingBottom: 16 }}>
        <Button variant="outlined" size="large" fullWidth onClick={onClose}>
          Cancel
        </Button>
        <Button variant="contained" size="large" fullWidth onClick={onConfirm} autoFocus>
          {confirmButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
