import { ORG_ADDRESS_IDS } from '../constants/campaigns';
import {
  IS_RECEIVER_ADDRESS_FIXED,
  RECEIVER_ADDRESS,
  RECEIVER_ADDRESS1,
  RECEIVER_ADDRESS2,
  RECEIVER_CITY,
  RECEIVER_COMPANY_NAME,
  RECEIVER_COUNTRY,
  RECEIVER_FIRST_NAME,
  RECEIVER_LAST_NAME,
  RECEIVER_PHONE,
  RECEIVER_STATE,
  RECEIVER_ZIP,
  SHIPPING_OPTION,
  SHIP_ORDER_STATUS,
} from '../constants/shipping';

export enum ShippingOptionsEnum {
  Ground = 'ground',
  TwoDays = '2-day',
  ThreeDays = '3-day',
  OverNight = 'overnight',
}

export interface ISendDetailsFormValues {
  [RECEIVER_FIRST_NAME]: string;
  [RECEIVER_LAST_NAME]: string;
  [RECEIVER_COMPANY_NAME]: string;
  [RECEIVER_PHONE]: string;
  [RECEIVER_ADDRESS1]: string;
  [RECEIVER_ADDRESS2]: string;
  [RECEIVER_STATE]: string;
  [RECEIVER_ZIP]: string;
  [RECEIVER_COUNTRY]: string;
  [RECEIVER_CITY]: string;
  [SHIP_ORDER_STATUS]: string;
  [SHIPPING_OPTION]: string;
  note_message?: string;
}

export interface IReceiverInfoDetailsFormValues
  extends Pick<
    ISendDetailsFormValues,
    'receiver_first_name' | 'receiver_last_name' | 'receiver_company_name' | 'receiver_phone'
  > {
  note_message?: string;
}

export interface ICommonAddress {
  address1: string;
  address2?: string;
  city: string;
  country: string;
  state?: string;
  zip: string;
}

export interface IBaseShippingDetails {
  [SHIP_ORDER_STATUS]: string;
  [ORG_ADDRESS_IDS]?: string[];
  [IS_RECEIVER_ADDRESS_FIXED]: boolean;
}

export interface IShippingDetails extends IBaseShippingDetails {
  receiver_address?: ICommonAddress;
}

export interface IBulkShippingDetails extends IBaseShippingDetails {
  receiver_fixed_address: ICommonAddress | undefined;
}

export interface ICampaignShippingConfiguration
  extends Omit<IShippingDetails, 'ship_order_status' | 'receiver_address'> {
  org_address_ids: string[];
  is_receiver_address_fixed: boolean;
  is_delayed_shipping: boolean;
}

export interface IOneLinkShippingDetails
  extends Pick<IShippingDetails, 'is_receiver_address_fixed' | 'receiver_address' | 'org_address_ids'> {}

export interface IBulkPayloadProps {
  is_delayed_shipping?: boolean;
  [ORG_ADDRESS_IDS]?: (string | undefined)[];
  [IS_RECEIVER_ADDRESS_FIXED]?: boolean;
  [RECEIVER_ADDRESS]?: ICommonAddress;
}
