import format from 'date-fns/format';

import { OrgAddressFilterTypesEnum } from '../constants/addressBook';
import {
  DEFAULT_DELAYED_SHIPPING_EXPIRATION_DATE,
  DELAYED_SHIPPING_EXPIRATION,
  SEND_ON_HOLD,
} from '../constants/bucket';
import { CUSTOM, ORG_ADDRESS_IDS } from '../constants/campaigns';
import { DateFormatsEnum } from '../constants/date';
import {
  AWAITING_ADDRESS,
  CRM_TYPE,
  DISABLE_EMAIL_NOTIFICATIONS,
  DISABLE_SENDER_EMAIL_NOTIFICATIONS,
  IS_RECEIVER_ADDRESS_FIXED,
} from '../constants/shipping';
import { IOrgAddress } from '../types/addressBook';
import {
  IFileInfo,
  IFlowStepProps,
  IOneLinkEngagementCandidate,
  TEngagementCandidate,
  TEngagementRequestPayload,
} from '../types/bucket';
import { ICampaign } from '../types/campaigns';
import { IOnDemandItem } from '../types/inventories';
import { ICommonAddress, IOneLinkShippingDetails, IShippingDetails } from '../types/shipping';
import { isDigitalBox } from './campaigns';
import { formatDate } from './date';
import { hasMskuItem, isItemMsku, isItemOnDemand } from './inventories';
import { mapOrgAddressToCommonAddress } from './oneLink';
import { isAwaitingAddress } from './reports';

export const getFileInfo = (file: File): IFileInfo | null => {
  if (!file) {
    return null;
  }
  const { name, lastModified, size } = file;

  return { name, lastModified, size };
};

export const formatLastModified = (date: number) => format(date, `d MMM, yyyy 'at' H:mm aaa`);

export const formatFileSize = (size: number) => {
  let unitPrefix = '';
  let sizeToShow = size.toString();

  if (size >= 100 && size < 1024 * 1024) {
    unitPrefix = 'K';
    sizeToShow = (size / 1024).toFixed(1);
  } else if (size >= 1024 * 1024) {
    unitPrefix = 'M';
    sizeToShow = (size / 1024 / 1024).toFixed(1);
  }

  return `${sizeToShow} ${unitPrefix}b`;
};

export const getSubstringAfterChar = (str: string, char: string) => str?.substring(str.indexOf(char) + 1);

export const mapShippingDetailsToSend = (shippingDetails: IShippingDetails) => {
  const { receiver_address, ...restShippingDetails } = shippingDetails;

  const {
    // tslint:disable:variable-name
    address1: receiver_address1,
    address2: receiver_address2,
    city: receiver_city,
    zip: receiver_zip,
    country: receiver_country,
    state: receiver_state,
    // tslint:enable:variable-name
  } = receiver_address || {};

  return {
    ...restShippingDetails,
    receiver_address1,
    receiver_address2,
    receiver_city,
    receiver_zip,
    receiver_country,
    receiver_state,
  };
};

type EngagementMapperMetadata = Omit<IFlowStepProps, 'className' | 'SenderInfo' | 'isPYG'> & {
  crmType: 'salesforce' | 'hubspot' | 'none';
};

/**
 * Maps the engagement candidate to the engagement request payload.
 *
 * @param {TEngagementCandidate | IOneLinkEngagementCandidate} engagement - The engagement candidate to be mapped.
 * @param {EngagementMapperMetadata} metadata - Additional metadata for the engagement mapping.
 * @returns {TEngagementRequestPayload} - The mapped engagement request payload.
 */
export const mapEngagementCandidateToRequest = (
  engagement: TEngagementCandidate | IOneLinkEngagementCandidate,
  metadata: EngagementMapperMetadata,
): TEngagementRequestPayload => {
  const { items: engagementItems, pick_campaigns, disable_sender_email_notifications, ...restEngagement } = engagement;
  const { isOneLink, crmType } = metadata;
  const { on_hold_until, delayed_shipping_expiration } = engagement;

  const isDelayedShipping = !isOneLink && isAwaitingAddress(engagement as TEngagementCandidate);

  const items = engagementItems?.map((item) => {
    if (isItemOnDemand(item)) {
      const onDemandItem = item as IOnDemandItem;
      const { item_id, ...onDemandRequestItem } = onDemandItem;
      return onDemandRequestItem;
    }
    if (isItemMsku(item) && item.sku_options?.length) {
      const { sku_options, ...restItem } = item;

      return { ...restItem, sku_option_ids: sku_options?.map(({ item_id }) => item_id) };
    }

    return item;
  });

  const onHoldUntil = (() => {
    if (on_hold_until?.length) {
      return on_hold_until;
    }
    return undefined;
  })();

  const expirationDate = (() => {
    if (!isDelayedShipping) {
      return;
    }
    if (delayed_shipping_expiration?.length) {
      return delayed_shipping_expiration;
    }

    return formatDate(DEFAULT_DELAYED_SHIPPING_EXPIRATION_DATE.toISOString(), DateFormatsEnum.End);
  })();

  // Below is a workaround and legacy issue in existing send models
  // Sends have the flag with the name `disable_email_notifications`
  // OneLinks and bulk request have the flag with the name `disable_sender_email_notifications`
  // Both flags are the same, but should be mapped differently in the request
  // isBulk case is not covered here because it's not used there in the current implementation
  const senderNotificationsConfig = {
    [isOneLink ? DISABLE_SENDER_EMAIL_NOTIFICATIONS : DISABLE_EMAIL_NOTIFICATIONS]: disable_sender_email_notifications,
  };

  // let shippingDetails;
  //
  // if (isOneLink) {
  //   const { is_receiver_address_fixed, receiver_fixed_address, org_address_ids } =
  //     engagement as IOneLinkEngagementCandidate;
  //
  //   shippingDetails = {
  //     is_receiver_address_fixed,
  //     org_address_ids,
  //     receiver_fixed_address,
  //   };
  // }

  return {
    ...restEngagement,
    ...(pick_campaigns ? { pick_campaign_ids: pick_campaigns.map(({ box_id }) => box_id) } : {}),
    items,
    ...senderNotificationsConfig,
    [SEND_ON_HOLD]: onHoldUntil,
    [DELAYED_SHIPPING_EXPIRATION]: expirationDate,
    ...(!isOneLink ? { [CRM_TYPE]: crmType } : {}),
  };
};

const filterActiveAddresses = (addressIDs?: string[], addressBook?: IOrgAddress[]): string[] | undefined => {
  const activeAddressIDs = addressIDs?.filter((uid) => {
    const address = addressBook?.find((adr) => adr.uid === uid);

    return address?.status === OrgAddressFilterTypesEnum.Active;
  });

  return activeAddressIDs?.length ? activeAddressIDs : undefined;
};

// Unable to move it to constants because of a bundler error
export const DEFAULT_OL_SHIPPING_DETAILS: IOneLinkShippingDetails = {
  [IS_RECEIVER_ADDRESS_FIXED]: false,
  [ORG_ADDRESS_IDS]: undefined,
  receiver_address: undefined,
};

export const DEFAULT_SEND_SHIPPING_DETAILS = (isDS: boolean = false): IShippingDetails => ({
  [IS_RECEIVER_ADDRESS_FIXED]: !isDS,
  [ORG_ADDRESS_IDS]: undefined,
  ship_order_status: isDS ? AWAITING_ADDRESS : '',
  receiver_address: isDS
    ? undefined
    : {
        address1: '',
        address2: '',
        city: '',
        state: '',
        country: '',
        zip: '',
      },
});

export const getShippingDetailsFromCampaign = (
  campaign: ICampaign | ICampaign[],
  { isOneLink, addressBook }: IFlowStepProps & { addressBook?: IOrgAddress[] },
): IShippingDetails | IOneLinkShippingDetails | undefined => {
  // return nothing in case of a digital flow
  if (Array.isArray(campaign) ? campaign.every(isDigitalBox) : isDigitalBox(campaign)) {
    return;
  }

  if (Array.isArray(campaign)) {
    // PYG case - return default values
    switch (true) {
      case isOneLink:
        return DEFAULT_OL_SHIPPING_DETAILS;
      default:
        return DEFAULT_SEND_SHIPPING_DETAILS(true);
    }
  }

  const hasMSKU = hasMskuItem(campaign.items);
  const { is_receiver_address_fixed, is_delayed_shipping, org_address_ids } = campaign.shipping_configuration || {};

  const hasOneAddressRecord = org_address_ids?.length === 1;
  const hasCustomAddressRecord = org_address_ids?.includes(CUSTOM);

  if (isOneLink) {
    switch (true) {
      case !is_delayed_shipping:
        // OneLink is always DS
        // so if it was turned off on campaign -> such campaign's shipping_configuration is not suitable for this flow
        return DEFAULT_OL_SHIPPING_DETAILS;
      case is_receiver_address_fixed: {
        // 'Recipient decides from list only' OR 'Fixed' case
        // When 'Fixed' -> campaign's org_address_ids can have only 1 item - either CUSTOM or an address ID
        return {
          is_receiver_address_fixed: true,
          // in case there's a custom address OR there are more than one address added, we should not set org_address_ids
          org_address_ids:
            hasOneAddressRecord || hasCustomAddressRecord
              ? undefined
              : filterActiveAddresses(org_address_ids, addressBook),
          receiver_address: (() => {
            // in case there is more than 1 address ID, we should not set receiver_fixed_address
            if (!hasOneAddressRecord) {
              return undefined;
            }

            const emptyReceiverAddress = {} as ICommonAddress;
            // in case there should be a custom address set during the flow,
            // receiver_fixed_address should NOT be undefined, but the empty object
            // it will be used as a flag to show the proper shipping details selector value on the UI
            if (hasCustomAddressRecord) {
              return emptyReceiverAddress;
            }

            const addressRecord = addressBook?.find(
              (a) => a.status === OrgAddressFilterTypesEnum.Active && a.uid === org_address_ids[0],
            );

            // in case the address is not found in the address book, we should return the empty object
            return addressRecord ? mapOrgAddressToCommonAddress(addressRecord) : emptyReceiverAddress;
          })(),
        };
      }
      // 'Recipient decides' case
      // We should take care of active addresses filtering, all the rest should be passed as it is, no fixed address populated
      case !!org_address_ids?.length: {
        return {
          is_receiver_address_fixed: false,
          org_address_ids: filterActiveAddresses(org_address_ids, addressBook),
          receiver_address: undefined,
        };
      }
      // Should be unreachable case
      default: {
        console.warn('Unreachable case in getShippingDetailsFromCampaign. Contact the product team and share details.');
        return DEFAULT_OL_SHIPPING_DETAILS;
      }
    }
  }

  switch (true) {
    case !is_delayed_shipping: {
      // We should check if DS should be forcely turned on
      // so if it was turned off on campaign -> such campaign's shipping_configuration is not suitable for this flow
      return hasMSKU
        ? // if MSKU flow and non-DS campaign -> the campaign configuration doesn't suit the flow -> default values for DS flow are returned
          DEFAULT_SEND_SHIPPING_DETAILS(true)
        : {
            // for non-MSKU flow and nonDS campaign -> nonDS default values are returned with receiver_address populated from the address book (if possible)
            ship_order_status: '',
            is_receiver_address_fixed: true,
            org_address_ids: undefined,
            receiver_address:
              org_address_ids?.length && !org_address_ids.includes(CUSTOM)
                ? (() => {
                    const address = addressBook?.find(
                      ({ uid, status }) => status === OrgAddressFilterTypesEnum.Active && uid === org_address_ids[0],
                    );
                    return address ?? DEFAULT_SEND_SHIPPING_DETAILS().receiver_address;
                  })()
                : DEFAULT_SEND_SHIPPING_DETAILS().receiver_address,
          };
    }
    case is_receiver_address_fixed: {
      // 'Recipient decides from list only' OR 'Fixed' case
      // these options are available only on DS flow
      // When 'Fixed' -> campaign's org_address_ids can have only 1 item - either CUSTOM or an address ID

      // if this is a simple send and 'Fixed' case but the DS is ON, we should use default options for simple sends
      const fixedAddressWithDS = !hasMSKU && is_delayed_shipping && hasOneAddressRecord;

      if (fixedAddressWithDS) {
        return DEFAULT_SEND_SHIPPING_DETAILS(false);
      }

      return {
        ship_order_status: AWAITING_ADDRESS,
        is_receiver_address_fixed: true,
        org_address_ids:
          hasOneAddressRecord || hasCustomAddressRecord
            ? undefined
            : filterActiveAddresses(org_address_ids, addressBook),
        receiver_address: (() => {
          // in case there is more than 1 address ID, we should not set receiver_fixed_address
          if (!hasOneAddressRecord) {
            return undefined;
          }

          const emptyReceiverAddress = {} as ICommonAddress;
          // in case there should be a custom address set during the flow,
          // receiver_fixed_address should NOT be undefined, but the empty object
          // it will be used as a flag to show the proper shipping details selector value on the UI
          if (hasCustomAddressRecord) {
            return emptyReceiverAddress;
          }

          const addressRecord = addressBook?.find(
            (a) => a.status === OrgAddressFilterTypesEnum.Active && a.uid === org_address_ids[0],
          );

          // in case the address is not found in the address book, we should return the empty object
          return addressRecord ? mapOrgAddressToCommonAddress(addressRecord) : emptyReceiverAddress;
        })(),
      };
    }

    case !!org_address_ids?.length: {
      // 'Recipient decides' case
      // We should take care of active addresses filtering, all the rest should be passed as it is, no fixed address populated
      return {
        ship_order_status: AWAITING_ADDRESS,
        is_receiver_address_fixed: false,
        org_address_ids: filterActiveAddresses(org_address_ids, addressBook),
        receiver_address: undefined,
      };
    }

    default: {
      return DEFAULT_SEND_SHIPPING_DETAILS(is_delayed_shipping);
    }
  }
};
