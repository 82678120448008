import { Box, Button } from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { select2FARecipientsList } from '../../../store/selectors/bucket';
import { IOneLinkWithCampaignSummaries } from '../../../types/oneLink';
import { IToggleRef } from '../../../types/shell';
import { TwoFASidebarToggle } from '../../index';
import { ConfirmationDialog } from '../../_mui/_dialogs_';
import { Section } from './common';

interface IProps {
  reportMode?: boolean;
  instance: IOneLinkWithCampaignSummaries | null | undefined;
}

export const TwoFASection: React.FC<IProps> = ({ reportMode, instance }) => {
  const twoFARecipientList = useSelector(select2FARecipientsList);

  const [dialogOpen, setDialogOpen] = useState(false);

  // The refs below is an imperative handles for the 2FA Sidebar control and Fixed Address Sidebar control
  const twoFASidebarRef = React.useRef<IToggleRef>(null);

  const isAllowedDomainsConfigured = useMemo(() => Boolean(instance?.allowed_domains), [instance?.allowed_domains]);
  const is2FAEnabled = useMemo(() => instance?.enabled_2fa, [instance?.enabled_2fa]);

  const handleOpen2FASidebar = useCallback(() => {
    if (isAllowedDomainsConfigured) {
      setDialogOpen(true); // Show confirmation dialog
    } else {
      twoFASidebarRef.current?.open();
    }
  }, [isAllowedDomainsConfigured]);

  const handleConfirm = () => {
    setDialogOpen(false);
    twoFASidebarRef.current?.open();
  };

  const totalRecipients = React.useMemo(() => {
    // @ts-ignore TODO this will be fixed when edit is done
    return instance?.recipients_count || instance?.recipients?.length;
    // @ts-ignore
  }, [instance?.recipients?.length, instance?.recipients_count]);

  const total2FARecipients = reportMode ? totalRecipients : twoFARecipientList?.successfulRecipients?.length;

  const buttonTitle = useMemo(() => {
    switch (true) {
      case !is2FAEnabled:
        return 'Enable 2FA';
      case is2FAEnabled && !total2FARecipients:
        return 'Manage recipients';
      case is2FAEnabled && total2FARecipients > 0:
        return `Manage ${total2FARecipients} recipients`;
      default:
        return 'Enable 2FA';
    }
  }, [is2FAEnabled, total2FARecipients]);

  return (
    <Section title="Two-factor Authentication (2FA)">
      <Box>
        <Button
          disabled={!is2FAEnabled}
          title={buttonTitle}
          onClick={handleOpen2FASidebar}
          variant="contained"
          size="large"
          fullWidth
        >
          {buttonTitle}
        </Button>
        <TwoFASidebarToggle
          ref={twoFASidebarRef}
          count={total2FARecipients}
          oneLinkId={instance?.uid}
          status={instance?.status}
          hideControl={true}
        />
      </Box>
      <ConfirmationDialog
        open={dialogOpen}
        title="Confirm configure 2FA"
        message="If you want to configure 2FA, then Domain protection will be disabled."
        onClose={() => setDialogOpen(false)}
        onConfirm={handleConfirm}
        confirmButtonText="Configure 2FA"
      />
    </Section>
  );
};
