import { createTheme } from '@mui/material/styles';

import { ITheme } from '../../types/shell';
import { isLightColor } from '../../utils/ui';
import variables from './variables';

const getThemeConfig = (theme?: ITheme) =>
  createTheme({
    wl: theme,
    sass: variables,
    palette: {
      ...(theme && !!theme.portal_accent_color
        ? {
            primary: {
              main: theme.portal_accent_color,
              contrastText: isLightColor(theme.portal_accent_color) ? variables.ebony : variables.white,
            },
          }
        : {}),
      text: {
        primary: variables.ebony,
      },
    },
    shape: {
      borderRadius: 8,
    },
    typography: {
      fontFamily: 'Gilroy, sans-serif',
      fontWeightMedium: 600,
      fontWeightRegular: 400,
    },
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            '& .MuiInputBase-input.Mui-disabled': {
              WebkitTextFillColor: variables.ebony,
            },
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          root: {
            '&.Mui-disabled': {
              opacity: 1,
            },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: 4,
            textTransform: 'none',
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            zIndex: 0,
          },
        },
      },
    },
  });

export default getThemeConfig;
