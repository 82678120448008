import { Box } from '@mui/material';
import { useMemo } from 'react';
import * as React from 'react';

import { useCurrency } from '../../../contexts/CurrencyProvider';
import { IOneLinkWithCampaignSummaries } from '../../../types/oneLink';
import { hasMskuItem } from '../../../utils/inventories';
import { CampaignPreview, CampaignViewItem, InventoryViewItem } from '../../index';
import { Section } from './common';

interface IProps {
  instance: IOneLinkWithCampaignSummaries | null;
  onEdit?: () => void;
}

export const InventorySection: React.FC<IProps> = ({ instance, onEdit }) => {
  const { getCampaignTotal } = useCurrency();

  const { items, pick_campaigns } = useMemo(
    () =>
      instance ?? {
        items: [],
        pick_campaigns: [],
      },
    [instance],
  );

  const isPYG = useMemo(() => pick_campaigns?.length, [pick_campaigns]);

  const itemsLayout = useMemo(() => {
    if (!items?.length) {
      return null;
    }

    return items.map((item) => {
      // BE doesn't add sku_options into the GetOneLinkSend(s) responses.
      // As a temporary solution, we excluded sku_options from all items - ref. EX-4762
      const { sku_options, ...rest } = item;

      return <InventoryViewItem key={item.item_id} item={rest} showCount={false} showQuantity defaultView />;
    });
  }, [items]);

  const campaignsLayout = useMemo(() => {
    if (!pick_campaigns?.length) {
      return null;
    }

    return pick_campaigns.map((item) => {
      const { box_id: boxId, name, icon_url: iconUrl, items: campaignItems } = item;
      const hasMsku = hasMskuItem(campaignItems);

      return (
        <CampaignViewItem
          key={boxId}
          defaultView
          name={name}
          hasMsku={hasMsku}
          price={getCampaignTotal(item)}
          imageUrl={iconUrl}
          hint={<CampaignPreview showPreview defaultView campaign={item} showWarning />}
        />
      );
    });
  }, [pick_campaigns, getCampaignTotal]);

  const title = useMemo(() => {
    switch (true) {
      case !!isPYG:
        return 'Campaigns selected';
      case !!instance?.campaign_name:
        return instance?.campaign_name;
      default:
        return 'Custom send';
    }
  }, [isPYG, instance?.campaign_name]);

  return (
    <Section title={title} minHeight={420} onEdit={onEdit}>
      <Box sx={{ maxHeight: 330, overflowY: 'auto' }}>{isPYG ? campaignsLayout : itemsLayout}</Box>
    </Section>
  );
};
