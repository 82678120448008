import * as React from 'react';
import { useSelector } from 'react-redux';

import { AddressList, SingleAddress } from '../../../components';
import { selectAddressBook } from '../../../store/selectors/addressBook';
import { ICommonAddress } from '../../../types/shipping';
import { isObjectEmpty } from '../../../utils/helpers';

interface IProps {
  addresses?: string[];
  receiverAddress?: ICommonAddress;
  forceAddressList?: boolean;
  onReset?: () => void;
  maxHeightList?: boolean;
}

const AddressPreview: React.FC<IProps> = ({ addresses, receiverAddress, forceAddressList, onReset, maxHeightList }) => {
  const isEmptyReceiverAddress = isObjectEmpty(receiverAddress);
  const shouldShowSingleAddress =
    !forceAddressList && (!isEmptyReceiverAddress || (addresses && addresses.length === 1));

  const addressBook = useSelector(selectAddressBook);

  if (shouldShowSingleAddress) {
    const fixedAddressItem = isEmptyReceiverAddress
      ? addressBook?.find((address) => address.uid === addresses?.[0])
      : receiverAddress;
    return <SingleAddress address={fixedAddressItem} onReset={onReset} />;
  }

  if (!shouldShowSingleAddress) {
    return addresses?.length ? (
      <AddressList
        addresses={addressBook?.filter((address) => addresses?.includes(address.uid)).map((address) => address)}
        isMaxHeightList={maxHeightList}
      />
    ) : null;
  }

  return null;
};

export default AddressPreview;
