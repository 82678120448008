import { useTheme } from '@mui/material';
import cn from 'classnames';
import isEqual from 'lodash/isEqual';
import * as React from 'react';

import { BackButton, Drawer, UnsavedChangesModal } from '../../components';
import useModal from '../../hooks/useModal';
import useWindowSize from '../../hooks/useWindowSize';
import { IReportEngagementItem } from '../../types/reports';
import { DrawerAnimationDirectionEnum } from '../../types/shell';
import { AddSendItemsContainer } from '../index';
import { IAddItemsRef } from './AddSendItemsContainer/AddSendItemsContainer';

import styles from './AddSendItemsSidebar.module.scss';

interface IAddSendItemsSidebarProps {
  onClose: () => void;
  reportItems?: IReportEngagementItem[] | null;
  isShown?: boolean;
  status?: string;
  onSubmit?: (items: IReportEngagementItem[] | undefined) => void;
  isDirectSend?: boolean;
}

const AddSendItemsSidebar: React.FC<IAddSendItemsSidebarProps> = ({
  reportItems,
  isShown,
  status,
  onSubmit,
  onClose,
  isDirectSend,
}) => {
  const { sass: variables } = useTheme();
  const { mobile, HD } = useWindowSize();
  const { openModal: openSaveChangesModal, closeModal: closeSaveChangesModal, Modal: SaveChangesModal } = useModal();

  const itemsRef = React.useRef<IAddItemsRef>(null);

  const from = React.useMemo(() => (mobile ? variables.boxSidebarMinHeight : variables.boxSidebarMinWidth), [mobile]);
  const to = React.useMemo(() => {
    if (mobile) {
      return variables.boxSidebarMaxHeight;
    }

    if (HD) {
      return variables.boxSidebarMaxWidthHD;
    }

    return variables.boxSidebarMaxWidthFullHD;
  }, [mobile, HD]);

  const handleItemsSubmit = React.useCallback(() => {
    if (typeof onSubmit === 'function') {
      onSubmit(itemsRef.current?.getValues());
    }
  }, [onSubmit]);

  const saveChangesModalActions = React.useMemo(() => {
    return [
      { title: 'Save and close', onClick: handleItemsSubmit },
      {
        title: 'Discard changes',
        onClick: () => {
          if (onClose) {
            onClose();
          }
        },
        isOutlined: true,
      },
    ];
  }, [onClose, handleItemsSubmit]);

  const saveChangesModal = React.useMemo(
    () => (
      <SaveChangesModal
        overlayClassName={styles.saveChangesModalOverlay}
        className={cn(styles.saveChangesModal, 'common-modal')}
      >
        {() => <UnsavedChangesModal actions={saveChangesModalActions} onClose={closeSaveChangesModal} />}
      </SaveChangesModal>
    ),
    [closeSaveChangesModal, saveChangesModalActions, SaveChangesModal],
  );

  const handleCloseSidebar = React.useCallback(() => {
    if (itemsRef.current?.getValues() && !isEqual(itemsRef.current?.getValues(), reportItems)) {
      return openSaveChangesModal();
    }

    if (typeof onClose === 'function') {
      return onClose();
    }
  }, [openSaveChangesModal, onClose, reportItems]);

  return (
    <React.Fragment>
      <Drawer
        className={cn(styles.container)}
        direction={mobile ? DrawerAnimationDirectionEnum.Vertically : DrawerAnimationDirectionEnum.Horizontally}
        from={from}
        to={to}
        trigger={!!isShown}
        onClose={handleCloseSidebar}
        withOverlay
        overlayClassName={styles.overlay}
      >
        <div className={styles.contentContainer}>
          <div className={styles.closeButtonContainer}>
            <button className={styles.closeButton} onClick={handleCloseSidebar} />
          </div>
          <div className={styles.titleContainer}>
            <BackButton className={styles.back} onClick={handleCloseSidebar} />
            <div className={styles.title}>Add Items</div>
          </div>
          <div className={styles.content}>
            <AddSendItemsContainer
              reportItems={reportItems}
              ref={itemsRef}
              canRemoveAll={false}
              onSubmit={handleItemsSubmit}
              isShown={isShown}
              onCancel={handleCloseSidebar}
              status={status}
              isDirectSend={isDirectSend}
            />
          </div>
        </div>
      </Drawer>
      {saveChangesModal}
    </React.Fragment>
  );
};

export default AddSendItemsSidebar;
