import { Box, MenuItem, TextField, Typography, useTheme } from '@mui/material';
import addYears from 'date-fns/addYears';
import { useFormik } from 'formik';
import { forwardRef, useEffect, useMemo } from 'react';
import * as React from 'react';
import { useSelector } from 'react-redux';
import * as yup from 'yup';

import { DateFormatsEnum } from '../../../constants/date';
import { IOneLinkChunkFormProps } from '../../../contexts/OneLinkSummaryProvider';
import { selectUsers } from '../../../store/selectors/users';
import { IOneLinkWithCampaignSummaries } from '../../../types/oneLink';
import { formatDate, isValidDate } from '../../../utils/date';
import { getFullName, isObjectsEqual } from '../../../utils/helpers';
import { getOneLinkStatusAndDate } from '../../../utils/oneLink';
import { ReportsStatus } from '../../index';
import { FormControls, Label } from '../../SummaryComponentMUI/components/common';
import { ExpirationDateInput, UserOptionMUI } from '../inputs';

const SummaryForm = forwardRef(({ onSubmit, instance, onClose }: IOneLinkChunkFormProps, ref: any) => {
  const { sass } = useTheme();
  const users = useSelector(selectUsers);

  const validationSchema = yup.object().shape({
    expiration_date: yup.string().nullable(),
    title: yup.string().notRequired(),
    user_id: yup.string().required('Sender is required'),
  });

  const formik = useFormik({
    initialValues: {
      title: instance?.title || '',
      user_id: instance?.user_id || '',
      expiration_date:
        instance?.expiration_date && isValidDate(new Date(instance.expiration_date)) ? instance.expiration_date : null,
    },
    validationSchema,
    onSubmit: (values) => {
      onSubmit({ summary_info: values });
      ref.current = false;
    },
  });

  const { user_id, expiration_date, title } = formik.values;
  const { handleChange, setFieldValue, handleSubmit, initialValues } = formik;

  const { label: statusLabel, color: statusColor } = React.useMemo(() => {
    if (instance) {
      return getOneLinkStatusAndDate(instance as IOneLinkWithCampaignSummaries);
    }
    return { label: '', color: '' };
  }, [instance]);

  const status = useMemo(
    () => <ReportsStatus status={statusLabel} backgroundColor={statusColor} />,
    [statusColor, statusLabel],
  );

  useEffect(() => {
    ref.current = !isObjectsEqual(initialValues, formik.values);
  }, [formik.values, initialValues]);

  return (
    <Box
      component="form"
      role="form"
      sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}
      onSubmit={handleSubmit}
    >
      <Box>
        <Box sx={{ mb: 4 }}>
          <Label>
            <Typography component="span" sx={{ mb: 2 }}>
              Status
            </Typography>
            <Typography component="span" sx={{ color: sass.ebony }}>
              {status}
            </Typography>
          </Label>
        </Box>
        <TextField
          label="Title"
          name="title"
          value={title}
          onChange={handleChange}
          variant="outlined"
          fullWidth
          sx={{ mb: 4 }}
        />
        <TextField
          label="Sender"
          name="user_id"
          value={user_id}
          onChange={handleChange}
          variant="outlined"
          select
          fullWidth
          sx={{ mb: 4 }}
          slotProps={{
            select: {
              renderValue: (selected) => {
                const selectedUser = users?.find((user) => user.uid === selected);
                return selectedUser ? getFullName(selectedUser.first_name, selectedUser.last_name) : '';
              },
              MenuProps: {
                sx: {
                  '& .MuiPaper-root': {
                    maxHeight: 260,
                    overflowY: 'auto',
                  },
                },
              },
            },
          }}
        >
          {users?.map((user) => (
            <MenuItem
              key={user.uid}
              value={user.uid}
              sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}
            >
              <UserOptionMUI user={user} />
            </MenuItem>
          ))}
        </TextField>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Label>Expiration Date</Label>
          <ExpirationDateInput
            value={expiration_date}
            maxDate={addYears(new Date(), 1)}
            onChange={(newStartDate) => {
              setFieldValue('expiration_date', newStartDate ? formatDate(newStartDate, DateFormatsEnum.End) : null);
            }}
            showLabel={false}
            defaultView
            resetLabel="Reset"
          />
        </Box>
      </Box>
      <FormControls
        onCancel={() => {
          onClose();
        }}
        isFormChanged={ref.current}
        isDisabled={!formik.isValid || !ref.current}
      />
    </Box>
  );
});

export default SummaryForm;
