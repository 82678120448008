import { Typography } from '@mui/material';
import { useMemo } from 'react';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { DEPT_ADMIN, USER } from '../../../../../../constants/users';
import { selectCurrentOrganizationDepartmentList } from '../../../../../../store/selectors/organizations';
import { IUser } from '../../../../../../types/users';
import { getUserRoleLabel, hasPermission } from '../../../../../../utils/users';

interface IProps {
  user: IUser;
}

export const UserOptionMUI: React.FC<IProps> = ({ user: { admin_type, department_id, first_name, last_name } }) => {
  const departments = useSelector(selectCurrentOrganizationDepartmentList);

  const department = useMemo(
    () => departments?.find((d) => d.uid === department_id)?.name,
    [departments, department_id],
  );

  const roleLabel = useMemo(() => getUserRoleLabel(admin_type), [admin_type]);

  const userDepartmentName = useMemo(
    () => (hasPermission([DEPT_ADMIN, USER], admin_type) ? department : null),
    [department, admin_type],
  );

  return (
    <>
      <Typography variant="body1" sx={{ textAlign: 'start' }}>
        {first_name} {last_name}
      </Typography>
      <Typography variant="body2" sx={{ color: 'gray', textAlign: 'start' }}>
        {`${roleLabel}${userDepartmentName ? `, ${userDepartmentName}` : ''}`}
      </Typography>
    </>
  );
};
