import cn from 'classnames';
import * as React from 'react';

import { DateFormatsEnum } from '../../../constants/date';
import { formatDate } from '../../../utils/date';

import styles from './SendDate.module.scss';

interface IProps {
  className?: string;
  label: string;
  value: string;
}

const SendDate = ({ className, label, value }: IProps) => {
  return (
    <div className={cn(styles.container, className)}>
      <span className={styles.label}>{label}</span>
      {formatDate(value, DateFormatsEnum.ddMMMyyyy)}
    </div>
  );
};

export default SendDate;
