import cn from 'classnames';
import format from 'date-fns/format';
import { useMemo } from 'react';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { ReactComponent as LockIcon } from '../../assets/images/icon-lock-green.svg';
import { ReactComponent as PYGIcon } from '../../assets/images/icon-pick-your-gift.svg';
import { PRE_CREATED_ENGAGEMENT_ID, RECEIVER_REMINDER_INTERVAL } from '../../constants/bucket';
import { DateFormatsEnum } from '../../constants/date';
import { TITLE } from '../../constants/oneLink';
import { BUSINESS_REASON_ID } from '../../constants/reasons';
import {
  ALLOWED_DOMAINS,
  ENABLED_2FA,
  EXPIRATION_DATE,
  PASSWORD,
  RECEIVER_FIXED_ADDRESS,
  REDEMPTION_LIMIT,
} from '../../constants/shipping';
import { EMAIL_MESSAGE, EMAIL_SUBJECT, MESSAGE } from '../../constants/templates';
import { USER } from '../../constants/users';
import { AdditionalSummaryContainer, OLShippingDetailsContainer } from '../../containers';
import { addBucketValue } from '../../store/actions/bucket';
import { selectAdminType } from '../../store/selectors/auth';
import {
  select2FARecipientsList,
  selectIsBusinessReasonRequired,
  selectIsReceiverReminderSettingEnabled,
  selectReminderInterval,
} from '../../store/selectors/bucket';
import { IOneLinkEngagementCandidate } from '../../types/bucket';
import { IOneLinkWithCampaignSummaries, OneLinkStatusEnum } from '../../types/oneLink';
import { IOneLinkRouteParams } from '../../types/routing';
import {
  CopyableLinkTypesEnum,
  InventorySidebarTypeEnum,
  IResultPriceWithCurrency,
  IToggleRef,
} from '../../types/shell';
import { formatDate, getUTCDate, isValidDate } from '../../utils/date';
import { getOneLinkCopyableInfo, getOneLinkStatusAndDate } from '../../utils/oneLink';
import { hasPermission } from '../../utils/users';
import {
  ActionButton,
  CopyableButton,
  Input,
  InputLabel,
  IntervalInput,
  ReportsCopyableButton,
  TagInput,
  Textarea,
  ToggleInput,
} from '../forms';
import { FixedAddress, HelpTooltip, HtmlView, Price, ReportsStatus, TwoFASidebarToggle } from '../index';
import InventorySidebar from '../InventorySidebar/InventorySidebar';
import ConfirmedSendsList from './ConfirmedSendsList/ConfirmedSendsList';

import styles from './SummaryComponent.module.scss';

interface ICommonProps {
  className?: string;
  instance?: IOneLinkEngagementCandidate | IOneLinkWithCampaignSummaries | null;
  showNote: boolean;
  showAdvancedSettings: boolean;
  showEmail: boolean;
  total: IResultPriceWithCurrency;
  isDigital: boolean;
  reportMode: boolean;
  isPYG?: boolean;
  showQuantity?: boolean;
}

interface IOneLinkFlowProps extends ICommonProps {
  SenderInfo: React.ReactNode;
  onSubmit: (...args: any[]) => void;
  status?: never;
}

interface IOneLinkReportProps extends ICommonProps {
  SenderInfo?: never;
  onSubmit?: never;
  status: OneLinkStatusEnum;
}

export type TOneLinkSummaryProps = IOneLinkFlowProps | IOneLinkReportProps;

const OneLinkSummaryComponent: React.FC<TOneLinkSummaryProps> = ({
  className,
  instance,
  showNote,
  showAdvancedSettings,
  showEmail,
  total,
  SenderInfo,
  reportMode,
  isPYG,
  showQuantity,
  onSubmit,
  status = OneLinkStatusEnum.Active,
}) => {
  const dispatch = useDispatch();
  const { itemId } = useParams<IOneLinkRouteParams>();

  // The refs below is an imperative handles for the 2FA Sidebar control and Fixed Address Sidebar control
  const twoFASidebarRef = React.useRef<IToggleRef>(null);

  const isReceiverReminderSettingEnabled = useSelector(selectIsReceiverReminderSettingEnabled);
  const receiverReminderInterval = useSelector(selectReminderInterval);
  const twoFARecipientList = useSelector(select2FARecipientsList);
  const isBusinessReasonRequired = useSelector(selectIsBusinessReasonRequired);
  const isDisabled = isBusinessReasonRequired && !instance?.[BUSINESS_REASON_ID];
  const adminType = useSelector(selectAdminType);

  const shippingValues = useMemo(
    () => ({
      is_receiver_address_fixed: instance?.is_receiver_address_fixed ?? false,
      receiver_address: instance?.receiver_fixed_address,
      org_address_ids: instance?.org_address_ids,
    }),
    [instance?.is_receiver_address_fixed, instance?.receiver_fixed_address, instance?.org_address_ids],
  );

  const {
    createdAtDate,
    label: statusLabel,
    color: statusColor,
  } = React.useMemo(() => {
    if (reportMode && instance) {
      return getOneLinkStatusAndDate(instance as IOneLinkWithCampaignSummaries);
    }

    return { date: '', label: '', color: '', dateLabel: '', createdAtDate: '' };
  }, [reportMode, instance]);

  const additionalForm = React.useMemo(() => {
    return (
      <AdditionalSummaryContainer
        viewMode={reportMode}
        engagement={instance}
        isEditAllowed={!hasPermission([USER], adminType)}
        isOneLink
        sendId={itemId}
      />
    );
  }, [reportMode, instance, adminType, itemId]);

  const noteArea = React.useMemo(() => {
    if (!(instance && showNote)) {
      return null;
    }
    return (
      <Textarea
        helperText="Custom message text"
        className={styles.textareaContainer}
        inputClassName={styles.noteTextarea}
        value={instance[MESSAGE]}
        readOnly
      />
    );
  }, [showNote, instance]);

  const totalRecipients = React.useMemo(() => {
    return instance?.recipients_count || instance?.recipients?.length;
  }, [instance?.recipients?.length, instance?.recipients_count]);

  const handleOpen2FASidebar = React.useCallback(() => {
    twoFASidebarRef.current?.open();
  }, []);

  const advancedSettings = React.useMemo(() => {
    const isShowAdvancedSettingsDisplayed = !(instance && showAdvancedSettings);

    if (isShowAdvancedSettingsDisplayed) {
      return null;
    }

    const total2FARecipients = reportMode ? totalRecipients : twoFARecipientList?.successfulRecipients?.length;

    return (
      <div className={styles.advancedSettingContainer}>
        <InputLabel value="Advanced settings" />
        <div className={styles.advancedSettings}>
          {instance[PASSWORD] && (
            <ToggleInput name={PASSWORD} value={instance[PASSWORD]!} inputLabel="Your password" isReadOnly>
              {({ isReadOnly, toggleInputStyles }) => (
                <>
                  <Input value={instance[PASSWORD]!} icon={<LockIcon />} readOnly={isReadOnly} />
                  <CopyableButton
                    key={PASSWORD}
                    value={instance[PASSWORD]!}
                    className={cn(toggleInputStyles.copyButton)}
                  >
                    copy
                  </CopyableButton>
                </>
              )}
            </ToggleInput>
          )}
          {instance[ALLOWED_DOMAINS] && (
            <ToggleInput name={ALLOWED_DOMAINS} value={instance[ALLOWED_DOMAINS]} inputLabel="Your domains" isReadOnly>
              {({ isReadOnly }) => <TagInput value={instance[ALLOWED_DOMAINS] ?? []} isReadOnly={isReadOnly} />}
            </ToggleInput>
          )}
          {instance[ENABLED_2FA] && (
            <div className={styles.manageRecipientsWrapper}>
              <InputLabel
                value="2FA Settings"
                containerClassName={styles.inputLabel}
                hint="Recipients will be asked to verify their phone number or email address with a code."
              />
              <div className={styles.manageRecipients}>
                <ActionButton
                  className={styles.twoFATrigger}
                  title="Manage recipients"
                  onClick={handleOpen2FASidebar}
                />
                <TwoFASidebarToggle
                  ref={twoFASidebarRef}
                  count={total2FARecipients}
                  oneLinkId={itemId}
                  status={status}
                />
              </div>
            </div>
          )}
          {<OLShippingDetailsContainer values={shippingValues} isReadOnly />}
        </div>
      </div>
    );
  }, [
    shippingValues,
    itemId,
    instance,
    showAdvancedSettings,
    reportMode,
    totalRecipients,
    handleOpen2FASidebar,
    twoFARecipientList,
    status,
  ]);

  const emailInfo = React.useMemo(() => {
    if (!(instance && showEmail)) {
      return null;
    }
    return (
      <div className={cn(styles.emailInfo, styles.oneLink)}>
        <Input helperText="Subject" value={instance[EMAIL_SUBJECT]} readOnly />
      </div>
    );
  }, [showEmail, instance?.[EMAIL_SUBJECT]]);

  const emailArea = React.useMemo(() => {
    if (!(instance && showEmail)) {
      return null;
    }
    return (
      <>
        <InputLabel value="Email text" />
        <HtmlView
          containerClassname={cn(styles.textareaContainer, styles.htmlViewContainer)}
          html={instance[EMAIL_MESSAGE]!}
        />
      </>
    );
  }, [showEmail, instance?.[EMAIL_MESSAGE]]);

  const statusBar = React.useMemo(() => {
    if (!instance) {
      return null;
    }
    return (
      <div className={styles.infoBar}>
        <InputLabel value="Status" />
        <ReportsStatus className={styles.status} status={statusLabel} backgroundColor={statusColor} />
      </div>
    );
  }, [statusLabel, instance, statusColor]);

  const dateBar = React.useMemo(() => {
    return (
      <div className={styles.dateBar}>
        <div className={styles.dateItem}>
          <InputLabel value="Created Date" />
          <span className={styles.label}>{formatDate(createdAtDate, DateFormatsEnum.ddMMMyyyy) || 'n/a'}</span>
        </div>
      </div>
    );
  }, [createdAtDate]);

  const linksList = React.useMemo(() => {
    if (!instance) {
      return null;
    }

    const copyableInfo = getOneLinkCopyableInfo(instance as IOneLinkWithCampaignSummaries);

    if (!copyableInfo || !copyableInfo.length) {
      return null;
    }

    const EngagementLink: React.FC<{ title: string; url: string }> = ({ title, url, ...restProp }) => {
      return (
        <div className={styles.link} {...restProp}>
          {title && <span className={styles.linkCopyTitle}>{title}</span>}
          <ReportsCopyableButton className={cn(styles.linkCopyButton)} value={url} />
        </div>
      );
    };

    return (
      <div className={styles.infoBar}>
        <InputLabel value="One Link" />
        <div className={styles.linksContainer}>
          {copyableInfo.reverse().map(({ data, type: reportLinkType }) => {
            if (!reportMode && reportLinkType === CopyableLinkTypesEnum.DigitalGift) {
              return;
            }

            return Array.isArray(data) ? (
              data.map(({ label, url }) => <EngagementLink key={url} url={url} title={label} />)
            ) : (
              <EngagementLink key={data.url} url={data.url} title={data.label} />
            );
          })}
        </div>
      </div>
    );
  }, [instance, reportMode]);

  const olTitle = React.useMemo(() => {
    if (!instance || !instance[TITLE]) {
      return null;
    }

    return (
      <div className={styles.container}>
        <InputLabel value="Title" />
        <span className={styles.expDate}>{instance?.[TITLE]}</span>
      </div>
    );
  }, [instance?.[TITLE]]);

  const expirationDate = React.useMemo(() => {
    if (!instance || !instance[EXPIRATION_DATE] || !isValidDate(new Date(instance[EXPIRATION_DATE] as string))) {
      return null;
    }

    return (
      <div className={styles.section}>
        <InputLabel value="Expiration date" />
        <span className={styles.expDate}>
          {format(getUTCDate(instance[EXPIRATION_DATE] as string), DateFormatsEnum.FullHumanReadable)}
        </span>
      </div>
    );
  }, [instance?.[EXPIRATION_DATE]]);

  const redemptionLimit = React.useMemo(() => {
    if (!instance || !instance[REDEMPTION_LIMIT]) {
      return null;
    }

    return (
      <div className={styles.section}>
        <InputLabel value="Redemption limit" />
        <span className={styles.expDate}>{instance[REDEMPTION_LIMIT]}</span>
      </div>
    );
  }, [instance?.[REDEMPTION_LIMIT]]);

  const fixedAddress = React.useMemo(() => {
    if (!instance || !instance[RECEIVER_FIXED_ADDRESS]) {
      return null;
    }

    return <FixedAddress className={styles.fixedAddress} instance={instance} />;
  }, [instance?.[RECEIVER_FIXED_ADDRESS]]);

  const handleChangeReminderInterval = React.useCallback(
    (interval: string | null) => {
      dispatch(addBucketValue({ [RECEIVER_REMINDER_INTERVAL]: interval }));
    },
    [dispatch],
  );

  return (
    <div className={cn(styles.summary, className)}>
      <div className={styles.mainContainer}>
        {(olTitle || expirationDate || SenderInfo || redemptionLimit || fixedAddress) && (
          <div className={styles.topContainer}>
            {olTitle}
            {expirationDate}
            {redemptionLimit}
            {reportMode && fixedAddress}
            {SenderInfo}
          </div>
        )}
        {emailInfo && <div className={styles.mainInfo}>{emailInfo}</div>}
        {emailArea}
        {noteArea}
        {advancedSettings}
        {(() => {
          const { created_sends } = (instance || {}) as IOneLinkWithCampaignSummaries;
          return created_sends && created_sends.length;
        })() ? (
          <ConfirmedSendsList items={(instance as IOneLinkWithCampaignSummaries)?.created_sends} />
        ) : null}
      </div>
      {instance && (
        <div className={styles.sideContainer}>
          {isReceiverReminderSettingEnabled && (
            <IntervalInput
              className={styles.intervalInputContainer}
              value={receiverReminderInterval}
              onChange={handleChangeReminderInterval}
            />
          )}
          {additionalForm}
          {reportMode && (
            <React.Fragment>
              {statusBar}
              {dateBar}
              {linksList}
            </React.Fragment>
          )}
          <InventorySidebar
            label={
              reportMode ? (
                <div className={styles.pygInfo}>
                  <InputLabel className={styles.pygLabel} value="Send Pack" />
                  {isPYG && <PYGIcon className={styles.pygIcon} />}
                </div>
              ) : (
                <InputLabel value="Your Send" />
              )
            }
            selected={instance[PRE_CREATED_ENGAGEMENT_ID]}
            className={styles.inventorySidebar}
            items={instance.pick_campaigns || instance.items}
            type={instance.pick_campaigns ? InventorySidebarTypeEnum.Campaign : InventorySidebarTypeEnum.InventoryItem}
            showQuantity={showQuantity}
            showCount={!reportMode && !showQuantity}
          />
          <div className={styles.infoBar}>
            <InputLabel
              value={
                !reportMode && isPYG ? (
                  <React.Fragment>
                    <span>Send cost</span>
                    <HelpTooltip
                      id="instance-cost-tooltip"
                      className={styles.tooltip}
                      contentClassName={styles.tooltipText}
                    >
                      You will be charged for the biggest price and when the user picks his gift the money will be
                      returned.
                    </HelpTooltip>
                  </React.Fragment>
                ) : (
                  'Item(s) Cost'
                )
              }
            />
            <Price className={styles.price} value={total} />
          </div>
          {!reportMode && (
            <ActionButton
              className={styles.actionButton}
              onClick={onSubmit}
              title="Create One Link"
              disabled={isDisabled}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default OneLinkSummaryComponent;
