import cn from 'classnames';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { SENDER_EMAIL, SENDER_FIRST_NAME, SENDER_LAST_NAME } from '../../constants/shipping';
import { DEPARTMENT_ID } from '../../constants/users';
import { selectCurrentOrganizationDepartmentList } from '../../store/selectors/organizations';
import { IOneLink } from '../../types/oneLink';
import { getDepartmentLabelById } from '../../utils/users';
import { Card } from '../index';
import { ConfirmedSendsCount, CreatedByLabel, DetailsCard, PersonCard, SendIcons, SendTitle } from './index';

import styles from './EngagementCard.module.scss';

interface IProps {
  className?: string;
  engagement: IOneLink;
  controls?: React.ReactNode | React.ReactNode[];
  wide?: boolean;
}

const OneLinkCard: React.FC<IProps> = ({ className, engagement, wide = false, controls }: IProps) => {
  const departments = useSelector(selectCurrentOrganizationDepartmentList);

  const getDepartmentLabel = React.useCallback((id: string) => getDepartmentLabelById(id, departments), [departments]);

  return (
    <article className={cn(styles.container, styles.oneLink, className, { [styles.expanded]: wide })}>
      <ConfirmedSendsCount className={styles.confirmedSends} instance={engagement} />
      <Card
        className={cn(styles.card, styles.oneLink)}
        middleClassName={styles.people}
        bottomClassName={styles.controlsWrapper}
        top={
          wide && (
            <div>
              <SendTitle className={styles.title} title={engagement.title} />
              <CreatedByLabel className={styles.createdBy} instance={engagement} />
            </div>
          )
        }
        middle={
          <PersonCard
            className={styles.sender}
            label="Sender"
            top={`${engagement[SENDER_FIRST_NAME]} ${engagement[SENDER_LAST_NAME]}`}
            middle={engagement[SENDER_EMAIL]}
            bottom={getDepartmentLabel(engagement[DEPARTMENT_ID])}
          />
        }
        bottom={
          !wide ? (
            <React.Fragment>
              <SendIcons instance={engagement} />
              {controls ? <div className={styles.controls}>{controls}</div> : null}
            </React.Fragment>
          ) : null
        }
      />
      {wide && (
        <DetailsCard className={cn(styles.card, styles.details)} instance={engagement} controls={controls} isOneLink />
      )}
    </article>
  );
};

export default OneLinkCard;
