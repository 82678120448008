import { Box, Button } from '@mui/material';
import * as React from 'react';
import variables from '../../../../contexts/ThemeProvider/variables';

interface IProps {
  onCancel: () => void;
  onSubmit?: () => void;
  isFormChanged?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
}

export const FormControls: React.FC<IProps> = ({ onCancel, onSubmit, isFormChanged, isDisabled, isLoading }) => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'end' }}>
      {isFormChanged && (
        <Button variant="text" size="medium" onClick={onCancel} sx={{ width: 150, ml: 2 }} disabled={isLoading}>
          Cancel
        </Button>
      )}
      <Button
        type="submit"
        loading={isLoading}
        variant="contained"
        size="medium"
        disabled={isDisabled}
        sx={{ width: 150, ml: 2, color: variables.white }}
        onClick={onSubmit ? onSubmit : undefined}
      >
        Save
      </Button>
    </Box>
  );
};
