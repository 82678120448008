import { useTheme } from '@mui/material';
import cn from 'classnames';
import * as React from 'react';

import useWindowSize from '../../../hooks/useWindowSize';
import { DrawerAnimationDirectionEnum } from '../../../types/shell';
import Drawer from '../../Drawer/Drawer';
import Nav from '../Nav/Nav';
import NavHeader from '../NavHeader/NavHeader';
import NavToggle from '../NavToggle/NavToggle';
import styles from './DesktopSidenav.module.scss';

interface IProps {
  expanded: boolean;
  toggle: () => void;
  mainMenu: React.ReactNode | React.ReactNode[];
}

const DesktopSidenav = ({ expanded, toggle, mainMenu }: IProps) => {
  const { sass: variables } = useTheme();
  const { HD, laptop, fullHD } = useWindowSize();

  return (
    <Drawer
      direction={DrawerAnimationDirectionEnum.Horizontally}
      from={HD ? variables.navDesktopMinWidthHD : variables.navDesktopMinWidth}
      to={variables.navDesktopMaxWidth}
      className={cn(styles.desktopSideNav, { [styles.expanded]: expanded })}
      trigger={expanded}
      onClose={toggle}
      withOverlay={HD || laptop || fullHD}
      overlayClassName={styles.overlay}
    >
      <NavHeader expanded={expanded} />
      <div className={cn(styles.body)}>
        <Nav>{mainMenu}</Nav>
      </div>
      <NavToggle expanded={expanded} onClick={toggle} />
    </Drawer>
  );
};

export default DesktopSidenav;
