import cn from 'classnames';
import * as React from 'react';

import useClickOutside from '../../hooks/useClickOutside';
import { BUTTON_BUTTON } from '../../types/forms';
import { IDropdownWithContentOptions } from '../../types/shell';

import styles from './DropdownWithContent.module.scss';

interface IProps {
  className?: string;
  contentClassName?: string;
  labelClassName?: string;
  children: (options: IDropdownWithContentOptions) => React.ReactNode | React.ReactNode[];
  label?: (options: IDropdownWithContentOptions) => string | React.ReactNode | React.ReactNode[];
  isOpen?: boolean;
  canOpen?: boolean;
  onClose?: () => void;
  onOpen?: () => void;
  defaultView?: boolean;
}

export type TDropdownProps = Partial<IProps>;

const DropdownWithContent = ({
  className,
  contentClassName,
  labelClassName,
  children,
  label,
  isOpen,
  canOpen = true,
  onClose,
  onOpen,
  defaultView,
}: IProps) => {
  const [isDropdownOpened, setIsDropdownOpened] = React.useState(false);

  const dropdownRef = React.useRef(null);

  const openDropdown = React.useCallback(() => {
    setIsDropdownOpened(true);

    if (typeof onOpen === 'function') {
      onOpen();
    }
  }, [onOpen]);

  const closeDropdown = React.useCallback(() => {
    setIsDropdownOpened(false);

    if (typeof onClose === 'function') {
      onClose();
    }
  }, [onClose]);

  const toggleDropdown = React.useCallback(
    (state: boolean) => {
      state ? openDropdown() : closeDropdown();
    },
    [openDropdown, closeDropdown],
  );

  const handleDropdownClick = React.useCallback((event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
  }, []);

  useClickOutside(dropdownRef, closeDropdown);

  React.useEffect(() => {
    if (!canOpen) {
      return;
    }

    if (typeof isOpen === 'undefined' || isOpen === null) {
      return;
    }

    toggleDropdown(isOpen);
  }, [canOpen, isOpen, toggleDropdown]);

  const childOptions = React.useMemo(
    () => ({ close: () => toggleDropdown(false), isOpened: isDropdownOpened }),
    [toggleDropdown, isDropdownOpened],
  );

  return (
    <div
      className={cn(styles.dropdown, className)}
      role={BUTTON_BUTTON}
      {...(canOpen ? { onClick: () => toggleDropdown(!isDropdownOpened) } : null)}
      ref={dropdownRef}
    >
      <span className={cn(labelClassName, styles.dropdownLabel, { [styles.defaultView]: defaultView })}>
        {label?.(childOptions)}
      </span>
      <div
        className={cn(contentClassName, styles.dropdownContent, { [styles.closed]: !isDropdownOpened })}
        onClick={handleDropdownClick}
      >
        {children(childOptions)}
      </div>
    </div>
  );
};

export default DropdownWithContent;
