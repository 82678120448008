import { differenceInDays, format, isValid, parseISO } from 'date-fns';
import isAfter from 'date-fns/isAfter';
import isEqual from 'date-fns/isEqual';
import pick from 'lodash/pick';
import { v4 as uuidv4 } from 'uuid';

import { CUSTOM_SEND_PRE_CREATED_ENGAGEMENT_ID } from '../constants/bucket';
import { DateFormatsEnum } from '../constants/date';
import {
  AWAITING_SHIPMENT,
  CANCELED_MANUALLY,
  CREATED_AT,
  DATE_TO_SEND,
  DIGITAL_STATUS,
  NEW,
  PHYSICAL_STATUS,
  PROCESSING,
  SHIPPED,
  VIEWED,
} from '../constants/reports';
import {
  AWAITING_ADDRESS,
  DELAYED_SHIPPING_URL,
  RECEIVER_COMPANY_NAME,
  RECEIVER_FIRST_NAME,
  RECEIVER_LAST_NAME,
  RECEIVER_PHONE,
  TRACKING_URL,
} from '../constants/shipping';
import { MESSAGE } from '../constants/templates';
import { ISendItemsInfo, TEngagementCandidate } from '../types/bucket';
import { ICampaignSummaryItem } from '../types/campaigns';
import { IOneLink, IOneLinkWithCampaignSummaries } from '../types/oneLink';
import {
  EngagementSourceTypesEnum,
  IReport,
  IReportEngagementItem,
  IReportWithCampaignSummaries,
  ReportStatusEnum,
} from '../types/reports';
import { CopyableLinkTypesEnum } from '../types/shell';
import { IBulkPayloadProps, IReceiverInfoDetailsFormValues } from '../types/shipping';
import { isDigitalBox } from './campaigns';
import { formatDate, getUTCDate, isValidDate } from './date';
import { isItemDigital, isItemFromPYC } from './inventories';

const getAdditionalReportStatusAndDate = (report: IReport) => {
  let date = '';
  let dateLabel = '';
  let status = {
    label: '',
    color: '',
  };

  if (report.status === ReportStatusEnum.Canceled) {
    date = report.canceled_at ? formatDate(report.canceled_at) : '';
    dateLabel = 'Canceled Date';
    status = (() => {
      const label = PHYSICAL_STATUS[CANCELED_MANUALLY].label;
      return {
        ...PHYSICAL_STATUS[CANCELED_MANUALLY],
        label: `${label} ${report.canceled_by ? `by ${report.canceled_by}` : ''}`,
      };
    })();
  }

  if (isValidScheduledEngagement(report)) {
    date = formatDate(report.date_to_send);
    dateLabel = 'Scheduled Date';
  }

  return { ...status, date, dateLabel };
};

const getDigitalReportStatusAndDate = (report: IReport) => {
  const item = report.items?.[0];
  const date = item?.digital_claim_date ? formatDate(item.digital_claim_date) : '';
  const status =
    item && DIGITAL_STATUS[item.digital_claim_status] ? DIGITAL_STATUS[item.digital_claim_status] : PROCESSING;
  const dateLabel = 'Claimed Date';

  return { ...status, date, dateLabel };
};

const getPhysicsReportStatusAndDate = (report: IReport) => {
  let date;
  let status;
  let dateLabel;

  if (isAwaitingAddress(report) && isValidDate(new Date(report.delayed_shipping_page_view_date))) {
    date = formatDate(report.delayed_shipping_page_view_date);
    status = PHYSICAL_STATUS[VIEWED];
    dateLabel = 'Viewed Date';
  } else {
    date = report.package_delivered_date ? formatDate(report.package_delivered_date) : '';
    dateLabel = 'Delivered Date';
    status = PHYSICAL_STATUS[report.ship_order_status] ? PHYSICAL_STATUS[report.ship_order_status] : PROCESSING;
  }

  return { ...status, date, dateLabel };
};

export const isDigitalReport = (report: IReport | IOneLink) =>
  !!report.items?.length && report.items.every(isItemDigital);

export const isDigitalPYGReport = (report: IReportWithCampaignSummaries | IOneLinkWithCampaignSummaries) =>
  !!report.pick_campaigns?.length && report.pick_campaigns.every(isDigitalBox);

export const isCustomReport = (report: IReport | IOneLink) => {
  return report?.pre_created_engagement_id === CUSTOM_SEND_PRE_CREATED_ENGAGEMENT_ID;
};

export const isReportViewed = ({ ship_order_status }: Pick<IReport, 'ship_order_status'>) =>
  ship_order_status === VIEWED;

export const isAwaitingAddress = (report: Partial<Pick<IReport, 'ship_order_status'>>) => {
  const { ship_order_status } = report || {};
  return ship_order_status === AWAITING_ADDRESS;
};

export const isAwaitingShipment = (report: Partial<Pick<IReport, 'ship_order_status'>>) => {
  const { ship_order_status } = report || {};
  return ship_order_status === AWAITING_SHIPMENT;
};

export const isShipped = ({ ship_order_status }: Pick<IReport, 'ship_order_status'>) => ship_order_status === SHIPPED;

export const isNew = ({ ship_order_status }: Pick<IReport, 'ship_order_status'>) => ship_order_status === NEW;

export const getShipmentInfoIsNotConfirmed = ({ ship_order_status }: Pick<IReport, 'ship_order_status'>) => {
  return (
    isNew({ ship_order_status }) || isReportViewed({ ship_order_status }) || isAwaitingAddress({ ship_order_status })
  );
};

export const getReportStatusAndDate = (report: IReport) => {
  const isDigital = isDigitalReport(report);
  const createdAtDate = formatDate(report.created_at);
  const nextReminderDate = formatDate(report.receiver_next_reminder_send_date);
  const onHoldUntilDate = formatDate(report.on_hold_until);

  const delayedShippingExpirationDate = format(
    getUTCDate(report.delayed_shipping_expiration),
    DateFormatsEnum.FullHumanReadable,
  );

  if (report.status || isValidScheduledEngagement(report)) {
    return {
      ...getAdditionalReportStatusAndDate(report),
      createdAtDate,
      nextReminderDate,
      onHoldUntilDate,
      delayedShippingExpirationDate,
    };
  }

  return {
    ...(isDigital ? getDigitalReportStatusAndDate(report) : getPhysicsReportStatusAndDate(report)),
    createdAtDate,
    onHoldUntilDate,
    nextReminderDate,
    delayedShippingExpirationDate,
  };
};

// export const getReportTotal = (report: IReport | null | undefined): number => {
//   if (!report || !report.items || !report.items.length) {
//     return 0;
//   }
//   return report.items.reduce((acc: number, { price, quantity = 1 }: IReportEngagementItem): number => {
//     return acc + price * quantity;
//   }, 0);
// };

export const sortReportsByCreatedTime = (isScheduled: boolean) => {
  const prop = isScheduled ? DATE_TO_SEND : CREATED_AT;

  return (current: IReport, next: IReport) => {
    // If the dates are equal we need to check the microseconds
    // When doing a bulk - sends will have the difference in microseconds and not milliseconds
    // Javascript just omits everything after the milliseconds
    if (isEqual(new Date(next[prop]), new Date(current[prop]))) {
      // This RegExp looks for the numbers between the period symbol (dot) and `Z`
      // const microsecondsRegex = /(?<=\.)[0-9]+(?!=Z)/m;
      const microsecondsRegex = /\.[.0-9]+(?!=Z)/m;

      const nextMs = parseFloat(microsecondsRegex.exec(next[prop])?.[0] || '');
      const currentMs = parseFloat(microsecondsRegex.exec(current[prop])?.[0] || '');

      // If the dates are not equal we should sort them descending
      if (nextMs !== currentMs) {
        return nextMs - currentMs;
      }

      return 0;
    }

    // If the dates are not equal we should sort them descending
    return isAfter(new Date(next[prop]), new Date(current[prop])) ? 1 : -1;
  };
};

export const populateReportItemsWithId = (report: IReport) => ({
  ...report,
  items: report.items?.map((item) => ({ ...item, id: uuidv4() })),
});

export const isOneLinkEngagement = ({ engagement_source_type }: Pick<IReport, 'engagement_source_type'>) =>
  engagement_source_type ? engagement_source_type === EngagementSourceTypesEnum.OneLink : false;

export const isProtectedEngagement = ({ password, allowed_domains }: Pick<IOneLink, 'password' | 'allowed_domains'>) =>
  Boolean(password || allowed_domains);

export const is2FAProtectedEngagement = ({ enabled_2fa }: Pick<IOneLink, 'enabled_2fa'>) => Boolean(enabled_2fa);

export const isZapierEngagement = ({ engagement_source_type }: IReport) =>
  engagement_source_type ? engagement_source_type === EngagementSourceTypesEnum.Zapier : false;

export const isValidScheduledEngagement = (engagement: IReport) => {
  const dateToSend = engagement && engagement.date_to_send ? new Date(engagement.date_to_send) : false;
  return dateToSend && isValidDate(dateToSend);
};

export const isPYGReport = ({ pick_campaign_ids }: { pick_campaign_ids: string[] | null }) =>
  Boolean(pick_campaign_ids?.length);

export const getReportCopyableInfo = (report: IReport) => {
  if (isValidScheduledEngagement(report)) {
    return [];
  }

  const result = [];

  const digitals = report.items?.filter(isItemDigital);
  if (digitals && digitals.length) {
    result.push({
      type: CopyableLinkTypesEnum.DigitalGift,
      data: digitals.map(({ name, digital_gift_url }) => ({
        label: name,
        url: digital_gift_url,
      })),
    });
  }

  if (report[DELAYED_SHIPPING_URL]) {
    result.push({
      type: CopyableLinkTypesEnum.Shipping,
      data: {
        label: 'Delayed shipping',
        url: report[DELAYED_SHIPPING_URL],
      },
    });
  }

  if (report[TRACKING_URL]) {
    result.push({
      type: CopyableLinkTypesEnum.Tracking,
      data: {
        label: 'Tracking URL',
        url: report[TRACKING_URL],
      },
    });
  }

  return result;
};

export const hasItemsFromPYC = (items: ICampaignSummaryItem[] | IReportEngagementItem[] | undefined | null) => {
  return items?.some(({ fulfillment_center_id }) => isItemFromPYC({ fulfillment_center_id }));
};

export const getEditableSendFields = (
  report: IReport | null | undefined,
): IReceiverInfoDetailsFormValues | undefined => {
  if (!report) {
    return;
  }

  return pick(report, [RECEIVER_FIRST_NAME, RECEIVER_LAST_NAME, RECEIVER_COMPANY_NAME, RECEIVER_PHONE, MESSAGE]);
};

export const mapSendItemsForEditSendRequest = (updates?: ISendItemsInfo['items_info']) => {
  if (!updates) {
    return;
  }

  return {
    items_info: updates.map(({ sku_options, ...item }) => {
      if (sku_options && sku_options.length) {
        return { ...item, sku_option_ids: sku_options.map(({ item_id }) => item_id) };
      }
      return item;
    }),
  };
};

export const calculateDaysLeft = (endDate: string) => {
  const targetDate = parseISO(endDate);
  const isValidTargetDate = isValid(targetDate);

  if (!isValidTargetDate) {
    return;
  }

  const currentDate = getUTCDate(new Date().toISOString());

  const daysLeft = differenceInDays(targetDate, currentDate);

  switch (true) {
    case daysLeft > 1:
      return `${daysLeft} days left`;
    case daysLeft === 1:
      return '1 day left';
    case daysLeft === 0 && isAfter(targetDate, currentDate):
      return '1 day left';
    default:
      return 'expired';
  }
};

export const transformReportAddressToCommonAddress = (values: TEngagementCandidate | IReportWithCampaignSummaries) => {
  const { receiver_address1, receiver_address2, receiver_city, receiver_country, receiver_state, receiver_zip } =
    values;

  return {
    address1: receiver_address1,
    address2: receiver_address2,
    city: receiver_city,
    country: receiver_country,
    state: receiver_state,
    zip: receiver_zip,
  };
};

export const prepareShippingRequestData = (data: IBulkPayloadProps) => {
  const { receiver_address, ...rest } = data;

  if (receiver_address) {
    const { address1, address2, city, country, state, zip } = receiver_address;

    return {
      ...rest,
      receiver_address1: address1,
      receiver_address2: address2 || '',
      receiver_city: city,
      receiver_country: country,
      receiver_state: state || '',
      receiver_zip: zip,
    };
  }
  return data;
};
