import { Box, TextField, Typography } from '@mui/material';
import { useMemo } from 'react';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { DateFormatsEnum } from '../../../constants/date';
import { BUSINESS_REASON_ID } from '../../../constants/reasons';
import { selectBusinessReasons } from '../../../store/selectors/reasons';
import { IOneLinkWithCampaignSummaries } from '../../../types/oneLink';
import { formatDate, isValidDate } from '../../../utils/date';
import { Label, Section } from './common';

interface IProps {
  onEdit?: () => void;
  instance: IOneLinkWithCampaignSummaries | null | undefined;
}

export const AdditionalSection: React.FC<IProps> = ({ onEdit, instance }) => {
  const reasons = useSelector(selectBusinessReasons);

  const businessReason = React.useMemo(() => {
    return reasons?.find((reason) => reason.uid === instance?.[BUSINESS_REASON_ID]);
  }, [reasons, instance?.[BUSINESS_REASON_ID]]);

  const onHoldUntil = useMemo(() => {
    if (instance?.on_hold_until && isValidDate(new Date(instance?.on_hold_until))) {
      return formatDate(instance?.on_hold_until, DateFormatsEnum.ddMMMyyyy);
    }
    return 'Date not set';
  }, [instance?.on_hold_until]);

  return (
    <Section title="Additional" onEdit={onEdit} minHeight={420}>
      <Box sx={{ height: 300 }}>
        <Box sx={{ display: 'flex', gap: 6, mb: 2 }}>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Label>On Hold Until</Label>
            <Typography>{onHoldUntil}</Typography>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Label>Sender Notifications</Label>
            <Typography>{instance?.disable_sender_email_notifications ? 'Disabled' : 'Enabled'}</Typography>
          </Box>
        </Box>
        <TextField
          label="Business Reason"
          variant="outlined"
          value={businessReason?.name ?? ''}
          aria-readonly={true}
          sx={{ mb: 2 }}
          fullWidth
          placeholder="Select Business Reason"
          disabled
        />
        <TextField
          placeholder="Put down your internal note"
          multiline
          minRows={5}
          maxRows={5}
          label="Internal Notes"
          variant="outlined"
          value={instance?.internal_notes ?? ''}
          fullWidth
          disabled
        />
      </Box>
    </Section>
  );
};
