import { useTheme } from '@mui/material';
import cn from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';
import { useCSVReader } from 'react-papaparse';
import { useDispatch } from 'react-redux';
import { v4 as uuid } from 'uuid';

import { ActionButton, Drawer, EditableTable, InfoLink, InputLabel, Loader, PaginatedList } from '../../components';
import useFetch from '../../hooks/useFetch';
import useSearchFilter from '../../hooks/useSearchFilter/useSearchFilter';
import useWindowSize from '../../hooks/useWindowSize';
import { upload2FARecipients } from '../../store/actions/bucket';
import { DrawerAnimationDirectionEnum, TwoFASidebarModesEnum } from '../../types/shell';
import { phoneTransform as transform, validateRecipients } from '../../utils/oneLink';
import TwoFAStatusMessage from './TwoFAStatusMessage';

import { CSV_ONE_LINK_RECIPIENTS_EXAMPLE_URL } from '../../config/app';
import { EMAIL_ADDRESS, FIRST_NAME, LAST_NAME, MAX_RECIPIENTS_PER_PAGE, PHONE_NUMBER } from '../../constants/oneLink';
import { endpoints, queryParams } from '../../constants/routing';
import { fetchOneLinkByIdSuccess, uploadOneLinkRecipients } from '../../store/actions/oneLink';
import { BUTTON_BUTTON, EditableTableModes } from '../../types/forms';
import {
  IAddRecipientsPayload,
  IFetchOneLinkByIdResponsePayload,
  IOneLinkRecipient,
  IOneLinkRecipients,
  OneLinkStatusEnum,
  TOneLinkRecipient,
} from '../../types/oneLink';
import { ITableConfig } from '../../types/tables';

import styles from './TwoFASidebar.module.scss';

interface IProps {
  onClose: () => void;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onAdd: () => void;
  onDeleteAll: () => void;
  onSave?: (recipient: TOneLinkRecipient) => Promise<void>;
  onDelete?: (recipient: TOneLinkRecipient) => void;
  trigger: boolean;
  children?: React.ReactNode;
  uploadedRecipients?: IOneLinkRecipient[] | null;
  tableConfig?: ITableConfig<IOneLinkRecipient>;
  tableMode?: EditableTableModes;
  activeAllowedRecipient?: TOneLinkRecipient;
  setActiveAllowedRecipient: (recipient: TOneLinkRecipient | null) => void;
  isChanged?: boolean;
  mode: TwoFASidebarModesEnum;
  isLoading?: boolean;
  oneLinkId?: string;
  status?: OneLinkStatusEnum;
}

const TwoFASidebar: React.FC<IProps> = ({
  onClose,
  onChange,
  onAdd,
  onDeleteAll,
  onSave,
  onDelete,
  trigger,
  children,
  uploadedRecipients,
  tableConfig,
  tableMode,
  activeAllowedRecipient,
  setActiveAllowedRecipient,
  isChanged,
  mode,
  oneLinkId,
  isLoading: isParentLoading,
  status,
}) => {
  const { sass: variables } = useTheme();
  const dispatch = useDispatch();
  const paginatedListRef = React.useRef<any>(null);

  const [csvUploadResult, setCsvUploadResult] = useState<IOneLinkRecipients | null>(null);
  const [csvUploadStatus, setCsvUploadStatus] = useState<'failed' | 'success' | null>(null);
  const [currentPage, setCurrentPage] = useState(paginatedListRef.current?.page || 1);

  const { CSVReader } = useCSVReader();
  const { mobile, HD } = useWindowSize();

  const { SearchInput, filteredItems: filteredBySearch } = useSearchFilter(
    uploadedRecipients || [],
    [FIRST_NAME, LAST_NAME, EMAIL_ADDRESS, PHONE_NUMBER],
    {
      className: styles.search,
      placeholder: 'Search recipients',
      disabled: !uploadedRecipients?.length,
    },
  );

  const { make: fetchOneLink } = useFetch<never, IFetchOneLinkByIdResponsePayload>({
    endpoint: endpoints.getOneLinkSendById,
  });

  const { isLoading: isCreating, make: createOneLinkRecipients } = useFetch<TOneLinkRecipient[], IAddRecipientsPayload>(
    {
      endpoint: endpoints.createOneLinkRecipients,
      method: 'POST',
    },
  );

  useEffect(() => {
    if (filteredBySearch.length <= 10) {
      setCurrentPage(1);
      paginatedListRef.current?.resetPageCount();
    }
  }, [filteredBySearch]);

  useEffect(() => {
    paginatedListRef.current?.resetPageCount();
    setCurrentPage(1);
  }, [filteredBySearch.length]);

  const handleCloseUploadStatus = React.useCallback(() => {
    setCsvUploadStatus(null);
  }, []);

  const handleClose = React.useCallback(() => {
    onClose();
    handleCloseUploadStatus();
  }, [onClose, handleCloseUploadStatus]);

  const renderHeader = useMemo(() => {
    return (
      <div className={styles.header}>
        <button type={BUTTON_BUTTON} className={styles.closeBtn} onClick={handleClose} />
        <div className={styles.mainInfo}>
          <h1 className={styles.title}>Manage 2FA recipients</h1>
          <h3 className={styles.description}>Add recipients by uploading a CSV or individually to the table.</h3>
          <div className={styles.upload}>
            <InputLabel
              containerClassName={styles.label}
              value="Upload a CSV File"
              hint="Upload a csv with 4 columns: first name, last name, email and phone number. Either phone number or email address is required."
            />
            <InfoLink
              url={CSV_ONE_LINK_RECIPIENTS_EXAMPLE_URL}
              className={styles.exampleLink}
              toggleClassName={styles.toggle}
              label="Download example CSV"
            />
          </div>
          <div className={styles.uploadFile}>
            <CSVReader
              config={{
                // FIXME: Enable this in case we meed to optimize the parsing
                //   - related tp https://github.com/mholt/PapaParse/pull/953
                // worker: true,
                header: true,
                delimiter: ',',
                skipEmptyLines: true,
                transform,
              }}
              onUploadAccepted={(results: { data: TOneLinkRecipient[] }) => {
                const resultWithId = results.data.map((recipient: TOneLinkRecipient) => {
                  recipient.id = uuid();
                  return recipient;
                });
                const result = validateRecipients(resultWithId, uploadedRecipients);

                if (mode === TwoFASidebarModesEnum.Create) {
                  setCsvUploadResult(result);
                  dispatch(upload2FARecipients(result));

                  if (result?.failedRecipients?.length > 0) {
                    setCsvUploadStatus('failed');
                  } else {
                    setCsvUploadStatus('success');
                  }
                }

                if (TwoFASidebarModesEnum.Edit && oneLinkId) {
                  if (result.successfulRecipients.length > 0) {
                    createOneLinkRecipients({
                      query: {
                        [queryParams.oneLinkId]: oneLinkId,
                      },
                      body: result.successfulRecipients,
                    })
                      .then((res) => {
                        let invalidRecipients = [...(result.failedRecipients as IOneLinkRecipient[])];

                        if (Array.isArray(res.invalid_recipients)) {
                          invalidRecipients = [...invalidRecipients, ...res.invalid_recipients];
                        }

                        result.failedRecipients = invalidRecipients;
                        result.successfulRecipients = res.valid_recipients;
                        setCsvUploadResult(result);

                        if (invalidRecipients.length) {
                          setCsvUploadStatus('failed');
                        } else {
                          setCsvUploadStatus('success');
                        }

                        if (res.valid_recipients.length > 0) {
                          dispatch(uploadOneLinkRecipients(res.valid_recipients as IOneLinkRecipients));
                        }
                      })
                      .then(() =>
                        fetchOneLink({
                          query: { [queryParams.uid]: oneLinkId },
                        }).then((response) => {
                          if (status) {
                            dispatch(fetchOneLinkByIdSuccess({ status, ...response }));
                          }
                        }),
                      );
                  } else {
                    setCsvUploadResult(result);
                    setCsvUploadStatus('failed');
                  }
                }
              }}
            >
              {({ getRootProps }: any) => (
                <ActionButton {...getRootProps()} className={styles.chooseBtn}>
                  Choose file
                </ActionButton>
              )}
            </CSVReader>
            <TwoFAStatusMessage
              recipients={csvUploadResult}
              status={csvUploadStatus}
              onClose={handleCloseUploadStatus}
            />
          </div>
          {SearchInput}
        </div>
      </div>
    );
  }, [
    handleClose,
    CSVReader,
    csvUploadResult,
    csvUploadStatus,
    handleCloseUploadStatus,
    SearchInput,
    uploadedRecipients,
    mode,
    oneLinkId,
    dispatch,
    status,
  ]);

  const renderAllowedRecipients = useMemo(() => {
    // Get list of items based on the current page
    const indexOfLastItem = currentPage * MAX_RECIPIENTS_PER_PAGE;
    const indexOfFirstItem = indexOfLastItem - MAX_RECIPIENTS_PER_PAGE;
    const currentItems = filteredBySearch.slice(indexOfFirstItem, indexOfLastItem);

    return (
      <PaginatedList
        totalCount={filteredBySearch.length}
        ref={paginatedListRef}
        items={currentItems || null}
        pageSize={MAX_RECIPIENTS_PER_PAGE}
        onChange={({ page, setPage }) => {
          setCurrentPage(page);
          // In case there's an error during the page change, paginator will change the page number, which is not correct.
          // The fix is to wrap the action with the Promise and call the `setPage(page)` after the Promise is resolved
          setPage(page);
        }}
      >
        {({ items, pagination: Pagination }) => {
          return (
            <>
              {isCreating && <Loader isLoading />}
              <EditableTable
                className={styles.allowedRecipients}
                listClassName={styles.list}
                headerClassName={styles.tableTitle}
                rowClassName={styles.row}
                mode={tableMode}
                onChange={onChange}
                activeItem={activeAllowedRecipient!}
                items={items || []}
                onAdd={onAdd}
                onDelete={onDelete}
                isChanged={isChanged}
                allowEmptyInputs
                showControlAsIcons={trigger}
                onSave={onSave!}
                isLoading={isParentLoading}
                onEdit={setActiveAllowedRecipient}
                onCancel={() => setActiveAllowedRecipient(null)}
                checkIsSubmitted={(recipient: TOneLinkRecipient) => Boolean(recipient?.is_submitted)}
                checkEditMode={(recipient: TOneLinkRecipient) => {
                  return recipient?.uid
                    ? recipient?.uid === activeAllowedRecipient?.uid
                    : recipient?.id === activeAllowedRecipient?.id;
                }}
                placeholderLabel="There are no recipients in your list."
                customSavedMessage="Saved"
                config={tableConfig}
              />
              <div className={styles.footer}>
                <ActionButton
                  inline
                  title="Delete All"
                  onClick={onDeleteAll}
                  className={styles.deleteAll}
                  disabled={filteredBySearch.length === 0}
                />
                <div className={styles.paginationContainer}>
                  <span>{`${filteredBySearch.length} recipients`}</span>
                  <Pagination className={styles.pagination} />
                </div>
              </div>
            </>
          );
        }}
      </PaginatedList>
    );
  }, [
    currentPage,
    filteredBySearch,
    tableMode,
    onChange,
    activeAllowedRecipient,
    onAdd,
    onDelete,
    isChanged,
    trigger,
    onSave,
    setActiveAllowedRecipient,
    tableConfig,
    onDeleteAll,
    isParentLoading,
  ]);

  const from = useMemo(() => (mobile ? variables.orgSidebarMinHeight : variables.orgSidebarMinWidth), [mobile]);
  const to = useMemo(() => {
    if (mobile) {
      return variables.orgSidebarMaxHeight;
    }

    if (HD) {
      return variables.orgSidebarMaxWidthHD;
    }

    return variables.orgSidebarMaxWidthFullHD;
  }, [mobile, HD]);

  // Renders children instead of the standard layout to be able to show some custom UI, like custom loader, etc
  return (
    <Drawer
      className={cn(styles.container)}
      direction={mobile ? DrawerAnimationDirectionEnum.Vertically : DrawerAnimationDirectionEnum.Horizontally}
      from={from}
      to={to}
      trigger={trigger}
      onClose={handleClose}
      withOverlay
      overlayClassName={styles.overlay}
    >
      {trigger &&
        (children || (
          <>
            {renderHeader}
            {renderAllowedRecipients}
          </>
        ))}
    </Drawer>
  );
};

export default TwoFASidebar;
