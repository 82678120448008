import cn from 'classnames';
import addYears from 'date-fns/addYears';
import { FormikProps } from 'formik';
import * as React from 'react';
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ReactComponent as LockIcon } from '../../../assets/images/icon-lock.svg';
import { DateFormatsEnum } from '../../../constants/date';
import { ONE_LINK_REDEMPTION_LIMIT, TITLE } from '../../../constants/oneLink';
import {
  ALLOWED_DOMAINS,
  DISABLE_SENDER_EMAIL_NOTIFICATIONS,
  ENABLED_2FA,
  EXPIRATION_DATE,
  PASSWORD,
  REDEMPTION_LIMIT,
  SHIPPING_NOTIFICATIONS_OPTIONS,
} from '../../../constants/shipping';
import { OLShippingDetailsContainer } from '../../../containers';
import { addBucketValue, updateBucketShippingDetails } from '../../../store/actions/bucket';
import { selectUserEmail } from '../../../store/selectors/auth';
import {
  select2FARecipientsList,
  selectAllowedDomains,
  selectIsPYGCampaignsDigital,
  selectOneLinkShippingDetails,
  selectSOBEmail,
} from '../../../store/selectors/bucket';
import { IOneLinkFormFields } from '../../../types/bucket';
import { BUTTON_BUTTON } from '../../../types/forms';
import { IToggleRef } from '../../../types/shell';
import { IOneLinkShippingDetails } from '../../../types/shipping';
import { getSubstringAfterChar } from '../../../utils/bucket';
import { TagInput, TwoFASidebarToggle } from '../../index';
import { CheckboxGroup, CopyableButton, DateSinglePicker, InputLabel, Toggle } from '../index';
import { Input, QuantityInput, ToggleInput } from '../inputs';
import { ICheckboxProps } from '../inputs/CheckboxGroup/CheckboxGroup';

import styles from './OneLinkShippingForm.module.scss';

interface IProps {
  className?: string;
  onChange: (name: string, value: number | string | boolean | string[] | null) => void;
  form: FormikProps<IOneLinkFormFields>;
  isDigital: boolean;
}

const OneLinkShippingForm = ({ className, form, onChange, isDigital }: IProps) => {
  // The refs below are imperative handles for the 2FA Sidebar control and Fixed Address Sidebar control
  const twoFASidebarRef = React.useRef<IToggleRef>(null);
  const dispatch = useDispatch();

  const recipients = useSelector(select2FARecipientsList);
  const userEmail = useSelector(selectUserEmail);
  const SOBEmail = useSelector(selectSOBEmail);
  const allowedDomains = useSelector(selectAllowedDomains);
  const isDigitalPYG = useSelector(selectIsPYGCampaignsDigital);
  const shippingValues = useSelector(selectOneLinkShippingDetails);

  const [is2FAEnabled, setIs2FAEnabled] = React.useState<boolean>(!!recipients?.successfulRecipients?.length);

  const senderEmail = SOBEmail || userEmail;
  const senderEmailDomain = getSubstringAfterChar(senderEmail!, '@');

  const handleShippingDetailsUpdate = React.useCallback(
    (v: IOneLinkShippingDetails) => {
      dispatch(updateBucketShippingDetails(v));
    },
    [dispatch],
  );

  const notificationOptions: ICheckboxProps[] = useMemo(
    () => [
      {
        checked: form?.values?.[DISABLE_SENDER_EMAIL_NOTIFICATIONS],
        name: DISABLE_SENDER_EMAIL_NOTIFICATIONS,
        id: DISABLE_SENDER_EMAIL_NOTIFICATIONS,
        text: SHIPPING_NOTIFICATIONS_OPTIONS.SENDER.TEXT,
        textAbout: SHIPPING_NOTIFICATIONS_OPTIONS.SENDER.TEXT_ABOUT,
      },
    ],
    [form.values],
  );

  const resetToDefaultDomain = React.useMemo(() => {
    const btn =
      senderEmailDomain && (!allowedDomains?.length || !allowedDomains?.includes(senderEmailDomain)) ? (
        <button
          className={styles.allowedDomainLabelButton}
          type={BUTTON_BUTTON}
          onClick={() => {
            const updatedDomains = allowedDomains?.includes(senderEmailDomain)
              ? allowedDomains
              : [...(allowedDomains || []), senderEmailDomain];
            onChange(ALLOWED_DOMAINS, updatedDomains);
          }}
        >
          Apply Sender's Domain
        </button>
      ) : null;

    return <>{btn}</>;
  }, [allowedDomains, senderEmailDomain, onChange]);

  const digitalSend = useMemo(() => isDigital || isDigitalPYG, [isDigital, isDigitalPYG]);

  const handleDomainsValue = useCallback(
    (isActive: boolean) => {
      const domains = form.values?.[ALLOWED_DOMAINS];

      switch (true) {
        case isActive && Array.isArray(domains) && domains.length > 0:
          return domains;
        case isActive && !Array.isArray(domains):
          return [senderEmailDomain];
        case !isActive:
          return [];
        default:
          return [senderEmailDomain];
      }
    },
    [form.values?.[ALLOWED_DOMAINS], senderEmailDomain],
  );

  React.useEffect(() => {
    if (is2FAEnabled && form.values[ALLOWED_DOMAINS]?.length) {
      onChange(ALLOWED_DOMAINS, null);
    }
  }, [is2FAEnabled, onChange, form.values[ALLOWED_DOMAINS]]);

  return (
    <div className={cn(styles.container, className)}>
      <span className={styles.info}>
        At ‘Create One Link’ flow you can’t add a recipient. All you have to do is to create a send and give your
        recipients the link with shipment information details. After they fill it your send will be initiated.
      </span>
      <form className={styles.form}>
        <Input
          helperText="Title"
          className={styles.title}
          placeholder="Enter title"
          name={TITLE}
          onChange={(e) => onChange(TITLE, e.target.value)}
          value={form.values?.[TITLE]}
        />
        <div className={styles.datePicker}>
          <ToggleInput
            onToggle={(isActive: boolean) =>
              onChange(EXPIRATION_DATE, isActive ? form.values[EXPIRATION_DATE] ?? null : '')
            }
            name={EXPIRATION_DATE}
            toggleLabel="Set expiration date"
            value={form.values?.[EXPIRATION_DATE]!}
            direction="row"
            hint="Stops the campaign at the chosen time. You can also stop it whenever you want from ‘One Link Manager’."
          >
            {({ isActive }) =>
              isActive ? (
                <DateSinglePicker
                  minDate={new Date()}
                  maxDate={addYears(new Date(), 1)}
                  value={form.values?.[EXPIRATION_DATE]}
                  formatRule={DateFormatsEnum.End}
                  formatView={DateFormatsEnum.FullHumanReadable}
                  onSelect={(value) => onChange(EXPIRATION_DATE, value)}
                  defaultLabel="No expiration date"
                />
              ) : null
            }
          </ToggleInput>
        </div>
        <div className={styles.datePicker}>
          <ToggleInput
            onToggle={(isActive: boolean) =>
              onChange(REDEMPTION_LIMIT, isActive ? form.values?.[REDEMPTION_LIMIT] ?? ONE_LINK_REDEMPTION_LIMIT : null)
            }
            name={REDEMPTION_LIMIT}
            toggleLabel="Redemption limit"
            value={form.values?.[REDEMPTION_LIMIT]!}
            direction="row"
          >
            {({ isActive }) =>
              isActive ? (
                <QuantityInput
                  onChange={(value) => onChange(REDEMPTION_LIMIT, value!)}
                  value={form.values?.[REDEMPTION_LIMIT]!}
                  inputClassName={styles.quantityInput}
                />
              ) : null
            }
          </ToggleInput>
        </div>
        <CheckboxGroup items={notificationOptions} onChange={onChange} />

        <div className={styles.advancedSettingContainer}>
          <InputLabel value="Advanced settings" />
          <div className={styles.advancedSettings}>
            <ToggleInput
              name={PASSWORD}
              value={form.values?.[PASSWORD] ?? ''}
              toggleLabel="Password protection"
              hint="Recipients will be asked to enter a password to access the send."
              onToggle={(isActive: boolean) => onChange(PASSWORD, isActive ? form.values[PASSWORD] ?? '' : '')}
              isRequired
            >
              {({ isActive, isReadOnly, toggleInputStyles }) => (
                <>
                  <Input
                    value={form.values?.[PASSWORD] ?? ''}
                    name={PASSWORD}
                    onChange={(e) => onChange(PASSWORD, e.target.value)}
                    inputClassName={cn(toggleInputStyles.input, toggleInputStyles.isCopyable, {
                      [toggleInputStyles.success]: form.values?.[PASSWORD]?.trim() && !isReadOnly,
                    })}
                    icon={<LockIcon />}
                    disabled={!isActive}
                    shouldTrimValue
                    readOnly={isReadOnly}
                    placeholder={isActive ? 'Enter password' : ''}
                  />
                  <CopyableButton
                    key={PASSWORD}
                    value={form.values?.[PASSWORD]!}
                    disabled={!isActive || (!form.values?.[PASSWORD]?.trim() && !isReadOnly)}
                    className={cn(toggleInputStyles.copyButton, {
                      [toggleInputStyles.success]: form.values?.[PASSWORD]?.trim(),
                    })}
                  >
                    copy
                  </CopyableButton>
                </>
              )}
            </ToggleInput>

            <div className={styles.allowedDomainInput}>
              <ToggleInput
                name={ALLOWED_DOMAINS}
                value={Array.isArray(form.values?.[ALLOWED_DOMAINS]) ? form.values[ALLOWED_DOMAINS] : []}
                toggleLabel="Email domain limiting"
                hint="Restrict access to only emails in your organization."
                isRequired
                disabled={is2FAEnabled}
                onToggle={(isActive: boolean) => {
                  const value = handleDomainsValue(isActive);
                  onChange(ALLOWED_DOMAINS, value || []);
                }}
              >
                {({ isActive, isReadOnly }) => (
                  <>
                    <TagInput
                      className={styles.tagInput}
                      // we need this to not set default value onBlur
                      onBlur={() => void 0}
                      value={Array.isArray(form.values?.[ALLOWED_DOMAINS]) ? form.values[ALLOWED_DOMAINS]! : []}
                      onChange={(value) => onChange(ALLOWED_DOMAINS, value || [])}
                      placeholder={isActive ? 'Enter domain' : ''}
                      error={form.errors[ALLOWED_DOMAINS]}
                      isReadOnly={isReadOnly || !isActive}
                      defaultValue={senderEmailDomain}
                    />
                    {isActive && !is2FAEnabled && resetToDefaultDomain}
                  </>
                )}
              </ToggleInput>
            </div>
            <div className={styles.toggleContainer}>
              <div className={styles.toggleWrapper}>
                <InputLabel
                  value="Enable 2FA"
                  className={styles.dateInputLabel}
                  hint="Recipients will be asked to verify their phone number or email address with a code."
                />
                <div className={styles.toggleInput}>
                  <Toggle
                    className={styles.toggle}
                    onChange={() => {
                      setIs2FAEnabled((prev) => {
                        if (!prev && twoFASidebarRef.current) {
                          twoFASidebarRef.current.open();
                        }

                        return !prev;
                      });
                      dispatch(addBucketValue({ [ENABLED_2FA]: !is2FAEnabled }));
                    }}
                    checked={is2FAEnabled}
                    name={ENABLED_2FA}
                  />
                  <TwoFASidebarToggle
                    ref={twoFASidebarRef}
                    count={recipients?.successfulRecipients?.length || 0}
                    disabled={!is2FAEnabled}
                  />
                </div>
              </div>
            </div>
            {!digitalSend && (
              <OLShippingDetailsContainer values={shippingValues} onChange={handleShippingDetailsUpdate} />
            )}
          </div>
        </div>
      </form>
    </div>
  );
};

export default OneLinkShippingForm;
