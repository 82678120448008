import addMinutes from 'date-fns/addMinutes';
import format from 'date-fns/format';
import isAfter from 'date-fns/isAfter';

import { DateFormatsEnum, MIN_BLUEBIRD_DATE } from '../constants/date';

export const isValidDate = (date: Date | number) => isAfter(date, MIN_BLUEBIRD_DATE);

export const formatDate = (dateString: string, dateFormat = DateFormatsEnum.MM_dd_yyyy) => {
  const utcDate = getUTCDate(dateString);

  if (isValidDate(new Date(utcDate))) {
    return format(utcDate, dateFormat);
  }

  return '';
};

export const getUTCDate = (dateString: string): Date => {
  const date = new Date(dateString);
  return addMinutes(date, date.getTimezoneOffset());
};
