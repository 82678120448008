import cn from 'classnames';
import * as React from 'react';

import { ReactComponent as MultipleOptionsIcon } from '../../assets/images/icon-msku.svg';
import { IResultPriceWithCurrency } from '../../types/shell';
import { Price } from '../index';

import styles from './CampaignViewItem.module.scss';

interface IProps {
  name: string;
  price: IResultPriceWithCurrency;
  hasMsku?: boolean;
  imageUrl?: string;
  className?: string;
  iconClassName?: string;
  additionalInfo?: React.ReactNode;
  hint?: React.ReactNode;
  defaultView?: boolean;
}
const CampaignViewItem = ({
  name,
  price,
  hasMsku,
  imageUrl,
  className,
  iconClassName,
  additionalInfo,
  hint,
  defaultView,
}: IProps) => {
  return (
    <div className={cn(styles.inventoryItem, className, { [styles.defaultView]: defaultView })}>
      <div className={cn(styles.iconInner, iconClassName)}>
        <div className={styles.icon} style={{ backgroundImage: `url(${imageUrl})` }} />
        {hint}
      </div>
      <div className={styles.inventoryInfo}>
        <Price value={price} className={styles.price} />
        <div className={cn(styles.inventoryName)}>{name}</div>
        {additionalInfo}
      </div>
      {hasMsku && <MultipleOptionsIcon className={styles.mskuIcon} />}
    </div>
  );
};

export default CampaignViewItem;
