import { Container } from '@mui/material';
import { useCallback, useEffect } from 'react';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import OneLinkSummaryComponent from '../../components/SummaryComponentMUI/OneLinkSummaryComponent';
import { CUSTOM } from '../../constants/campaigns';
import { routes } from '../../constants/routing';
import { useHeaderDispatch } from '../../contexts/HeaderInfo';
import { OneLinkSummaryProvider, TUpdateOneLinkChunk } from '../../contexts/OneLinkSummaryProvider';
import {
  disableOneLinkRequest,
  editOneLinkRequest,
  fetchOneLinkByIdRequest,
  populateOneLinkCampaignSummariesRequest,
} from '../../store/actions/oneLink';
import { fetchBusinessReasonsRequest } from '../../store/actions/reasons';
import { fetchUsersRequest } from '../../store/actions/users';
import { selectOneLinkById } from '../../store/selectors/oneLink';
import { IOneLinkWithCampaignSummaries } from '../../types/oneLink';
import { IOneLinkRouteParams } from '../../types/routing';
import { IApiError, NotificationListEnum } from '../../types/shell';
import notification from '../../utils/notification';
import { handleApiError } from '../../utils/ui';

const OneLinkSummaryContainer = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { itemId, status } = useParams<IOneLinkRouteParams>();

  const { setAction, setTitle } = useHeaderDispatch();

  const oneLink = useSelector(selectOneLinkById(itemId, status));

  const fetchOneLink = useCallback(
    (isSilent?: boolean) => {
      return new Promise<void>((resolve, reject) => {
        dispatch(
          fetchOneLinkByIdRequest(
            {
              itemId,
              status,
              resolve,
              reject,
            },
            { isSilent },
          ),
        );
      }).catch(
        handleApiError(`Something bad happened during the One Link retrieval.`, () =>
          history.push(routes.oneLinkManager.root),
        ),
      );
    },
    [dispatch, history, status, itemId],
  );

  const fetchCampaignSummaries = useCallback(
    (campaignIds: string[]) => {
      dispatch(populateOneLinkCampaignSummariesRequest({ campaign_ids: campaignIds, itemId, status }));
    },
    [dispatch, itemId, status],
  );

  useEffect(() => {
    if (!oneLink) {
      fetchOneLink();
    }
  }, [oneLink, fetchOneLink]);

  React.useEffect(() => {
    dispatch(fetchUsersRequest());
    dispatch(fetchBusinessReasonsRequest());
  }, [dispatch]);

  // Effect that requests campaign summaries
  // It should be triggered in case we didn't populate summaries earlier
  // It should be triggered in case there's a pre_created_engagement_id
  // It should be triggered in case there are pick_campaign_ids
  useEffect(() => {
    const campaignIDs = [];

    if (oneLink && !oneLink.pick_campaigns) {
      if (!!oneLink?.pre_created_engagement_id && oneLink.pre_created_engagement_id !== CUSTOM) {
        campaignIDs.push(oneLink.pre_created_engagement_id);
      }
      if (!!oneLink?.pick_campaign_ids?.length) {
        campaignIDs.push(...oneLink.pick_campaign_ids);
      }

      if (campaignIDs.length) {
        fetchCampaignSummaries(campaignIDs);
      }
    }
  }, [oneLink, fetchCampaignSummaries]);

  useEffect(() => {
    setTitle?.('One Link Summary');
    setAction?.(() => history.push(routes.oneLinkManager.root));
  }, [setTitle, setAction, history]);

  const handleUpdate = useCallback(
    (chunk: TUpdateOneLinkChunk): Promise<IApiError | void> => {
      return new Promise<IApiError | void>((resolve, reject) => {
        dispatch(
          editOneLinkRequest({
            oneLink: {
              uid: oneLink?.uid!,
              status: oneLink?.status!,
              ...chunk,
            },
            resolve,
            reject,
          }),
        );
      })
        .then((response) => {
          const { message } = (response as IApiError) || {};
          if (message && message?.length > 0) {
            notification.warning(NotificationListEnum.EditOneLink, {
              content: message,
            });
          } else {
            notification.success(NotificationListEnum.EditOneLink, {
              content: 'One Link Send was updated successfully!',
            });
          }
        })
        .then(() => {
          fetchOneLink(true);
        })
        .catch((error) => {
          notification.error(NotificationListEnum.EditOneLink, {
            content: error?.message ? error.message : 'Something bad has happened. One Link Send edit was not applied.',
          });
        });
    },
    [oneLink?.uid, oneLink?.status, dispatch],
  );

  const handleDisableOneLink = React.useCallback(
    ({ uid }: IOneLinkWithCampaignSummaries) => {
      return new Promise<string>((resolve, reject) => {
        dispatch(disableOneLinkRequest({ uid, resolve, reject }));
      }).catch(handleApiError('Cannot disable One Link Send'));
    },
    [dispatch],
  );

  return (
    <OneLinkSummaryProvider onUpdate={handleUpdate} onDeactivate={handleDisableOneLink} instance={oneLink ?? null}>
      <Container
        maxWidth="xl"
        sx={{
          paddingLeft: { lg: 12 },
          marginTop: 2,
          '@media (min-width: 1200px) and (max-width: 1367px)': {
            paddingLeft: 3,
          },
        }}
      >
        <OneLinkSummaryComponent reportMode />
      </Container>
    </OneLinkSummaryProvider>
  );
};

export default OneLinkSummaryContainer;
