import * as React from 'react';

import styles from './SendTitle.module.scss';

interface IProps {
  title?: string;
  className?: string;
}

const SendTitle: React.FC<IProps> = ({ title, className }) => {
  return (
    <div className={className}>
      {title ? (
        <>
          <span className={styles.label}>Title: </span>
          {title}
        </>
      ) : (
        'No title'
      )}
    </div>
  );
};

export default SendTitle;
