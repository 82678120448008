import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Button, useTheme } from '@mui/material';
import * as React from 'react';

interface IProps {
  orderId: string;
  reportUrl: string;
}

export const OrderLinkButton: React.FC<IProps> = ({ orderId, reportUrl }) => {
  const theme = useTheme();

  return (
    <Button
      onClick={() => window.open(reportUrl, '_blank')}
      sx={{
        display: 'flex',
        alignItems: 'center',
        fontWeight: theme.typography.fontWeightRegular,
        gap: 1,
        padding: 0,
        '&:hover': {
          backgroundColor: 'transparent',
          textDecoration: 'underline',
          color: theme.palette.primary.main,
          '& .MuiSvgIcon-root': {
            opacity: 1,
            transform: 'translateX(0)',
          },
        },
      }}
    >
      {orderId}
      <OpenInNewIcon
        fontSize="small"
        sx={{
          opacity: 0,
          transform: `translateX(-100%)`,
          transition: 'all 0.25s ease',
        }}
      />
    </Button>
  );
};
