import ClearIcon from '@mui/icons-material/Clear';
import { Box, FormControlLabel, IconButton, MenuItem, Switch, TextField } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useFormik } from 'formik';
import { forwardRef, useCallback, useEffect, useMemo } from 'react';
import * as React from 'react';
import { useSelector } from 'react-redux';
import * as yup from 'yup';

import { DateFormatsEnum } from '../../../constants/date';
import { IOneLinkChunkFormProps } from '../../../contexts/OneLinkSummaryProvider';
import { selectBusinessReasons } from '../../../store/selectors/reasons';
import { ICampaignSummary } from '../../../types/campaigns';
import { formatDate, isValidDate } from '../../../utils/date';
import { isObjectsEqual } from '../../../utils/helpers';
import { FormControls, Label } from '../../SummaryComponentMUI/components/common';
import { DateSinglePicker } from '../inputs';

const AdditionalForm = forwardRef(({ onSubmit, instance, onClose }: IOneLinkChunkFormProps, ref: any) => {
  const reasons = useSelector(selectBusinessReasons);

  const isBusinessReasonRequired = useMemo(
    () => instance?.pick_campaigns?.some((c: ICampaignSummary) => c.business_reason_required),
    [instance?.pick_campaigns],
  );

  const validationSchema = yup.object().shape({
    on_hold_until: yup.string().nullable(),
    disable_sender_email_notifications: yup.boolean(),
    business_reason_id: yup
      .string()
      .nullable()
      .test('is-required', 'Business reason is required in campaign', (value) => {
        const noValue = !value || value.trim() === '';

        return !(isBusinessReasonRequired && noValue);
      }),
    internal_notes: yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      on_hold_until:
        instance?.on_hold_until && isValidDate(new Date(instance?.on_hold_until)) ? instance.on_hold_until : null,
      disable_sender_email_notifications: instance?.disable_sender_email_notifications,
      business_reason_id: instance?.business_reason_id || '',
      internal_notes: instance?.internal_notes || '',
    },
    validationSchema,
    onSubmit: (values) => {
      onSubmit({ additional_info: values });
      ref.current = false;
    },
  });

  const { on_hold_until, disable_sender_email_notifications, business_reason_id, internal_notes } = formik.values;
  const { handleChange, setFieldValue, handleSubmit, initialValues } = formik;

  const handleResetDate = useCallback(() => {
    setFieldValue('on_hold_until', '');
  }, [setFieldValue]);

  useEffect(() => {
    ref.current = !isObjectsEqual(initialValues, formik.values);
  }, [formik.values, initialValues]);

  return (
    <Box
      component="form"
      role="form"
      sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}
      onSubmit={handleSubmit}
    >
      <Box>
        <Grid container spacing={6} sx={{ mb: 4 }}>
          <Grid container direction="column" spacing={1} component={Box}>
            <Label>On Hold Until</Label>
            <DateSinglePicker
              minDate={new Date()}
              value={on_hold_until || null}
              formatRule={DateFormatsEnum.yyyyMMdd}
              onSelect={(newStartDate) => {
                setFieldValue('on_hold_until', newStartDate ? formatDate(newStartDate, DateFormatsEnum.Start) : null);
              }}
              onReset={handleResetDate}
              defaultView
            />
          </Grid>
          <Grid component={Box}>
            <Label>Sender Notifications</Label>
            <FormControlLabel
              control={
                <Switch
                  name="disable_sender_email_notifications"
                  checked={!disable_sender_email_notifications}
                  onChange={(e) => formik.setFieldValue('disable_sender_email_notifications', !e.target.checked)}
                />
              }
              label={disable_sender_email_notifications ? 'Disabled' : 'Enabled'}
            />
          </Grid>
        </Grid>
        <TextField
          select
          label="Business Reason"
          name="business_reason_id"
          value={business_reason_id}
          onChange={handleChange}
          variant="outlined"
          fullWidth
          sx={{ mb: 4 }}
          helperText={formik.errors.business_reason_id}
          error={!!formik.errors.business_reason_id}
          slotProps={{
            select: {
              MenuProps: {
                sx: {
                  '& .MuiPaper-root': {
                    maxHeight: 260,
                    overflowY: 'auto',
                  },
                },
              },
            },
            input: {
              endAdornment: (
                <span>
                  {business_reason_id?.length !== 0 && (
                    <IconButton
                      size="small"
                      sx={{ mr: 2 }}
                      onClick={() => handleChange({ target: { name: 'business_reason_id', value: '' } })}
                    >
                      <ClearIcon />
                    </IconButton>
                  )}
                </span>
              ),
            },
          }}
        >
          {reasons?.map((reason) => (
            <MenuItem key={reason.uid} value={reason.uid}>
              {reason.name}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          multiline
          label="Internal Notes"
          name="internal_notes"
          value={internal_notes}
          onChange={handleChange}
          variant="outlined"
          minRows={5}
          fullWidth
          sx={{ mb: 4 }}
        />
      </Box>
      <FormControls
        onCancel={() => {
          onClose();
        }}
        isFormChanged={ref.current}
        isDisabled={!ref.current || !!formik.errors.business_reason_id}
      />
    </Box>
  );
});

export default AdditionalForm;
