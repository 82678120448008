import { Button, Typography, useTheme } from '@mui/material';
import * as React from 'react';
import { routes, URL_VARS } from '../../../constants/routing';
import { IOneLinkCreatedSend, IOneLinkWithCampaignSummaries } from '../../../types/oneLink';
import { ReportTypesEnum } from '../../../types/reports';
import { OrderLinkButton } from '../../_mui/_controls_';
import { TableMUI } from '../../_mui/_tables_';
import { ITableColumn } from '../../_mui/_tables_/Table';
import { Section } from './common';

interface IProps {
  instance?: IOneLinkWithCampaignSummaries | null;
}

export const ConfirmedSendsSection: React.FC<IProps> = ({ instance }) => {
  const theme = useTheme();

  const tableConfig: ITableColumn<IOneLinkCreatedSend>[] = [
    {
      label: 'Recipient',
      render: ({ first_name, last_name }) => (
        <Typography variant="body2">
          {first_name} {last_name}
        </Typography>
      ),
    },
    {
      label: 'Order ID',
      render: ({ uid, order_id }) => (
        <OrderLinkButton
          orderId={order_id}
          reportUrl={routes.reports.getReportsUrl({
            reportId: uid,
            type: ReportTypesEnum.Regular,
            flowId: URL_VARS.SUMMARY,
          })}
        />
      ),
    },
    {
      label: 'Links',
      render: ({ digital_gift_url, tracking_url }) => (
        <>
          {digital_gift_url && (
            <Button onClick={() => navigator.clipboard.writeText(digital_gift_url)}>Copy URL</Button>
          )}
          {tracking_url && <Button onClick={() => navigator.clipboard.writeText(tracking_url)}>Copy URL</Button>}
        </>
      ),
    },
  ];

  return (
    <Section
      title={
        <>
          Confirmed Sends:{' '}
          <span style={{ fontWeight: theme.typography.fontWeightRegular }}>{instance?.created_sends?.length || 0}</span>
        </>
      }
      minHeight={600}
    >
      <TableMUI data={instance?.created_sends} columns={tableConfig} />
    </Section>
  );
};

export default ConfirmedSendsSection;
