import { useTheme } from '@mui/material';
import * as React from 'react';

export default () => {
  const { sass: variables } = useTheme();
  const [width, setWidth] = React.useState(window.innerWidth);
  const [height, setHeight] = React.useState(window.innerHeight);

  const handleResize = () => {
    setWidth(window.innerWidth!);
    setHeight(window.innerHeight!);
  };

  React.useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const mobile = React.useMemo(() => (width ? width <= parseInt(variables.breakpointMobile, 10) : false), [width]);
  const medium = React.useMemo(() => (width ? width <= parseInt(variables.breakpointMedium, 10) : false), [width]);
  const smallTablet = React.useMemo(
    () => (width ? width <= parseInt(variables.breakpointDesktop, 10) : false),
    [width],
  );
  const tablet = React.useMemo(
    () => (width ? width <= parseInt(variables.breakpointLargeDesktop, 10) : false),
    [width],
  );
  const desktop = React.useMemo(
    () =>
      width
        ? width > parseInt(variables.breakpointLargeDesktop, 10) && width <= parseInt(variables.breakpointHD, 10)
        : false,
    [width],
  );
  const HD = React.useMemo(() => (width ? width <= parseInt(variables.breakpointHD, 10) : false), [width]);
  const laptop = React.useMemo(() => (width ? width <= parseInt(variables.breakpointFullHD, 10) : false), [width]);
  const fullHD = React.useMemo(() => (width ? width > parseInt(variables.breakpointFullHD, 10) : false), [width]);

  return {
    width,
    height,
    mobile,
    medium,
    smallTablet,
    tablet,
    desktop,
    HD,
    laptop,
    fullHD,
  };
};
