import { Box } from '@mui/material';
import { forwardRef, useCallback, useEffect, useMemo } from 'react';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { OLShippingDetailsContainer } from '../../../containers';
import { IOneLinkChunkFormProps } from '../../../contexts/OneLinkSummaryProvider';
import { selectAddressBook } from '../../../store/selectors/addressBook';
import { IOneLinkShippingDetails } from '../../../types/shipping';
import { isAllOrgAddressesActive } from '../../../utils/addressBook';
import { isObjectEmpty, isObjectsEqual } from '../../../utils/helpers';
import { FormControls } from '../../SummaryComponentMUI/components/common';

const ShippingForm = forwardRef(({ onSubmit, instance, onClose }: IOneLinkChunkFormProps, ref: any) => {
  const addresses = useSelector(selectAddressBook);

  const initialShippingValues = useMemo(
    () => ({
      is_receiver_address_fixed: instance?.is_receiver_address_fixed ?? false,
      receiver_address: instance?.receiver_fixed_address,
      org_address_ids: instance?.org_address_ids,
    }),
    [instance],
  );

  const [shipping, setShipping] = React.useState<IOneLinkShippingDetails>(initialShippingValues);

  const handleChange = useCallback((v: IOneLinkShippingDetails) => {
    setShipping(v);
  }, []);

  const handleSubmit = useCallback(() => {
    const { receiver_address, ...rest } = shipping; // Remove receiver_address

    onSubmit({
      shipping_info: {
        ...rest,
        receiver_fixed_address: receiver_address,
      },
    });

    ref.current = false;
  }, [ref, shipping, onSubmit]);

  const handleReset = useCallback(() => {
    setShipping(initialShippingValues);
    onClose();
  }, [onClose, initialShippingValues]);

  const isValidForm = useMemo(() => {
    const { receiver_address, org_address_ids, is_receiver_address_fixed } = shipping;

    const areOrgAddressesActive =
      org_address_ids && org_address_ids.length > 0 ? isAllOrgAddressesActive(addresses, org_address_ids) : true;

    // Recipient Decides with addresses
    if (!is_receiver_address_fixed && org_address_ids?.length) {
      return areOrgAddressesActive;
    }

    // Fixed address and Recipient Decides from List Only
    if (is_receiver_address_fixed) {
      const hasValidOrgAddresses = org_address_ids && org_address_ids.length >= 2 && areOrgAddressesActive;
      const hasValidFixedAddress = receiver_address && !isObjectEmpty(receiver_address);

      return hasValidOrgAddresses || hasValidFixedAddress;
    }

    return true;
  }, [shipping, addresses]);

  const isValuesEqual = useMemo(
    () => isObjectsEqual(initialShippingValues, shipping),
    [initialShippingValues, shipping],
  );

  useEffect(() => {
    ref.current = !isValuesEqual;
  }, [isValuesEqual]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
      <OLShippingDetailsContainer mui values={shipping} onChange={handleChange} maxHeightList />
      <FormControls
        onCancel={handleReset}
        onSubmit={handleSubmit}
        isFormChanged={!isValuesEqual}
        isDisabled={!isValidForm || isValuesEqual}
      />
    </Box>
  );
});

export default ShippingForm;
