import { OneLinkStatusEnum } from '../types/oneLink';
import { FilterTypesEnum } from '../types/shell';
import { DEPT_ADMIN, DISTRIBUTOR, ORG_ADMIN, SUPER_ADMIN } from './users';

import variables from '../_variables.scss';

export const UID = 'uid';
export const URL = 'url';

export const MAX_ONE_LINKS_PER_PAGE = 10;
export const MAX_RECIPIENTS_PER_PAGE = 10;

export const ONE_LINK_FILTERS = [
  {
    label: 'Organization',
    filterId: FilterTypesEnum.Organization,
    role: [ORG_ADMIN, DISTRIBUTOR, SUPER_ADMIN],
  },
  {
    label: 'Department',
    filterId: FilterTypesEnum.Department,
    role: [DEPT_ADMIN],
  },
  {
    label: 'Individual',
    filterId: FilterTypesEnum.Individual,
  },
];

export const ONE_LNK_TABS = [
  {
    label: 'Active',
    tabId: OneLinkStatusEnum.Active,
  },
  {
    label: 'Not active',
    tabId: OneLinkStatusEnum.NotActive,
  },
];

export const ONE_LINK_STATUS = {
  [OneLinkStatusEnum.Active]: {
    label: 'Active',
    color: variables.budgetGreen,
  },
  [OneLinkStatusEnum.NotActive]: {
    label: 'Not active',
    color: variables.errorRed,
  },
};

export const FIRST_NAME = 'first_name';
export const LAST_NAME = 'last_name';
export const EMAIL_ADDRESS = 'email';
export const PHONE_NUMBER = 'phone';

export const ERROR_EMAIL_OR_PHONE_REQUIRED = 'Email or phone is required';
export const ERROR_DUPLICATE_EMAIL = 'Duplicate email';
export const ERROR_DUPLICATE_PHONE = 'Duplicate phone';
export const ERROR_EMAIL_INVALID = 'Email is invalid';
export const ERROR_PHONE_INVALID = 'Phone is invalid';

export const ALLOWED_RECIPIENTS_TABLE_HEADERS = {
  [FIRST_NAME]: 'First name',
  [LAST_NAME]: 'Last name',
  [EMAIL_ADDRESS]: 'Email address',
  [PHONE_NUMBER]: 'Phone number',
};

export const ONE_LINK_REDEMPTION_LIMIT = 10;
export const TITLE = 'title';
