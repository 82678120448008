import { generatePath } from 'react-router-dom';
import {
  ICampaignRouteParams,
  IInventoryRouteParams,
  IOneLinkRouteParams,
  IOrganizationRouteParams,
  IReportsRouteParams,
  ITemplateRouteParams,
  IUserRouteParams,
} from '../types/routing';

export enum SupportedFlowsEnum {
  Single = 'new',
  Bulk = 'bulk',
  OneLink = 'one-link',
  DirectSend = 'direct',
}

export const URL_VARS = {
  ROOT: '/',
  LOGIN: 'login',
  DASHBOARD: 'dashboard',
  ENGAGEMENT: 'send',
  CUSTOM: 'custom',
  INVENTORY: 'inventory',
  CAMPAIGNS: 'campaigns',
  AUTOMATED_CAMPAIGNS: 'automated-campaigns',
  REPORTS: 'reports',
  BOX: 'box',
  CUSTOMIZE: 'customize',
  UNAUTHORIZED: 'unauthorized',
  SHIPPING: 'shipping',
  NOTE: 'note',
  EMAIL: 'email',
  SUMMARY: 'summary',
  USERS: 'users',
  SETTINGS: 'settings',
  USER_INFO: 'user-info',
  UPLOAD: 'upload',
  INTEGRATIONS: 'integrations',
  SUGGEST_GIFT: 'suggest-gift',
  SF_SUCCESS: 'sf_success',
  HUBSPOT_SUCCESS: 'hubspot_success',
  OUTLOOK_SUCCESS: 'outlook_success',
  REPORT_ID: 'reportId',
  FLOW_ID: 'flowId',
  SINGLE: SupportedFlowsEnum.Single,
  BULK: SupportedFlowsEnum.Bulk,
  ONE_LINK: SupportedFlowsEnum.OneLink,
  DIRECT_SEND: SupportedFlowsEnum.DirectSend,
  ITEM_ID: 'itemId',
  STEP_ID: 'stepId',
  USER_ID: 'userId',
  ORG_ID: 'orgId',
  ADD: 'add',
  NEW: 'new',
  EDIT: 'edit',
  VIEW: 'view',
  DETAILS: 'details',
  TEXT_CUSTOMIZATION: 'text-customization',
  ITEMS: 'items',
  TEMPLATES: 'templates',
  ORGANIZATIONS: 'organizations',
  BUSINESS_REASONS: 'business-reasons',
  DIGITAL_BUDGET: 'digital-budget',
  SENDS_MANAGER: 'sends-manager',
  ONE_LINK_MANAGER: 'one-link-manager',
  TYPE: 'type',
  REGULAR: 'regular',
  SCHEDULED: 'scheduled',
  SIGNATURE_SETUP: 'signature-setup',
  STATUS: 'status',
  ACTIVE: 'active',
  NOT_ACTIVE: 'not_active',
  ADDRESS_BOOK: 'address-book',
};

export const routes = {
  root: `${URL_VARS.ROOT}`,
  login: `/${URL_VARS.LOGIN}`,
  dashboard: `/${URL_VARS.DASHBOARD}`,
  reports: {
    root: `/${URL_VARS.REPORTS}`,
    route: `/${URL_VARS.REPORTS}/:${URL_VARS.FLOW_ID}(${URL_VARS.SUMMARY})?/:${URL_VARS.REPORT_ID}?/:${URL_VARS.TYPE}(${URL_VARS.REGULAR}|${URL_VARS.SCHEDULED})`,
    getReportsUrl: (params: IReportsRouteParams) =>
      generatePath(routes.reports.route, params as { [key in keyof IReportsRouteParams]: string }),
  },
  engagement: {
    root: `/${URL_VARS.ENGAGEMENT}`,
    route: `/${URL_VARS.ENGAGEMENT}/:${URL_VARS.FLOW_ID}?/:stepId?`,
    bulk: `/${URL_VARS.ENGAGEMENT}/${URL_VARS.BULK}`,
    new: `/${URL_VARS.ENGAGEMENT}/${URL_VARS.SINGLE}`,
    oneLink: `/${URL_VARS.ENGAGEMENT}/${URL_VARS.ONE_LINK}`,
    directSend: `/${URL_VARS.ENGAGEMENT}/${URL_VARS.DIRECT_SEND}`,
    getSingleUrl: (slug: string) => `/${URL_VARS.ENGAGEMENT}/${URL_VARS.SINGLE}/${slug}`,
    getBulkUrl: (slug: string) => `/${URL_VARS.ENGAGEMENT}/${URL_VARS.BULK}/${slug}`,
    getOneLinkUrl: (slug: string) => `/${URL_VARS.ENGAGEMENT}/${URL_VARS.ONE_LINK}/${slug}`,
    getDirectSendUrl: (slug: string) => `/${URL_VARS.ENGAGEMENT}/${URL_VARS.DIRECT_SEND}/${slug}`,
  },
  users: {
    root: `/${URL_VARS.USERS}`,
    route: `/${URL_VARS.USERS}/:${URL_VARS.FLOW_ID}(${URL_VARS.NEW}|${URL_VARS.EDIT}|${URL_VARS.VIEW})?/:${URL_VARS.USER_ID}?`,
    getUserUrl: (params: IUserRouteParams) =>
      generatePath(routes.users.route, params as { [key in keyof IUserRouteParams]: string }),
  },
  organizations: {
    root: `/${URL_VARS.ORGANIZATIONS}`,
    route: `/${URL_VARS.ORGANIZATIONS}/:${URL_VARS.FLOW_ID}(${URL_VARS.NEW}|${URL_VARS.EDIT}|${URL_VARS.VIEW})?/:${URL_VARS.ORG_ID}?`,
    getOrganizationUrl: (params: IOrganizationRouteParams) =>
      generatePath(routes.organizations.route, params as { [key in keyof IOrganizationRouteParams]: string }),
  },
  businessReasons: `/${URL_VARS.BUSINESS_REASONS}`,
  addressBook: `/${URL_VARS.ADDRESS_BOOK}`,
  inventory: {
    root: `/${URL_VARS.INVENTORY}`,
    route: `/${URL_VARS.INVENTORY}/:${URL_VARS.FLOW_ID}(${URL_VARS.NEW}|${URL_VARS.EDIT}|${URL_VARS.VIEW})?/:${URL_VARS.ITEM_ID}?`,
    getInventoryUrl: (params: IInventoryRouteParams) =>
      generatePath(routes.inventory.route, params as { [key in keyof IInventoryRouteParams]: string }),
  },
  automations: `/${URL_VARS.AUTOMATED_CAMPAIGNS}`,
  campaigns: {
    root: `/${URL_VARS.CAMPAIGNS}`,
    route: `/${URL_VARS.CAMPAIGNS}/:${URL_VARS.ITEM_ID}?/:${URL_VARS.FLOW_ID}(${URL_VARS.NEW}|${URL_VARS.EDIT}|${URL_VARS.VIEW})/:${URL_VARS.STEP_ID}(${URL_VARS.DETAILS}|${URL_VARS.ITEMS}|${URL_VARS.SUMMARY}|${URL_VARS.TEXT_CUSTOMIZATION})?`,
    getCampaignUrl: (params: ICampaignRouteParams) =>
      generatePath(routes.campaigns.route, params as { [key in keyof ICampaignRouteParams]: string }),
  },
  templates: {
    root: `/${URL_VARS.TEMPLATES}`,
    route: `/${URL_VARS.TEMPLATES}/:${URL_VARS.ITEM_ID}?/:${URL_VARS.FLOW_ID}(${URL_VARS.NEW}|${URL_VARS.EDIT}|${URL_VARS.VIEW})`,
    getTemplateUrl: (params: ITemplateRouteParams) =>
      generatePath(routes.templates.route, params as { [key in keyof ITemplateRouteParams]: string }),
  },
  integrations: `/${URL_VARS.INTEGRATIONS}`,
  signatureSetup: `/${URL_VARS.SIGNATURE_SETUP}`,
  settings: `/${URL_VARS.SETTINGS}`,
  userInfo: `/${URL_VARS.USER_INFO}`,
  engagementsManager: `/${URL_VARS.SENDS_MANAGER}`,
  oneLinkManager: {
    root: `/${URL_VARS.ONE_LINK_MANAGER}`,
    route: `/${URL_VARS.ONE_LINK_MANAGER}/:${URL_VARS.ITEM_ID}/:${URL_VARS.STATUS}(${URL_VARS.ACTIVE}|${URL_VARS.NOT_ACTIVE})`,
    getOneLinkManagerUrl: (params: IOneLinkRouteParams) =>
      generatePath(routes.oneLinkManager.route, params as { [key in keyof IOneLinkRouteParams]: string }),
  },
  sfAuthorizeCallback: `/${URL_VARS.SF_SUCCESS}`,
  hubspotAuthorizeCallback: `/${URL_VARS.HUBSPOT_SUCCESS}`,
  outlookAuthorizeCallback: `/${URL_VARS.OUTLOOK_SUCCESS}`,
  suggestGift: `/${URL_VARS.SUGGEST_GIFT}`,
};

export const endpoints = {
  exchangeAuth: '/ExchangeAuthCode1_2',
  authorize: '/Authorize1_3',
  inventoryItemById: '/GetInventoryItem',
  inventoryItems: '/GetInventoryItems',
  lowInventoryItems: '/GetLowInventoryItems',
  getCampaigns: '/GetCampaigns',
  getCampaignById: '/GetCampaign',
  notes: '/GetMessageTemplates',
  emails: '/GetEmailTemplates',
  engagement: '/CreateEngagement',
  editEngagement: '/EditEngagement',
  editOneLink: '/EditOneLink',
  checkSFAuth: '/IsUserAuthorizedOnSalesforce',
  getLeadsAndContacts: '/GetLeadsAndContacts',
  getOpportunities: '/GetOpportunities',
  logs: '/AddLogsInBulk',
  uploadFileToStorage: '/UploadFileToStorage1_3',
  userOptions: '/GetUserOptionsAndPermissions',
  exchangeSfAuthCode: '/ExchangeSalesforceAuthCode',
  bulkWithCSV: '/CreateBulkEngagementsWithCSV',
  reports: '/GetDeliveryReport',
  users: '/GetAllUsers',
  addUser: '/AddUserToOrg',
  editUser: '/EditUserInOrg',
  editUserProfile: '/EditUserProfile',
  toggleDigitalBalanceEmails: '/ToggleDigitalBalanceEmails',
  deleteUser: '/DeleteUserFromOrg',
  addAutomationConnection: '/CreateAutomationConnection',
  updateAutomationConnection: '/UpdateAutomationConnection',
  deleteAutomationConnection: '/DeleteAutomationConnection',
  report: '/GetEngagement',
  addInventoryItem: '/AddInventoryItem',
  editInventoryItem: '/EditInventoryItem',
  getInventoryImagesList: '/GetProductImages',
  deleteInventoryItem: '/DeleteInventoryItem',
  addCampaign: '/CreateCampaign',
  editCampaign: '/EditCampaign',
  deleteCampaign: '/DeleteCampaign',
  getOrganizations: '/GetOrganizations',
  changeOrganization: '/ChangeOrganization',
  getOrgCustomizationOptions: '/GetOrgCustomizationOptions',
  getCampaignIconsList: '/GetCampaignIcons',
  exchangeHubspotAuthCode: '/ExchangeHubspotAuthCode',
  checkHubspotAuth: '/IsUserAuthorizedOnHubspot',
  getBusinessReasons: '/GetBusinessReasons',
  addBusinessReason: '/AddBusinessReason',
  deleteBusinessReason: '/DeleteBusinessReason',
  editBusinessReason: '/EditBusinessReason',
  createTemplate: '/CreateTemplate',
  deleteTemplate: '/DeleteTemplateFromOrg',
  editTemplate: '/EditTemplate',
  resetPassword: '/CreatePasswordResetLink',
  getAccountBalance: '/GetAccountBalance',
  generateAPIKey: '/GenerateAPIKey',
  getUserApiKey: '/GetUserApiKey',
  createOrgDepartment: '/CreateOrgDepartment',
  editOrgDepartment: '/EditOrgDepartment',
  getBluebirdMetadata: '/GetBluebirdMetadata',
  createOrg: '/CreateOrg',
  editOrg: '/EditOrg',
  validateOrderKey: '/ValidateOrderKey',
  deleteEngagement: '/DeleteEngagement',
  cancelEngagement: '/CancelEngagement',
  getCampaignSummaries: '/GetCampaignSummaries',
  fulfillmentCenters: '/GetFulfillmentCenters',
  saveEmailSignature: '/SaveEmailSignature',
  downloadReportsCSV: '/DownloadEngagementsReportCSV',
  getDashboard: '/GetDashboard',
  getDepartmentBudgetSummaries: '/GetDepartmentBudgetSummaries',
  updateDepartmentBudget: '/UpdateDepartmentBudget',
  createOneLink: '/CreateOneLink',
  getOneLinkSends: '/GetOneLinkSends',
  getOneLinkSendById: '/GetOneLinkSend',
  disableOneLinkSend: '/DisableOneLinkSend',
  downloadInventoryReportCSV: '/DownloadInventoryReport',
  handlePIIForOrganization: '/HandlePIIForOrganization',
  sendFeedback: '/SaveUserFeedback',
  improveText: '/ImproveUserInputText',
  bulkCreateInventoryItemsFromCSV: '/BulkCreateInventoryItemsFromCSV',
  generateGiftSuggest: '/GenerateGiftSuggestions',
  editSendField: '/EditSendField',
  deleteOneLinkRecipient: '/DeleteOneLinkRecipients',
  updateOneLinkRecipient: '/UpdateOneLinkRecipient',
  createOneLinkRecipients: '/CreateOneLinkRecipients',
  getOneLinkRecipients: '/GetOneLinkRecipients',
  getAddressBook: '/GetAddressBook',
  createOrgAddress: '/CreateOrgAddress',
  updateOrgAddress: '/UpdateOrgAddress',
  addAutomatedCampaign: '/CreateAutomatedCampaign',
  updateAutomationCampaign: '/UpdateAutomatedCampaign',
  deleteAutomatedCampaign: '/DeleteAutomatedCampaign',
  automatedCampaigns: '/GetAutomatedCampaigns',
};

export const queryParams = {
  deptSearch: 'dept_search',
  orgSearch: 'org_search',
  uid: 'uid',
  cid: 'cid',
  orgId: 'org_id',
  itemId: 'item_id',
  responseData: 'response_data',
  orderKey: 'order_key',
  orderId: 'order_id',
  type: 'type',
  searchType: 'search_type',
  engagementType: 'engagement_type',
  campaignId: 'campaign_id',
  dashboardSubOption: 'sub',
  fileType: 'file_type',
  orgInventory: 'org_inventory',
  oneLinkId: 'one_link_id',
  all: 'all',
  newOwnerId: 'new_owner_id',
};

export const crmEndpoints = {
  authorizeSF: '/services/oauth2/authorize',
  authorizeHubspot: '/oauth/authorize',
  authorizeOutlook: '/oauth2/v2.0/authorize',
};
export const IE_PP_URL = 'https://www.imprintengine.com/privacy-policy';
export const IE_HC_URL = 'https://help.imprintengine.com/knowledge';
