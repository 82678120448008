import { useTheme } from '@mui/material';
import cn from 'classnames';
import React from 'react';

import { Drawer } from '../../components';
import useWindowSize from '../../hooks/useWindowSize';
import { DrawerAnimationDirectionEnum } from '../../types/shell';

import styles from './AddressBookSidebar.module.scss';

interface IProps {
  onClose: () => void;
  trigger: boolean;
  children?: React.ReactNode;
}

const AddressBookSidebar: React.FC<IProps> = ({ trigger, onClose, children }) => {
  const { sass: variables } = useTheme();
  const { mobile } = useWindowSize();
  const from = React.useMemo(
    () => (mobile ? variables.muiSidebarMinHeight : variables.muiSidebarMinWidth),
    [mobile, variables.muiSidebarMinHeight, variables.muiSidebarMinWidth],
  );

  const to = React.useMemo(() => {
    if (mobile) {
      return variables.muiSidebarMaxWidth;
    }

    return variables.muiSidebarMaxWidth;
  }, [mobile, variables.muiSidebarMaxWidth]);

  return (
    <Drawer
      className={cn(styles.container)}
      direction={mobile ? DrawerAnimationDirectionEnum.Vertically : DrawerAnimationDirectionEnum.Horizontally}
      from={from}
      to={to}
      trigger={trigger}
      onClose={onClose}
      withOverlay
      withPortal
      overlayClassName={styles.overlay}
    >
      <div className={styles.wrapper}>{trigger && children}</div>
    </Drawer>
  );
};

export default AddressBookSidebar;
