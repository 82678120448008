import { IInventoryItem } from '../../types/inventories';
import { ICommonAddress } from '../../types/shipping';

export enum OneLinkChunksEnum {
  SummaryInfo = 'summary_info',
  InventoryInfo = 'inventory_info',
  ShippingInfo = 'shipping_info',
  AdditionalInfo = 'additional_info',
  SecurityInfo = 'security_info',
  TwoFactorInfo = 'two_factor_info',
}

export interface IOLSummaryInfo {
  expiration_date: string | null;
  title: string;
  user_id: string;
}

export type TOLInventoryInfo =
  | {
      items: IInventoryItem[];
      pre_created_engagement_id: string;
      campaign_name: string;
    }
  | { pick_campaign_ids: string[] };

export interface IOLShippingInfo {
  is_receiver_address_fixed: boolean;
  receiver_fixed_address?: ICommonAddress;
  org_address_ids?: string[];
}

export interface IOLAdditionalInfo {
  on_hold_until: string | null;
  disable_sender_email_notifications?: boolean;
  business_reason_id: string;
  internal_notes: string;
}

export interface IOLSecurityInfo {
  password: string;
  allowed_domains: string[];
  max_submit_count: number;
  enabled_2fa: boolean;
}

export type TUpdateOneLinkChunk =
  | {
      [OneLinkChunksEnum.SummaryInfo]: IOLSummaryInfo;
      [OneLinkChunksEnum.InventoryInfo]?: never;
      [OneLinkChunksEnum.ShippingInfo]?: never;
      [OneLinkChunksEnum.AdditionalInfo]?: never;
      [OneLinkChunksEnum.SecurityInfo]?: never;
    }
  | {
      [OneLinkChunksEnum.SummaryInfo]?: never;
      [OneLinkChunksEnum.InventoryInfo]: TOLInventoryInfo;
      [OneLinkChunksEnum.ShippingInfo]?: never;
      [OneLinkChunksEnum.AdditionalInfo]?: never;
      [OneLinkChunksEnum.SecurityInfo]?: never;
    }
  | {
      [OneLinkChunksEnum.SummaryInfo]?: never;
      [OneLinkChunksEnum.InventoryInfo]?: never;
      [OneLinkChunksEnum.ShippingInfo]?: IOLShippingInfo;
      [OneLinkChunksEnum.AdditionalInfo]?: never;
      [OneLinkChunksEnum.SecurityInfo]?: never;
    }
  | {
      [OneLinkChunksEnum.SummaryInfo]?: never;
      [OneLinkChunksEnum.InventoryInfo]?: never;
      [OneLinkChunksEnum.ShippingInfo]?: never;
      [OneLinkChunksEnum.AdditionalInfo]: IOLAdditionalInfo;
      [OneLinkChunksEnum.SecurityInfo]?: never;
    }
  | {
      [OneLinkChunksEnum.SummaryInfo]?: never;
      [OneLinkChunksEnum.InventoryInfo]?: never;
      [OneLinkChunksEnum.ShippingInfo]?: never;
      [OneLinkChunksEnum.AdditionalInfo]?: never;
      [OneLinkChunksEnum.SecurityInfo]: IOLSecurityInfo;
    };

export type TUpdateOneLinkPayload = TUpdateOneLinkChunk & {
  uid?: string;
};
