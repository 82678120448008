import { Box } from '@mui/material';
import Grid from '@mui/material/Grid2';
import * as React from 'react';

import { OneLinkStatusEnum } from '../../types/oneLink';
import { Loader } from '../index';
import { Sidebar } from './components/common';

import { useOneLinkSummary } from '../../contexts/OneLinkSummaryProvider';
import {
  AdditionalSection,
  ConfirmedSendsSection,
  InventorySection,
  SecuritySection,
  ShippingSection,
  SummarySection,
  TwoFASection,
} from './components';
interface IProps {
  reportMode: boolean;
}

const OneLinkSummaryComponent: React.FC<IProps> = ({ reportMode }) => {
  const gridSizes = { xs: 12, md: 6, lg: 4 };

  const {
    handleEdit,
    onSubmit,
    modes: editModes,
    onDeactivate,
    Form: EditForm,
    title: sidebarTitle,
    active: sidebarMode,
    instance,
    isLoading,
  } = useOneLinkSummary();

  if (!instance) {
    return null;
  }

  return (
    <Grid container sx={{ width: '100%' }} spacing={2}>
      <Grid size={12}>
        <SummarySection
          instance={instance}
          reportMode={reportMode}
          actions={[
            {
              title: 'Edit',
              onClick: () => handleEdit(editModes.SummaryInfo),
              disabled: false,
            },
            {
              title: 'Deactivate',
              onClick: onDeactivate,
              disabled: !onDeactivate || instance?.status === OneLinkStatusEnum.NotActive,
            },
          ]}
        />
      </Grid>
      <Grid size={gridSizes}>
        <InventorySection instance={instance} />
      </Grid>
      <Grid size={gridSizes}>
        <ShippingSection instance={instance} onEdit={() => handleEdit(editModes.ShippingInfo)} />
      </Grid>
      <Grid size={gridSizes}>
        <AdditionalSection instance={instance} onEdit={() => handleEdit(editModes.AdditionalInfo)} />
      </Grid>
      <Grid container size={gridSizes} spacing={2} sx={{ mb: 2 }}>
        <SecuritySection instance={instance} />
        <TwoFASection instance={instance} reportMode={reportMode} />
      </Grid>
      <Grid size={{ xs: 12, lg: 8 }} sx={{ mb: 2 }}>
        <ConfirmedSendsSection instance={instance} />
      </Grid>

      <Sidebar open={sidebarMode !== null} onClose={() => handleEdit(null)} title={sidebarTitle}>
        {({ ref, onClose }) => (
          <Box sx={{ height: '100%' }}>
            <Loader isLoading={isLoading} />
            <EditForm onSubmit={onSubmit} instance={instance} ref={ref} onClose={onClose} />
          </Box>
        )}
      </Sidebar>
    </Grid>
  );
};

export default OneLinkSummaryComponent;
