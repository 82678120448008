import { Close as CloseIcon } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import cn from 'classnames';
import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Column, Row } from 'react-table';

import { ReactComponent as EditIcon } from '../../assets/images/icon-edit-gray.svg';
import { ReactComponent as WarningIcon } from '../../assets/images/icon-warning-round.svg';

import { ActionButton, AddressBookTable, Checkbox, Filter } from '../../components';
import { ISidebarTableAddressItem } from '../../components/AddressBookTable/AddressBookTable';
import CompactOrgAddressView from '../../components/CompactOrgAddressView/CompactOrgAddressView';
import { OrgAddressForm } from '../../components/forms';
import { FormControls } from '../../components/SummaryComponentMUI/components/common';
import { Header, Title } from '../../components/_mui/_components_';
import {
  ADDRESS1,
  ADDRESS2,
  ADDRESS_FILTERS,
  CITY,
  COUNTRY,
  LABEL,
  NEW_ID,
  OrgAddressFilterTypesEnum,
  STATE,
  ZIP,
} from '../../constants/addressBook';
import { DISTRIBUTOR, ORG_ADMIN, SUPER_ADMIN } from '../../constants/users';
import useSearchFilter from '../../hooks/useSearchFilter/useSearchFilter';
import { selectAddressBook } from '../../store/selectors/addressBook';
import { selectAdminType } from '../../store/selectors/auth';
import { IOrgAddress } from '../../types/addressBook';
import { BUTTON_BUTTON } from '../../types/forms';
import { UISizeEnum } from '../../types/shell';
import { sortAddressesInABSidebar } from '../../utils/helpers';
import { hasPermission } from '../../utils/users';

import styles from './AddressBookSidebarContainer.module.scss';

interface ICommonProps {
  onClose: () => void;
  addressIds?: string[];
  isSidebarOpen: boolean;
  onReset?: () => void;
}

type TProps = ICommonProps &
  (
    | { onSubmit: (selectedIds: string[]) => void; onSelect?: never }
    | { onSubmit?: never; onSelect: (selectedIds: string[]) => void }
  );

const AddressBookSidebarContainer: React.FC<TProps> = ({
  onSubmit,
  onSelect,
  onReset,
  onClose,
  addressIds,
  isSidebarOpen,
}) => {
  const orgAddresses = useSelector(selectAddressBook);
  const adminType = useSelector(selectAdminType);

  const [innerAddresses, setInnerAddresses] = React.useState<string[]>(addressIds || []);

  const campaignNonActiveAddresses = useMemo(
    () =>
      orgAddresses?.filter(
        (item) => innerAddresses?.includes(item.uid) && item.status === OrgAddressFilterTypesEnum.NotActive,
      ) || [],
    [orgAddresses, innerAddresses],
  );

  const hasAnyInactiveAddresses = useMemo(() => campaignNonActiveAddresses.length > 0, [campaignNonActiveAddresses]);

  const [filter, setFilter] = React.useState(
    hasAnyInactiveAddresses ? OrgAddressFilterTypesEnum.All : OrgAddressFilterTypesEnum.Active,
  );
  const [candidate, setCandidate] = React.useState<string | undefined>();

  const isMultiSelect = !!onSubmit;

  const { SearchInput, filteredItems: filteredAddressesBySearch } = useSearchFilter(
    orgAddresses,
    [LABEL, ADDRESS1, COUNTRY, CITY, STATE, ZIP, ADDRESS2],
    {
      placeholder: 'Search Address',
      className: styles.searchByFilterContainer,
    },
  );

  const filterConfig = React.useMemo(() => {
    const showIcon = filter === OrgAddressFilterTypesEnum.Active && campaignNonActiveAddresses.length > 0;

    return ADDRESS_FILTERS.map(({ filterId, label: optionLabel }) => {
      return {
        onClick: () => setFilter(filterId),
        isActive: filterId === filter,
        key: optionLabel,
        label: `${optionLabel}`,
        icon: filterId === OrgAddressFilterTypesEnum.All && showIcon ? <WarningIcon /> : null,
      };
    });
  }, [filter, campaignNonActiveAddresses]);

  const filteredAddresses = React.useMemo(() => {
    const addresses =
      filter === OrgAddressFilterTypesEnum.All
        ? filteredAddressesBySearch
        : filteredAddressesBySearch.filter((address) => address?.status === filter);
    return sortAddressesInABSidebar(addresses, innerAddresses);
  }, [filteredAddressesBySearch, innerAddresses, filter]);

  const handleEditOrgAddress = useCallback((uid: string) => {
    setCandidate(uid);
  }, []);

  const handleAddOrgAddress = useCallback(() => {
    setCandidate(NEW_ID);
  }, []);

  const handleCloseOrgForm = useCallback(() => {
    setCandidate(undefined);
  }, []);

  const getStatusLabel = useCallback((status: OrgAddressFilterTypesEnum): string | JSX.Element => {
    switch (status) {
      case OrgAddressFilterTypesEnum.Active:
        return 'Active';
      case OrgAddressFilterTypesEnum.NotActive:
        return 'Not active';
      default:
        return '';
    }
  }, []);

  const handleResetRowSelection = useCallback(() => {
    setInnerAddresses([]);
    if (onReset) {
      onReset();
    }
  }, [onReset]);

  const handleSelectOrgAddress = useCallback(
    (address: Partial<IOrgAddress>) => {
      const { uid = '' } = address;

      setInnerAddresses((prevSelectedRows) => {
        const isSelected = prevSelectedRows.includes(uid);
        return isSelected ? prevSelectedRows.filter((rowId: string) => rowId !== uid) : [...prevSelectedRows, uid];
      });
    },
    [setInnerAddresses],
  );

  const activeItems = useMemo(
    () => filteredAddresses.filter((item) => item.status === OrgAddressFilterTypesEnum.Active),
    [filteredAddresses],
  );

  const handleSelectAllOrgAddresses = useCallback(
    (isChecked: boolean) => {
      setInnerAddresses(isChecked ? activeItems.map((item) => item.uid) : []);
    },
    [activeItems],
  );

  const handleConfirmOrgAddressesSelection = useCallback(() => {
    onSubmit?.(innerAddresses || []);
    onClose();
  }, [onClose, onSubmit, innerAddresses]);

  const columns: Column<ISidebarTableAddressItem>[] = useMemo(
    () => [
      ...(isMultiSelect
        ? [
            {
              Header: '',
              id: 'selected',
              Cell: ({ row }: { row: Row<ISidebarTableAddressItem> }) => {
                const isNotActiveSelected =
                  row.original.status === OrgAddressFilterTypesEnum.NotActive &&
                  innerAddresses?.includes(row.original.address.uid as string);
                return (
                  <div className={styles.checkboxWrapper}>
                    <Checkbox
                      checked={innerAddresses?.includes(row.original.address.uid as string)}
                      onChange={() => handleSelectOrgAddress(row.original.address)}
                    />
                    {isNotActiveSelected && <WarningIcon />}
                  </div>
                );
              },
            },
          ]
        : []),
      {
        id: 'address',
        Cell: ({ row }: { row: Row<ISidebarTableAddressItem> }) => {
          return <CompactOrgAddressView row={row.original.address} />;
        },
      },
      {
        Header: 'Status',
        id: 'status',
        Cell: ({ value }: { value: OrgAddressFilterTypesEnum }) => (
          <span>{getStatusLabel(value as OrgAddressFilterTypesEnum)}</span>
        ),
      },
      ...(hasPermission([ORG_ADMIN, DISTRIBUTOR, SUPER_ADMIN], adminType)
        ? [
            {
              Header: 'Actions',
              id: 'action',
              Cell: ({ row }: { row: Row<ISidebarTableAddressItem> }) => {
                return (
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      handleEditOrgAddress(row.original.address.uid as string);
                    }}
                  >
                    <EditIcon />
                  </button>
                );
              },
              meta: {
                className: 'actions',
              },
            },
          ]
        : []),
    ],
    [handleSelectOrgAddress, adminType, handleEditOrgAddress, innerAddresses, isMultiSelect, getStatusLabel],
  );

  const isNonActiveFilter = useMemo(() => filter === OrgAddressFilterTypesEnum.NotActive, [filter]);

  const isControlHidden = useMemo(
    () => isNonActiveFilter || activeItems.length === 0,
    [activeItems, isNonActiveFilter],
  );

  const isSelectAllChecked = useMemo(
    () => innerAddresses?.length === activeItems.length && activeItems.length > 0,
    [innerAddresses, activeItems],
  );

  const isSelectAllIndeterminate = useMemo(
    () => innerAddresses && innerAddresses.length > 0 && innerAddresses.length < activeItems.length,
    [innerAddresses, activeItems],
  );

  const isAnyAddressSelected = useMemo(() => innerAddresses && innerAddresses.length > 0, [innerAddresses]);

  // synchronize the form values with the sidebar
  React.useEffect(() => {
    if (!isSidebarOpen) {
      setInnerAddresses(addressIds || []);
    }
  }, [addressIds, isSidebarOpen]);

  const handleAddressSelect = useCallback(
    (address: Partial<IOrgAddress>) => {
      if (isMultiSelect) {
        handleSelectOrgAddress(address);
        return;
      }
      if (address.status === OrgAddressFilterTypesEnum.Active) {
        onSelect?.([address.uid!]);
      }
    },
    [onSelect, isMultiSelect, handleSelectOrgAddress],
  );

  return (
    <>
      {candidate ? (
        <OrgAddressForm id={candidate} onClose={handleCloseOrgForm} />
      ) : (
        <>
          <Header>
            <Title variant="h6">Address Book</Title>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Header>
          {SearchInput}
          <div className={styles.filter}>
            <Filter className={styles.typeFilter} config={filterConfig} />
            {hasPermission([ORG_ADMIN, DISTRIBUTOR, SUPER_ADMIN], adminType) && (
              <ActionButton
                size={UISizeEnum.Small}
                title="+"
                onClick={handleAddOrgAddress}
                className={styles.addButton}
              />
            )}
          </div>
          {hasAnyInactiveAddresses && (
            <p className={styles.warning}>
              <WarningIcon />
              <span>
                You have {campaignNonActiveAddresses.length} inactive address
                {`${campaignNonActiveAddresses.length > 1 ? 'es' : ''}`} selected in your campaign, which may cause
                errors.
              </span>
            </p>
          )}
          <div className={styles.tableControls}>
            {isMultiSelect && (
              <Checkbox
                checked={isSelectAllChecked}
                indeterminate={isSelectAllIndeterminate}
                onChange={(e) => handleSelectAllOrgAddresses(e.target.checked)}
                text={`Select All ${activeItems.length} items`}
                className={cn(styles.selectAllTitle, { [styles.visuallyHidden]: isControlHidden })}
              />
            )}
            {isAnyAddressSelected && (
              <button
                type={BUTTON_BUTTON}
                onClick={handleResetRowSelection}
                className={cn({ [styles.visuallyHidden]: isControlHidden }, styles.resetButton)}
              >
                Reset
              </button>
            )}
          </div>
          <div className={styles.tableWrapper}>
            <AddressBookTable
              columns={columns}
              data={filteredAddresses.map((address) => ({
                address,
                status: address.status,
              }))}
              containerClassName={styles.table}
              className={styles.tableBody}
              placeholderText="There are no addresses"
              rowClassName={!isMultiSelect ? styles.singleSelectRow : ''}
              onClick={handleAddressSelect}
              selectedIDs={isMultiSelect ? innerAddresses : innerAddresses[0]}
            />
            {isMultiSelect && <FormControls onCancel={onClose} onSubmit={handleConfirmOrgAddressesSelection} />}
          </div>
        </>
      )}
    </>
  );
};

export default AddressBookSidebarContainer;
