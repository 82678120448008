import {
  OrgAddressFilterTypesEnum,
  ORG_ADDRESS_FORM_REQUIRED_FIELDS,
  ORG_ADDRESS_FORM_REQUIRED_FIELDS_USA,
} from '../constants/addressBook';
import { USA } from '../constants/countries';
import { IOrgAddress } from '../types/addressBook';

export const getOrgAddressFormRequiredFieldsByCountryCode = (country?: string) => {
  switch (country) {
    case USA.two_digit_code:
      return ORG_ADDRESS_FORM_REQUIRED_FIELDS_USA;
    default:
      return ORG_ADDRESS_FORM_REQUIRED_FIELDS;
  }
};

export const isAllOrgAddressesActive = (addressList: IOrgAddress[] | null | undefined, orgIds: string[]): boolean => {
  if (addressList) {
    return orgIds.every((id) => {
      const address = addressList.find((addr) => addr.uid === id);
      return address?.status === OrgAddressFilterTypesEnum.Active;
    });
  }
  return false;
};
