import { useTheme } from '@mui/material';
import * as React from 'react';
import { ItemCallback, Layout, Responsive as ResponsiveGrid, WidthProvider } from 'react-grid-layout';
import useWindowSize from '../../hooks/useWindowSize';

import { moveArrayElements } from '../../utils/helpers';

import './DraggableList.module.scss';

interface IDraggableListProps<T> {
  items: T[];
  render: React.ReactNode;
  onDragStop: (items: T[]) => void;
  readOnly?: boolean;
}

const Responsive = WidthProvider(ResponsiveGrid);

const DraggableList = <T extends { item_id: string }>({
  items,
  render,
  readOnly,
  onDragStop,
}: IDraggableListProps<T>) => {
  const { sass: variables } = useTheme();
  const { HD, mobile } = useWindowSize();

  const height = React.useMemo(() => {
    switch (true) {
      case mobile:
        return 134;
      case HD:
        return 150;
      default:
        return 160;
    }
  }, [mobile, HD]);

  const layout: Layout[] = React.useMemo(
    () =>
      items.map((item, index) => ({
        i: item.item_id,
        x: 0,
        y: index,
        w: 1,
        h: 1,
        maxW: 1,
        minW: 1,
        isResizable: false,
      })),
    [items],
  );

  const handleDragStop: ItemCallback = React.useCallback(
    (currentLayout, { y: oldIndex }, { y: newIndex }) => {
      onDragStop(moveArrayElements(items, oldIndex, newIndex));
    },
    [onDragStop, items],
  );

  return readOnly ? (
    <>{render}</>
  ) : (
    <Responsive
      draggableCancel=".no-drag"
      rowHeight={height}
      breakpoints={{
        lg: +variables.breakpointLargeDesktop,
        md: +variables.breakpointDesktop,
        sm: +variables.breakpointMedium,
        xs: +variables.breakpointMobile,
        xxs: +variables.breakpointMobileMin,
      }}
      cols={{ lg: 1 }}
      layouts={{ lg: layout }}
      margin={[0, 20]}
      onDragStop={handleDragStop}
    >
      {render}
    </Responsive>
  );
};

export default DraggableList;
