import { Close as CloseIcon } from '@mui/icons-material';
import { Drawer, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useCallback, useRef, useState } from 'react';
import * as React from 'react';

import { DeleteAllWarning } from '../../../index';
import { Header, Title } from '../../../_mui/_components_';

interface IProps {
  open: boolean;
  onClose: () => void;
  title: string;
  children: (props: { ref: React.MutableRefObject<boolean>; onClose: () => void }) => React.ReactNode;
  isLoading?: boolean;
}

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  '& .MuiDrawer-paper': {
    color: '#212121',
    width: theme.sass.muiSidebarMaxWidth,
    padding: 48,
    maxWidth: '100%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));

export const Sidebar: React.FC<IProps> = ({ open, onClose, title, children }) => {
  const ref = useRef(false);
  const [showWarning, setShowWarning] = useState(false);

  const handleClose = useCallback(() => {
    ref.current ? setShowWarning(true) : onClose();
  }, [onClose]);

  const handleWarningSubmit = useCallback(() => {
    ref.current = false;
    setShowWarning(false);
    onClose();
  }, [onClose]);

  const handleCancel = useCallback(() => {
    setShowWarning(false);
  }, []);

  return (
    <StyledDrawer anchor="right" open={open} onClose={handleClose}>
      <Header>
        <Title variant="h6">{title}</Title>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </Header>
      {showWarning && (
        <DeleteAllWarning
          title="Quit without saving?"
          message="You have unsaved changes. Are you sure you want to exit without saving?"
          submitLabel="Exit without saving"
          cancelLabel="Stay on page"
          onSubmit={handleWarningSubmit}
          onCancel={handleCancel}
        />
      )}
      {children({ ref, onClose })}
    </StyledDrawer>
  );
};
