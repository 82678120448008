import { useTheme } from '@mui/material';
import cn from 'classnames';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import suggestGiftImage from '../../assets/images/suggest-gift-image.png';

import { ActionButton, Drawer } from '../../components';
import { AI_EXTENSION_DOWNLOAD_URL } from '../../constants/shell';
import useWindowSize from '../../hooks/useWindowSize';
import { toggleUiElement } from '../../store/actions/shell';
import { selectIsAIExtensionInstalled, selectIsSuggestGiftSidebarShown } from '../../store/selectors/shell';
import { DrawerAnimationDirectionEnum } from '../../types/shell';

import styles from './SuggestGiftSidebar.module.scss';

const SuggestGiftSidebar: React.FC = () => {
  const { sass: variables } = useTheme();
  const dispatch = useDispatch();
  const { mobile } = useWindowSize();

  const isExtensionInstalled = useSelector(selectIsAIExtensionInstalled);

  const isShown = useSelector(selectIsSuggestGiftSidebarShown);

  const handleClose = useCallback(() => {
    dispatch(toggleUiElement({ suggestGiftSidenavIsShown: false }));
  }, [dispatch]);

  const from = React.useMemo(
    () => (mobile ? variables.suggestGiftSidebarMinHeight : variables.suggestGiftSidebarMinWidth),
    [mobile],
  );
  const to = React.useMemo(() => {
    if (mobile) {
      return variables.suggestGiftSidebarMaxHeight;
    }

    return variables.suggestGiftSidebarMaxWidth;
  }, [mobile]);

  const nonInstalledSteps = [
    'Install the Imprint Engine AI Chrome extension.',
    'On a supported profile, the Imprint Engine AI icon will appear. Press it!',
    'Our AI scans their profile and makes personalized suggestions.',
  ];

  const installedSteps = [
    'On a supported profile, the Imprint Engine AI icon will appear. Press it!',
    'Our AI scans their profile and makes personalized suggestions.',
  ];

  const steps = isExtensionInstalled ? installedSteps : nonInstalledSteps;

  const handleRedirectButton = useCallback(() => {
    window.open(
      isExtensionInstalled ? 'https://www.linkedin.com/feed/' : AI_EXTENSION_DOWNLOAD_URL,
      '_blank',
      'noreferrer noopener',
    );
    handleClose();
  }, [isExtensionInstalled, handleClose]);

  return (
    <Drawer
      className={cn(styles.container)}
      direction={mobile ? DrawerAnimationDirectionEnum.Vertically : DrawerAnimationDirectionEnum.Horizontally}
      from={from}
      to={to}
      trigger={isShown}
      onClose={handleClose}
      withOverlay
      overlayClassName={styles.overlay}
    >
      {isShown && (
        <div className={styles.suggestGiftContainer}>
          <div className={styles.header}>
            <button className={styles.closeBtn} onClick={handleClose} />
            <span>Suggest gifts with AI</span>
          </div>
          <img src={suggestGiftImage} alt="suggest-gift" />
          <span className={styles.title}>Need a personalized gift?</span>
          <div className={styles.description}>
            <span>Imprint Engine's AI gifting tool can create tailored suggestions when you visit a profile.</span>
            <ol>
              <span>Follow these steps to get started:</span>
              {steps.map((step, i) => (
                <li className={styles.step} key={i}>
                  {step}
                </li>
              ))}
            </ol>
          </div>
          <ActionButton
            className={styles.submit}
            title={isExtensionInstalled ? 'Open LinkedIn' : 'Install Chrome extension'}
            onClick={handleRedirectButton}
          />
        </div>
      )}
    </Drawer>
  );
};

export default SuggestGiftSidebar;
