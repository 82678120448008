import MenuBookIcon from '@mui/icons-material/MenuBook';
import { Button, MenuItem, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import cn from 'classnames';
import * as React from 'react';
import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AddressBookSidebar, Selector } from '../../../components';
import { Label } from '../../../components/SummaryComponentMUI/components/common';
import { ShippingFlowSelectorOptionsEnum } from '../../../constants/addressBook';
import { fetchAddressBookRequest } from '../../../store/actions/addressBook';
import { selectAddressBook } from '../../../store/selectors/addressBook';
import { AddressBookSidebarContainer, ReceiverFixedAddressSidebarContainer } from '../../index';

import { ISelectorValue } from '../../../types/shell';
import { ICommonAddress, IOneLinkShippingDetails } from '../../../types/shipping';
import { isObjectEmpty } from '../../../utils/helpers';
import { AddressPreview, Message } from '../common';
import { SidebarModesEnum } from '../types';
import { getInitialSelectorState, getSelectOptions } from '../utils';

import styles from '../ShippingDetailsContainer.module.scss';

interface IProps {
  values: IOneLinkShippingDetails;
  onChange?: (v: IOneLinkShippingDetails) => void;
  isReadOnly?: boolean;
  isDisabled?: boolean;
  mui?: boolean;
  maxHeightList?: boolean;
}

const StyledSelector = styled(TextField)({
  '& .MuiSelect-select.Mui-disabled ~ .MuiSelect-icon': {
    display: 'none',
  },
});

const OneLink: React.FC<IProps> = ({
  values,
  onChange,
  isDisabled,
  isReadOnly,
  mui = false,
  maxHeightList = false,
}) => {
  const [sidebarMode, setSidebarMode] = React.useState<SidebarModesEnum>(SidebarModesEnum.None);
  const addressBook = useSelector(selectAddressBook);
  const dispatch = useDispatch();
  const { org_address_ids, is_receiver_address_fixed = false, receiver_address } = values;

  const [selectValue, setSelectValue] = React.useState<ShippingFlowSelectorOptionsEnum>(
    getInitialSelectorState({ is_receiver_address_fixed, address: receiver_address, is_delayed_shipping: true }),
  );

  const handleSelectChange = React.useCallback(
    (value: string) => {
      let updates: IOneLinkShippingDetails;
      switch (value) {
        case ShippingFlowSelectorOptionsEnum.Fixed: {
          if (org_address_ids?.length === 1) {
            const address = addressBook?.find((a) => a.uid === org_address_ids[0]);
            updates = {
              is_receiver_address_fixed: true,
              org_address_ids: undefined,
              receiver_address: address,
            };
          } else {
            setSidebarMode(SidebarModesEnum.FixedAddress);
            updates = {
              is_receiver_address_fixed: true,
              org_address_ids: undefined,
              receiver_address: undefined,
            };
          }
          break;
        }
        case ShippingFlowSelectorOptionsEnum.RecipientDecides: {
          updates = {
            is_receiver_address_fixed: false,
            receiver_address: undefined,
            org_address_ids: org_address_ids || undefined,
          };
          break;
        }
        case ShippingFlowSelectorOptionsEnum.RecipientDecidesFromList: {
          updates = {
            is_receiver_address_fixed: true,
            receiver_address: undefined,
            org_address_ids: org_address_ids || undefined,
          };
          break;
        }
        default:
          return;
      }
      setSelectValue(value);
      onChange?.(updates);
    },
    [onChange, addressBook, org_address_ids],
  );

  const handleAddressBookSubmit = React.useCallback(
    (addressIds: string[]) => {
      onChange?.({
        is_receiver_address_fixed,
        org_address_ids: addressIds,
        receiver_address: undefined,
      });
    },
    [onChange, is_receiver_address_fixed],
  );

  const options = useMemo(() => getSelectOptions(true), []); // isDelayedShipping = always true

  const handleCloseSidebar = React.useCallback(() => {
    setSidebarMode(SidebarModesEnum.None);
  }, []);

  const isEmptyRecipientFixedAddress = React.useMemo(() => isObjectEmpty(receiver_address), [receiver_address]);

  const isFixedSelectorValue = useMemo(() => {
    return selectValue === ShippingFlowSelectorOptionsEnum.Fixed;
  }, [selectValue]);

  const actionButtonTitle = useMemo(() => {
    switch (true) {
      case isFixedSelectorValue:
        return `${!isEmptyRecipientFixedAddress ? 'Edit' : 'Enter'} Fixed Address`;
      case org_address_ids && org_address_ids.length !== 0:
        return 'Edit Address Book Option';
      default:
        return 'Select Address Book Option';
    }
  }, [isFixedSelectorValue, org_address_ids, isEmptyRecipientFixedAddress]);

  const addressPreviewProps = useMemo(
    () => ({
      addresses: org_address_ids,
      receiverAddress: isFixedSelectorValue ? receiver_address : undefined,
      forceAddressList: !isFixedSelectorValue,
      onReset: mui
        ? undefined
        : () =>
            onChange?.({
              receiver_address: undefined,
              is_receiver_address_fixed,
              org_address_ids,
            }),
      maxHeightList,
    }),
    [maxHeightList, mui, onChange, is_receiver_address_fixed, isFixedSelectorValue, org_address_ids, receiver_address],
  );

  const handleSubmitFixedAddress = useCallback(
    (address: ICommonAddress) => onChange?.({ receiver_address: address, is_receiver_address_fixed, org_address_ids }),
    [onChange, is_receiver_address_fixed, org_address_ids],
  );

  const selector = useMemo(() => {
    return mui ? (
      <StyledSelector
        disabled={isReadOnly}
        select
        fullWidth
        label="Address Options"
        variant="outlined"
        value={selectValue}
        onChange={(e) => handleSelectChange(e.target.value)}
        sx={{ mb: 2 }}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </StyledSelector>
    ) : (
      <Selector
        className={styles.selector}
        helperText="Address Options"
        options={options}
        value={options.find((option) => option.value === selectValue)}
        onChange={(v) => handleSelectChange((v as ISelectorValue).value)}
        isClearable={false}
        isSearchable={false}
        closeMenuOnSelect
        readOnly={isReadOnly}
      />
    );
  }, [mui, options, selectValue, isReadOnly, handleSelectChange]);

  useEffect(() => {
    dispatch(fetchAddressBookRequest());
  }, [dispatch]);

  // this effect is needed to synchronize the selector value with the initial state
  useEffect(() => {
    setSelectValue(
      getInitialSelectorState({
        is_receiver_address_fixed,
        address: receiver_address,
        is_delayed_shipping: true,
      }),
    );
  }, [is_receiver_address_fixed, receiver_address]);

  return (
    <div className={cn(styles.container, styles.nested, { [styles.disabled]: isDisabled, [styles.mui]: mui })}>
      {!mui ? <h2 className={styles.title}>Shipping</h2> : null}
      {selector}
      {!isReadOnly && !isDisabled && (
        <Button
          variant="outlined"
          fullWidth
          onClick={() =>
            setSidebarMode(isFixedSelectorValue ? SidebarModesEnum.FixedAddress : SidebarModesEnum.AddressBook)
          }
          size="large"
          sx={{ minHeight: 50, mb: 3 }}
          startIcon={<MenuBookIcon />}
        >
          {actionButtonTitle}
        </Button>
      )}
      {!isDisabled && (
        <>
          {mui && (org_address_ids || receiver_address) && <Label>Selected Address Options</Label>}
          <AddressPreview {...addressPreviewProps} />
          <Message selectValue={selectValue} addresses={org_address_ids} />
        </>
      )}
      <AddressBookSidebar onClose={handleCloseSidebar} trigger={sidebarMode === SidebarModesEnum.AddressBook}>
        <AddressBookSidebarContainer
          onClose={handleCloseSidebar}
          addressIds={org_address_ids}
          onSubmit={handleAddressBookSubmit}
          isSidebarOpen={sidebarMode === SidebarModesEnum.AddressBook}
        />
      </AddressBookSidebar>
      <ReceiverFixedAddressSidebarContainer
        addressBook={addressBook}
        trigger={sidebarMode === SidebarModesEnum.FixedAddress}
        onClose={handleCloseSidebar}
        onSubmit={mui ? handleSubmitFixedAddress : undefined}
        value={receiver_address}
      />
    </div>
  );
};

export default OneLink;
