import LockIcon from '@mui/icons-material/Lock';
import { Autocomplete, Box, Chip, InputAdornment, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useMemo } from 'react';
import * as React from 'react';

import { IOneLinkWithCampaignSummaries } from '../../../types/oneLink';
import { CopyableButton } from '../../_mui/_controls_';
import { Section } from './common';

interface IProps {
  onEdit?: () => void;
  instance: IOneLinkWithCampaignSummaries | null | undefined;
}

export const SecuritySection: React.FC<IProps> = ({ onEdit, instance }) => {
  const StyledAutocomplete = styled(Autocomplete)(() => ({
    '& .MuiAutocomplete-inputRoot': {
      display: 'flex',
      alignItems: 'flex-start',
      minHeight: 150,
      maxHeight: 150,
    },
  }));

  const password = useMemo(() => instance?.password ?? '', [instance?.password]);

  return (
    <Section onEdit={onEdit} title="Security" minHeight={420}>
      <TextField
        label="Redemption Limit"
        variant="outlined"
        value={instance?.max_submit_count || ''}
        sx={{ mb: 2 }}
        fullWidth
        disabled
      />
      <TextField
        label="Password Protection"
        variant="outlined"
        value={password}
        sx={{
          mb: 2,
        }}
        fullWidth
        disabled
        slotProps={{
          input: {
            startAdornment: password ? (
              <InputAdornment position="start" sx={{ width: 30, justifyContent: 'center' }}>
                <LockIcon sx={{ width: 16, height: 16, mb: '2px' }} />
              </InputAdornment>
            ) : null,
            endAdornment: password ? (
              <InputAdornment position="end">
                <CopyableButton value={password} isVisible />
              </InputAdornment>
            ) : null,
          },
        }}
      />
      <StyledAutocomplete
        clearIcon={false}
        options={[]}
        freeSolo
        multiple
        disabled
        value={instance?.allowed_domains?.length ? instance?.allowed_domains : []}
        renderTags={(value, props) => (
          <Box sx={{ maxHeight: 120, overflowY: 'auto' }}>
            {value.map((option, index) => {
              // Extract key separately
              // onDelete should be passed when edits are allowed
              const { key, onDelete, ...restProps } = props({ index });
              return <Chip key={key} label={option as string} {...restProps} />;
            })}
          </Box>
        )}
        renderInput={(params) => <TextField label={'Allowed Domains'} {...params} />}
      />
    </Section>
  );
};
