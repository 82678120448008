import { variables } from '../contexts/ThemeProvider';
import { AutomationStatusEnum } from '../types/automations';

export const getAutomationStatusLabel = (status: string, text: string): { message: string; color: string } => {
  switch (status) {
    case AutomationStatusEnum.Active:
      return { message: `${text} is active`, color: variables.budgetGreen };
    case AutomationStatusEnum.Failed:
      return { message: `${text} is failed`, color: variables.errorRed };
    case AutomationStatusEnum.NotActive:
      return { message: `${text} is deactivated`, color: variables.orangeSun };
    default:
      return { message: 'Status is pending', color: variables.grayEbony };
  }
};
