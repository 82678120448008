import cn from 'classnames';
import * as React from 'react';
import { ChangeEvent, useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { ReactComponent as BookIcon } from '../../../assets/images/icon-book.svg';

import { ActionButton, AddressBookSidebar, Selector, Toggle } from '../../../components';
import { ShippingFlowSelectorOptionsEnum } from '../../../constants/addressBook';
import { AWAITING_ADDRESS } from '../../../constants/shipping';
import { selectAddressBook } from '../../../store/selectors/addressBook';
import { IShippingDetails } from '../../../types/shipping';
import { mapOrgAddressToCommonAddress } from '../../../utils/oneLink';
import { AddressBookSidebarContainer, ReceiverFixedAddressSidebarContainer } from '../../index';

import { ISelectorValue, UISizeEnum } from '../../../types/shell';
import { isObjectEmpty } from '../../../utils/helpers';
import { AddressPreview, Message } from '../common';
import { SidebarModesEnum } from '../types';
import { getInitialSelectorState, getSelectOptions } from '../utils';

import styles from '../ShippingDetailsContainer.module.scss';

interface IProps {
  isReadOnly?: boolean;
  isDisabled?: boolean;
  value: IShippingDetails;
  onChange: (v: IShippingDetails) => void;
  isForcedDS?: boolean;
}

const BulkSend: React.FC<IProps> = ({ value, onChange, isDisabled, isReadOnly, isForcedDS }) => {
  const [sidebarMode, setSidebarMode] = React.useState<SidebarModesEnum>(SidebarModesEnum.None);
  const addressBook = useSelector(selectAddressBook);

  const { ship_order_status, org_address_ids, is_receiver_address_fixed = false, receiver_address } = value;

  const isDelayedShipping = ship_order_status === AWAITING_ADDRESS;

  const [selectValue, setSelectValue] = React.useState<ShippingFlowSelectorOptionsEnum>(
    getInitialSelectorState({
      is_receiver_address_fixed,
      address: receiver_address,
      is_delayed_shipping: isDelayedShipping,
    }),
  );

  const handleSelectChange = React.useCallback(
    (selectedValue: ShippingFlowSelectorOptionsEnum, isDS?: boolean) => {
      const newShipOrderStatus = (() => {
        if (typeof isDS !== 'undefined') {
          return isDS ? AWAITING_ADDRESS : '';
        }
        return ship_order_status;
      })();

      let updates: IShippingDetails;

      switch (selectedValue) {
        case ShippingFlowSelectorOptionsEnum.Fixed: {
          if (org_address_ids?.length === 1) {
            const address = addressBook?.find((a) => a.uid === org_address_ids[0]);
            updates = {
              is_receiver_address_fixed: true,
              org_address_ids: undefined,
              receiver_address: mapOrgAddressToCommonAddress(address),
              ship_order_status: newShipOrderStatus,
            };
          } else {
            updates = {
              is_receiver_address_fixed: true,
              org_address_ids: undefined,
              receiver_address,
              ship_order_status: newShipOrderStatus,
            };
          }
          break;
        }
        case ShippingFlowSelectorOptionsEnum.RecipientDecides: {
          updates = {
            ship_order_status: newShipOrderStatus,
            is_receiver_address_fixed: false,
            receiver_address: undefined,
            org_address_ids,
          };
          break;
        }
        case ShippingFlowSelectorOptionsEnum.RecipientDecidesFromList: {
          updates = {
            is_receiver_address_fixed: true,
            receiver_address: undefined,
            org_address_ids: !!org_address_ids?.length ? org_address_ids : [],
            ship_order_status: newShipOrderStatus,
          };
          break;
        }
        default:
          return;
      }
      setSelectValue(selectedValue);
      onChange({ ...value, ...updates });
    },
    [onChange, addressBook, value, org_address_ids, receiver_address, ship_order_status],
  );

  const handleAddressBookSubmit = React.useCallback(
    (addressIds: string[]) => {
      onChange({
        ...value,
        org_address_ids: addressIds,
      });
    },
    [onChange, value],
  );

  const handleDSChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const isNextDelayedShipping = e.currentTarget.checked;

      handleSelectChange(
        isNextDelayedShipping
          ? ShippingFlowSelectorOptionsEnum.RecipientDecides
          : ShippingFlowSelectorOptionsEnum.Fixed,
        isNextDelayedShipping,
      );
    },
    [handleSelectChange],
  );

  const isFixedSelectorValue = useMemo(() => {
    return selectValue === ShippingFlowSelectorOptionsEnum.Fixed;
  }, [selectValue]);

  const options = useMemo(
    () => getSelectOptions(isDelayedShipping, isDelayedShipping && !isForcedDS),
    [isForcedDS, isDelayedShipping],
  );

  const handleCloseSidebar = React.useCallback(() => {
    setSidebarMode(SidebarModesEnum.None);
  }, []);

  const isEmptyRecipientFixedAddress = React.useMemo(() => isObjectEmpty(receiver_address), [receiver_address]);

  const actionButtonTitle = useMemo(() => {
    switch (true) {
      case isFixedSelectorValue:
        return `${!isEmptyRecipientFixedAddress ? 'Edit' : 'Enter'} Fixed Address`;
      case org_address_ids && org_address_ids.length !== 0:
        return 'Edit Address Book Option';
      default:
        return 'Select Address Book Option';
    }
  }, [isFixedSelectorValue, org_address_ids, isEmptyRecipientFixedAddress]);

  const addressPreviewProps = useMemo(
    () => ({
      addresses: org_address_ids,
      receiverAddress: isFixedSelectorValue ? receiver_address : undefined,
      forceAddressList: !isFixedSelectorValue,
      onReset: () => {
        onChange({
          ...value,
          receiver_address: undefined,
        });
      },
    }),
    [isFixedSelectorValue, org_address_ids, receiver_address],
  );

  // force DS in Custom sends for msku items
  useEffect(() => {
    if (isForcedDS && !isDelayedShipping) {
      handleDSChange({ currentTarget: { checked: true } } as ChangeEvent<HTMLInputElement>);
    }
  }, [isForcedDS, isDelayedShipping, handleDSChange]);

  return (
    <div className={cn(styles.container, styles.nested, { [styles.disabled]: isDisabled })}>
      <h2 className={styles.title}>Shipping</h2>
      <Toggle
        className={styles.toggle}
        sliderClassName={styles.toggleSlider}
        onChange={!isReadOnly ? handleDSChange : undefined}
        value={isDelayedShipping}
        checked={isDelayedShipping}
        defaultValue={false}
        isReadOnly={isReadOnly}
        disabled={isDisabled || isForcedDS}
      >
        Claim Link
      </Toggle>
      <Selector
        className={styles.selector}
        helperText="Address Options"
        options={options}
        value={options.find((option) => option.value === selectValue)}
        onChange={(v) => handleSelectChange((v as ISelectorValue).value as ShippingFlowSelectorOptionsEnum)}
        isClearable={false}
        isSearchable={false}
        closeMenuOnSelect
        readOnly={isReadOnly}
      />
      {!isReadOnly && !isDisabled && (
        <ActionButton
          className={styles.addressBookBtn}
          outlined
          icon={<BookIcon className={styles.icon} />}
          title={actionButtonTitle}
          size={UISizeEnum.Normal}
          onClick={() =>
            setSidebarMode(isFixedSelectorValue ? SidebarModesEnum.FixedAddress : SidebarModesEnum.AddressBook)
          }
        />
      )}
      {!isDisabled && (
        <>
          <AddressPreview {...addressPreviewProps} />
          <Message selectValue={selectValue} addresses={org_address_ids} />
        </>
      )}
      <AddressBookSidebar onClose={handleCloseSidebar} trigger={sidebarMode === SidebarModesEnum.AddressBook}>
        <AddressBookSidebarContainer
          onClose={handleCloseSidebar}
          addressIds={org_address_ids}
          onSubmit={handleAddressBookSubmit}
          isSidebarOpen={sidebarMode === SidebarModesEnum.AddressBook}
        />
      </AddressBookSidebar>
      <ReceiverFixedAddressSidebarContainer
        addressBook={addressBook}
        trigger={sidebarMode === SidebarModesEnum.FixedAddress}
        onClose={handleCloseSidebar}
      />
    </div>
  );
};

export default BulkSend;
