import cn from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';

import { Toggle } from '..';
import { isObjectEmpty } from '../../../../utils/helpers';
import { InputLabel } from '../../labels';

import styles from './ToggleInput.module.scss';

interface IChildProps {
  toggleInputStyles: any;
  isActive: boolean;
  isReadOnly?: boolean;
}

interface IProps<T> {
  className?: string;
  name: string;
  value: T;
  toggleLabel?: React.ReactNode;
  inputLabel?: React.ReactNode;
  hint?: string | React.ReactNode;
  isReadOnly?: boolean;
  isRequired?: boolean;
  onToggle?: (isActive: boolean) => void;
  children: (childProps: IChildProps) => React.ReactElement | null;
  direction?: 'column' | 'row';
  disabled?: boolean;
}

const ToggleInput = <T, _>({
  className,
  name,
  value,
  toggleLabel,
  inputLabel,
  hint,
  isReadOnly,
  isRequired,
  disabled,
  children,
  direction = 'column',
  onToggle,
}: IProps<T>) => {
  const [isActive, setIsActive] = useState<boolean>(typeof value === 'number' ? value > 0 : !isObjectEmpty(value));

  const handleToggleClick = useCallback(() => {
    setIsActive((prevActive) => !prevActive);
  }, []);

  useEffect(() => {
    onToggle?.(isActive);
    // DON'T add onToggle to the dependencies array to prevent infinite loop
  }, [isActive]);

  useEffect(() => {
    // 100% used in OL allowed domains input to clear value in case it becomes disabled after 2FA is turned on
    // this is bad approach. I know... I know... ¯\_(ツ)_/¯
    if (disabled && isObjectEmpty(value)) {
      setIsActive(false);
    }
  }, [disabled, value]);

  return (
    <div className={cn(className, styles.container)}>
      {!isReadOnly && toggleLabel && <InputLabel value={toggleLabel} hint={hint} name={name} />}
      <div className={cn(styles.wrapper, styles[direction])}>
        {!isReadOnly && (
          <div className={styles.toggle}>
            <Toggle onClick={handleToggleClick} checked={isActive} name={name} disabled={disabled} />
          </div>
        )}
        <div className={styles.input}>
          <InputLabel
            containerClassName={styles.inputLabel}
            value={inputLabel}
            name={name}
            required={isActive && isRequired && !isReadOnly}
          />
          <div className={styles.field}>{children({ isActive, isReadOnly, toggleInputStyles: styles })}</div>
        </div>
      </div>
    </div>
  );
};

export default ToggleInput;
