import * as React from 'react';
import { createContext, useCallback, useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';

import * as forms from '../../components/forms/OneLinkSummary';
import { selectIsOneLinkEditing } from '../../store/selectors/oneLink';
import { IOneLinkWithCampaignSummaries } from '../../types/oneLink';
import { IApiError } from '../../types/shell';
import { OneLinkChunksEnum, TUpdateOneLinkChunk } from './types';

export interface IOneLinkChunkFormProps {
  instance: IOneLinkWithCampaignSummaries | null;
  onClose: () => void;
  onSubmit: (chunk: TUpdateOneLinkChunk) => void;
  isLoading?: boolean;
}

type TFormElement = <T extends IOneLinkChunkFormProps>(p: T) => React.ReactElement<T> | null;

interface IOneLinkSummaryContext {
  onDeactivate?: () => void;
  onSubmit: (chunk: TUpdateOneLinkChunk) => void;
  handleEdit: (mode: OneLinkChunksEnum | null) => void;
  instance: IOneLinkWithCampaignSummaries | null;
  modes: typeof OneLinkChunksEnum;
  active: OneLinkChunksEnum | null;
  title: string;
  Form: TFormElement;
  isLoading: boolean;
}

const OneLinkContext = createContext<IOneLinkSummaryContext>({
  onSubmit: (_: TUpdateOneLinkChunk) => void 0,
  handleEdit: (_: OneLinkChunksEnum | null) => void 0,
  onDeactivate: () => void 0,
  instance: null,
  modes: OneLinkChunksEnum,
  active: null,
  title: '',
  Form: () => null,
  isLoading: false,
});

interface IProps {
  children: React.ReactNode;
  onUpdate: (chunk: TUpdateOneLinkChunk) => Promise<IApiError | void>;
  onDeactivate?: (instance: IOneLinkWithCampaignSummaries) => void;
  instance: IOneLinkWithCampaignSummaries | null;
}

export const OneLinkSummaryProvider = ({ children, onUpdate, onDeactivate, instance }: IProps) => {
  const [sidebarMode, setSidebarMode] = React.useState<OneLinkChunksEnum | null>(null);
  const isLoading = useSelector(selectIsOneLinkEditing);

  const handleUpdate = useCallback(
    (chunk: TUpdateOneLinkChunk) => {
      onUpdate(chunk).then(() => {
        setSidebarMode(null);
        // setTimeout(() => {
        //   // close the sidebar after a successful update with a timeout
        // }, 1000);
      });
    },
    [onUpdate],
  );

  const {
    title,
    Form,
  }: {
    title: string;
    Form: TFormElement;
  } = React.useMemo(() => {
    switch (sidebarMode) {
      case OneLinkChunksEnum.SummaryInfo:
        return { title: 'Edit Summary', Form: forms.SummaryForm };
      case OneLinkChunksEnum.InventoryInfo:
        return { title: 'Edit Inventory', Form: forms.InventoryForm };
      case OneLinkChunksEnum.ShippingInfo:
        return { title: 'Edit Shipping', Form: forms.ShippingForm };
      case OneLinkChunksEnum.AdditionalInfo:
        return { title: 'Edit Additional Details', Form: forms.AdditionalForm };
      case OneLinkChunksEnum.SecurityInfo:
        return { title: 'Edit Security', Form: forms.SecurityForm };
      case OneLinkChunksEnum.TwoFactorInfo:
        return { title: 'Manage 2FA Recipients', Form: forms.TwoFactorForm };
      default: {
        console.warn('Unreachable scenario. Unknown form type');
        return { title: '', Form: () => null };
      }
    }
  }, [sidebarMode]);

  const handleDeactivate = useCallback(() => {
    if (onDeactivate && instance) {
      onDeactivate(instance as IOneLinkWithCampaignSummaries);
    }
  }, [onDeactivate, instance]);

  const handleEdit = useCallback((mode: OneLinkChunksEnum | null) => {
    setSidebarMode(mode);
  }, []);

  const value = useMemo(
    () => ({
      instance,
      handleEdit,
      onSubmit: handleUpdate,
      onDeactivate: handleDeactivate,
      modes: OneLinkChunksEnum,
      title,
      Form,
      active: sidebarMode,
      isLoading,
    }),
    [handleDeactivate, isLoading, instance, handleEdit, handleUpdate, title, Form, sidebarMode],
  );

  return <OneLinkContext.Provider value={value}>{children}</OneLinkContext.Provider>;
};

export const useOneLinkSummary = () => {
  return useContext(OneLinkContext);
};
