import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import { Button, styled } from '@mui/material';
import * as React from 'react';

import useCopyClipboard from '../../../hooks/useCopyClipboard';

export interface IProps {
  children?: string | React.ReactNode | React.ReactNode[];
  value: string;
  disabled?: boolean;
  onClick?: (...args: any[]) => void;
  isVisible?: boolean;
}

const StyledButton = styled(Button)(({ theme }) => ({
  fontSize: 16,
  fontWeight: 400,
  padding: 0,
  color: theme.palette.primary.main,
  display: 'flex',
  alignItems: 'center',
  marginTop: -5,
  gap: 4,
  '&:hover': {
    backgroundColor: 'transparent',
    textDecoration: 'underline',
    '& .MuiSvgIcon-root': {
      opacity: 1,
      transform: 'translateX(0)',
    },
  },
}));

export const CopyableButton: React.FC<IProps> = ({ value, onClick, isVisible = false, children, disabled }) => {
  const { setCopied } = useCopyClipboard({ successDuration: 1000, toastDuration: 1000 });

  const handleClick = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setCopied(value.toString());
      if (onClick) {
        onClick(event);
      }
    },
    [onClick, value, setCopied],
  );

  return (
    <StyledButton onClick={handleClick} disabled={disabled} disableRipple variant="text">
      {children}
      <ContentCopyOutlinedIcon
        sx={{
          width: 14,
          height: 14,
          marginLeft: '3px',
          opacity: isVisible ? 1 : 0,
          transform: !isVisible ? `translateX(-100%)` : 'unset',
          transition: 'all 0.25s ease',
        }}
      />
    </StyledButton>
  );
};
