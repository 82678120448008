import * as React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { OrgAddressFilterTypesEnum, UID } from '../../constants/addressBook';
import { fetchAddressBookRequest } from '../../store/actions/addressBook';
import { selectAddressBook } from '../../store/selectors/addressBook';
import { IOrgAddress, ISelectorValueWithOrgAddress } from '../../types/addressBook';
import { ICommonAddress } from '../../types/shipping';
import { isObjectsEqual } from '../../utils/helpers';
import { mapOrgAddressToCommonAddress } from '../../utils/oneLink';
import { InputLabel, OrgAddressOption, Selector } from '../forms';

interface IProps {
  value?: ICommonAddress | undefined;
  onChange: (value: IOrgAddress) => void | Promise<void>;
  label?: string;
  className?: string;
  selectClassName?: string;
}

const AddressSelector: React.FC<IProps> = ({ className, selectClassName, value, onChange, label }) => {
  const dispatch = useDispatch();

  const [selectedAddressUID, setSelectedAddressUID] = React.useState<string | null>(null);

  const addressBook = useSelector(selectAddressBook);

  const selectorOptions = React.useMemo(() => {
    if (!addressBook || !addressBook.length) {
      return [];
    }

    return addressBook
      .filter((address) => address.status === OrgAddressFilterTypesEnum.Active)
      .map((address) => {
        const { label: addressLabel, uid } = address;

        return {
          value: uid,
          label: addressLabel,
          address,
        };
      });
  }, [addressBook]);

  const handleChange = React.useCallback(
    (address: IOrgAddress) => {
      setSelectedAddressUID(address[UID]);
      if (address) {
        onChange(address);
      }
    },
    [onChange],
  );

  const selectedAddress = React.useMemo(() => {
    const address = addressBook?.find((a) => a.uid === selectedAddressUID);

    return address ? { value: address.uid, label: address.label, address } : undefined;
  }, [addressBook, selectedAddressUID]);

  useEffect(() => {
    dispatch(fetchAddressBookRequest());
  }, [dispatch]);

  useEffect(() => {
    if ((addressBook?.length ?? 0) > 0 && selectedAddressUID) {
      const address = addressBook?.find((a) => a.uid === selectedAddressUID);

      if (address && !isObjectsEqual(value, mapOrgAddressToCommonAddress(address))) {
        setSelectedAddressUID(null);
      }
    }
  }, [selectedAddressUID, addressBook, value]);

  if (!addressBook || !addressBook.length) {
    return null;
  }

  return (
    <div className={className}>
      {label && <InputLabel value={label} />}
      <Selector
        className={selectClassName}
        value={selectedAddress}
        isClearable={false}
        onChange={(v) => handleChange((v as ISelectorValueWithOrgAddress).address)}
        closeMenuOnSelect
        options={selectorOptions}
        customOption={OrgAddressOption}
      />
    </div>
  );
};

export default AddressSelector;
