import { Box } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { styled } from '@mui/material/styles';
import format from 'date-fns/format';
import * as React from 'react';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { DateFormatsEnum } from '../../../constants/date';
import { EXPIRATION_DATE } from '../../../constants/shipping';
import { selectUsers } from '../../../store/selectors/users';
import { IOneLinkWithCampaignSummaries } from '../../../types/oneLink';
import { formatDate, getUTCDate, isValidDate } from '../../../utils/date';
import { getFullName } from '../../../utils/helpers';
import { getOneLinkStatusAndDate } from '../../../utils/oneLink';
import { ReportsStatus } from '../../index';
import { CopyableButton } from '../../_mui/_controls_';
import { Label, Section } from './common';

interface IProps {
  instance: IOneLinkWithCampaignSummaries | null | undefined;
  reportMode: boolean;
  actions?: { title: string; onClick?: () => void; disabled?: boolean }[];
}

export const SummarySection: React.FC<IProps> = ({ reportMode, instance, actions }) => {
  const users = useSelector(selectUsers);

  const TruncatedBox = styled(Box)({
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  });

  const title = useMemo(() => instance?.title || 'No title', [instance]);

  const createdByUser = useMemo(() => {
    const user = users?.find((person) => person.email === instance?.created_by);
    return user ? getFullName(user.first_name, user.last_name) : '';
  }, [users, instance?.created_by]);

  const createdBy = useMemo(() => createdByUser || instance?.created_by, [createdByUser, instance?.created_by]);

  const {
    createdAtDate,
    label: statusLabel,
    color: statusColor,
  } = React.useMemo(() => {
    if (reportMode && instance) {
      return getOneLinkStatusAndDate(instance as IOneLinkWithCampaignSummaries);
    }

    return { date: '', label: '', color: '', dateLabel: '', createdAtDate: '' };
  }, [reportMode, instance]);

  const status = useMemo(
    () => <ReportsStatus status={statusLabel} backgroundColor={statusColor} />,
    [statusColor, statusLabel],
  );

  const expirationDate = useMemo(() => {
    if (instance && instance[EXPIRATION_DATE] && isValidDate(new Date(instance[EXPIRATION_DATE]))) {
      return format(getUTCDate(instance[EXPIRATION_DATE]), DateFormatsEnum.FullHumanReadable);
    }
    return 'No expiration date';
  }, [instance?.[EXPIRATION_DATE]]);

  const sender = useMemo(
    () => getFullName(instance?.sender_first_name, instance?.sender_last_name),
    [instance?.sender_last_name, instance?.sender_first_name],
  );

  const createdAt = useMemo(() => format(new Date(createdAtDate), DateFormatsEnum.ddMMMyyyy), [createdAtDate]);

  return (
    <Section title={title} actions={actions}>
      <Grid
        container
        spacing={2}
        sx={{
          minHeight: { xs: 200, md: 'unset' },
        }}
      >
        <Grid size={{ xs: 6, sm: 4, md: 1.75 }}>
          <Label>Status</Label>
          <Box>{status}</Box>
        </Grid>
        <Grid size={{ xs: 6, sm: 4, md: 1.75 }}>
          <Label>Link</Label>
          <CopyableButton value={instance?.url!}>Copy Link</CopyableButton>
        </Grid>
        <Grid size={{ xs: 6, sm: 4, md: 2 }}>
          <Label>Expiration Date</Label>
          <Box>{expirationDate}</Box>
        </Grid>
        <Grid size={{ xs: 6, sm: 4, md: 1.5 }}>
          <Label>Created Date</Label>
          <Box>{createdAt}</Box>
        </Grid>
        <Grid size={{ xs: 6, sm: 4, md: 2.5 }}>
          <Label>Sender</Label>
          <TruncatedBox title={sender}>{sender}</TruncatedBox>
        </Grid>
        <Grid size={{ xs: 6, sm: 4, md: 2.5 }}>
          <Label>Created by</Label>
          <TruncatedBox title={createdBy}>{createdBy}</TruncatedBox>
        </Grid>
      </Grid>
    </Section>
  );
};

export default SummarySection;
