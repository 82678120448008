import cn from 'classnames';
import * as React from 'react';

import { ReactComponent as ErrorIcon } from '../../assets/images/icon-warning-red.svg';
import { ReactComponent as WarningIcon } from '../../assets/images/icon-warning.svg';

import {
  CAMPAIGN_HAS_ITEMS_FROM_DIFFERENT_FC_WARNING,
  CAMPAIGN_HAS_OUT_OF_STOCK_ITEMS_ERROR,
} from '../../constants/inventories';
import { ICampaign, ICampaignSummary } from '../../types/campaigns';
import { getCampaignCountWarning, isCampaignEligibleToOrder } from '../../utils/campaigns';
import { overrideTooltipPosition } from '../../utils/helpers';
import { isItemDigital, isSameFC } from '../../utils/inventories';
import HelpTooltip from '../tooltips/HelpTooltip/HelpTooltip';

import styles from './CampaignPreview.module.scss';

interface IProps {
  className?: string;
  contentClassName?: string;
  campaign: ICampaign | ICampaignSummary;
  onClick?: () => void;
  shouldPropagate?: boolean;
  showPreview?: boolean;
  showWarning?: boolean;
  reportMode?: boolean;
  defaultView?: boolean;
}

const CampaignPreview = ({
  campaign: { name: campaignName, description, items, box_id },
  className,
  contentClassName,
  onClick,
  shouldPropagate = false,
  showPreview = false,
  showWarning = false,
  reportMode = false,
  defaultView,
}: IProps) => {
  const handleClick = React.useCallback(
    (e: React.MouseEvent) => {
      if (!shouldPropagate) {
        e.stopPropagation();
      }
      if (typeof onClick === 'function') {
        onClick();
      }
    },
    [shouldPropagate, onClick],
  );

  const isDigital = React.useMemo(() => items?.every(isItemDigital), [items]);

  const hasItemsFromDifferentFc = React.useMemo(() => {
    return !isSameFC(items);
  }, items);

  const campaignIsNotEligibleToOrder = React.useMemo(() => !isCampaignEligibleToOrder(items), [items]);

  const warningMessage = React.useMemo(() => {
    switch (true) {
      case hasItemsFromDifferentFc:
        return CAMPAIGN_HAS_ITEMS_FROM_DIFFERENT_FC_WARNING;
      case campaignIsNotEligibleToOrder:
        return CAMPAIGN_HAS_OUT_OF_STOCK_ITEMS_ERROR;
      case reportMode:
      case isDigital:
        return undefined;
      default:
        return getCampaignCountWarning(items);
    }
  }, [items, isDigital, hasItemsFromDifferentFc, campaignIsNotEligibleToOrder, reportMode]);

  const isCampaignWithError = React.useMemo(
    () => hasItemsFromDifferentFc || campaignIsNotEligibleToOrder,
    [hasItemsFromDifferentFc, campaignIsNotEligibleToOrder],
  );

  const warningIcon = React.useMemo(() => {
    return isCampaignWithError ? ErrorIcon : WarningIcon;
  }, [isCampaignWithError]);

  const isWarningShown = React.useMemo(() => showWarning || reportMode, [showWarning, reportMode]);

  const previewLayout: React.ReactNode = React.useMemo(
    () => (
      <div className={cn(styles.content, contentClassName)} role={onClick ? 'button' : ''} onClick={handleClick}>
        {showPreview && (
          <React.Fragment>
            <h3 className={styles.title}>{campaignName}</h3>
            <h4 className={styles.heading}>Description:</h4>
            <div className={styles.description}>{description}</div>
            <h4 className={styles.heading}>Send includes:</h4>
            <ol className={styles.list}>
              {items?.map(({ quantity, item_id, name }) => (
                <li className={styles.item} key={`${item_id}+${quantity}`}>
                  {name}
                  {quantity > 1 && <div className={styles.quantity}>x{quantity}</div>}
                </li>
              ))}
            </ol>
          </React.Fragment>
        )}
        {isWarningShown && warningMessage && (
          <div
            className={cn(styles.warning, { [styles.noMargin]: !showPreview }, { [styles.alert]: isCampaignWithError })}
          >
            {warningMessage}
          </div>
        )}
      </div>
    ),
    [items, description, onClick, warningMessage, showPreview, hasItemsFromDifferentFc],
  );

  if ((!showPreview && !showWarning) || (!showPreview && showWarning && !warningMessage)) {
    return null;
  }

  return (
    <HelpTooltip
      effect="float"
      className={cn(className, { [styles.defaultView]: defaultView })}
      contentClassName={styles.container}
      icon={isWarningShown && warningMessage ? warningIcon : null}
      id={`campaign-preview-${box_id}`}
      overridePosition={overrideTooltipPosition}
    >
      {previewLayout}
    </HelpTooltip>
  );
};

export default CampaignPreview;
