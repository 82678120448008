import * as yup from 'yup';

import { IOrgAddress } from '../types/addressBook';
import { USA } from './countries';
import { NO_DEPARTMENT } from './organizations';
import { EMAIL_VALIDATION_REGEX, ZIP_VALIDATION_REGEX } from './shell';

export const UID = 'uid';
export const LABEL = 'label';
export const ADDRESS1 = 'address1';
export const ADDRESS2 = 'address2';
export const CITY = 'city';
export const STATE = 'state';
export const COUNTRY = 'country';
export const ZIP = 'zip';
export const EMAIL = 'email';
export const PHONE = 'phone';
export const STATUS = 'status';
export const DEPARTMENT_IDS = 'department_ids';
export const NEW_ID = 'new';

export enum OrgAddressFilterTypesEnum {
  All = 'all',
  Active = 'active',
  NotActive = 'not_active',
}

export enum ShippingFlowSelectorOptionsEnum {
  RecipientDecides = 'recipient_decides',
  RecipientDecidesFromList = 'recipient_decides_from_list',
  Fixed = 'fixed',
}

export const ADDRESS_FILTERS = [
  {
    label: 'All',
    filterId: OrgAddressFilterTypesEnum.All,
  },
  {
    label: 'Active',
    filterId: OrgAddressFilterTypesEnum.Active,
  },
  {
    label: 'Non-active',
    filterId: OrgAddressFilterTypesEnum.NotActive,
  },
];

export const ORG_ADDRESS_INITIAL_VALUES: Partial<IOrgAddress> = {
  [LABEL]: '',
  [ADDRESS1]: '',
  [ADDRESS2]: '',
  [CITY]: '',
  [STATE]: '',
  [COUNTRY]: '',
  [ZIP]: '',
  [EMAIL]: '',
  [PHONE]: '',
  [DEPARTMENT_IDS]: [NO_DEPARTMENT],
};

export const ORG_ADDRESS_FORM_FIELDS_MAP: { [k: string]: string } = {
  [LABEL]: 'Label',
  [ADDRESS1]: 'Address 1',
  [CITY]: 'City',
  [STATE]: 'State',
  [COUNTRY]: 'Country',
  [ZIP]: 'Zip',
  [DEPARTMENT_IDS]: 'Department',
};

export const ORG_ADDRESS_VALIDATION_SCHEMA = yup.object().shape({
  [LABEL]: yup.string().label(ORG_ADDRESS_FORM_FIELDS_MAP[LABEL]).required().max(70, 'Label is too long').trim(),
  [ADDRESS1]: yup.string().label(ORG_ADDRESS_FORM_FIELDS_MAP[ADDRESS1]).required().trim(),
  [ADDRESS2]: yup.string().trim(),
  [CITY]: yup.string().label(ORG_ADDRESS_FORM_FIELDS_MAP[CITY]).required().trim(),
  [STATE]: yup
    .string()
    .trim()
    .when(COUNTRY, {
      is: USA.two_digit_code,
      then: (schema: yup.StringSchema) => schema.required().label(ORG_ADDRESS_FORM_FIELDS_MAP[STATE]),
      otherwise: (schema: yup.StringSchema) => schema.notRequired(),
    }),
  [COUNTRY]: yup.string().label(ORG_ADDRESS_FORM_FIELDS_MAP[COUNTRY]).required().trim(),
  [ZIP]: yup
    .string()
    .label(ORG_ADDRESS_FORM_FIELDS_MAP[ZIP])
    .matches(ZIP_VALIDATION_REGEX, { message: 'Invalid zip format' })
    .required()
    .trim(),
  [EMAIL]: yup.string().notRequired().matches(EMAIL_VALIDATION_REGEX, { message: 'Invalid email format' }).trim(),
  [PHONE]: yup.string().trim(),
  [DEPARTMENT_IDS]: yup.array().of(yup.string()).label(ORG_ADDRESS_FORM_FIELDS_MAP[DEPARTMENT_IDS]).required(),
});

export const ORG_ADDRESS_FORM_REQUIRED_FIELDS = [] as string[];

export const ORG_ADDRESS_FORM_REQUIRED_FIELDS_USA = ORG_ADDRESS_FORM_REQUIRED_FIELDS.concat(STATE);
