import { Box } from '@mui/material';
import * as React from 'react';

import { IOneLinkChunkFormProps } from '../../../contexts/OneLinkSummaryProvider';

const TwoFactorForm = ({ onSubmit, instance }: IOneLinkChunkFormProps) => {
  console.log('AdditionalForm instance', instance);

  return (
    <Box>
      AdditionalForm goes here...
      <button
        // @ts-ignore
        onClick={onSubmit}
      >
        submit
      </button>
    </Box>
  );
};

export default TwoFactorForm;
