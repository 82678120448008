import { useMemo } from 'react';
import * as React from 'react';

import { OLShippingDetailsContainer } from '../../../containers';
import { IOneLinkWithCampaignSummaries } from '../../../types/oneLink';
import { Section } from './common';

interface IProps {
  onEdit?: () => void;
  instance: IOneLinkWithCampaignSummaries | null | undefined;
}

export const ShippingSection: React.FC<IProps> = ({ onEdit, instance }) => {
  const shippingValues = useMemo(
    () => ({
      is_receiver_address_fixed: !!instance?.is_receiver_address_fixed,
      receiver_address: instance?.receiver_fixed_address,
      org_address_ids: instance?.org_address_ids,
    }),
    [instance],
  );

  return (
    <Section title="Shipping" onEdit={onEdit} minHeight={420}>
      <OLShippingDetailsContainer isReadOnly mui values={shippingValues} />
    </Section>
  );
};

export default ShippingSection;
