import { ThemeProvider as MUIProvider, useTheme as useMUITheme } from '@mui/material';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { selectTheme } from '../../store/selectors/shell';
import { ITheme } from '../../types/shell';
import { setThemeToCSSVariables } from '../../utils/helpers';
import getThemeConfig from './themeConfig';

const UpdateThemeContext = React.createContext<(newTheme?: ITheme) => void>(() => void 0);

interface IProps {
  children: React.ReactNode | React.ReactNode[];
}

export const useUpdateTheme = () => {
  return useContext(UpdateThemeContext);
};

export const useWL = () => {
  return useMUITheme().wl;
};

export const ThemeProvider: React.FC<IProps> = ({ children }) => {
  const initialTheme = useSelector(selectTheme);
  const [theme, setTheme] = useState(initialTheme);

  const mui = useMemo(() => getThemeConfig(theme), [theme]);

  const updateTheme = useCallback(
    (newTheme?: ITheme) => {
      if (newTheme) {
        setTheme(newTheme);
      } else {
        setTheme(initialTheme);
      }
    },
    [initialTheme],
  );

  React.useEffect(() => {
    setThemeToCSSVariables(theme);
  }, [theme]);

  React.useEffect(() => {
    setTheme(initialTheme);
  }, [initialTheme]);

  return (
    <MUIProvider theme={mui}>
      <UpdateThemeContext.Provider value={updateTheme}>{children}</UpdateThemeContext.Provider>
    </MUIProvider>
  );
};
